import {
  EMAIL_REGEXP,
  NAME_REGEXP,
  PASSWORD_REGEXP,
  ErrorMessages,
  BYTES_IN_MEGABYTE,
  MAX_SIZE_IMAGE,
  FIELD_REGEXP,
  CYRILLIC_LATIN_REGEXP,
  IMAGE_TYPE_REGEXP,
} from './formFields.constants';

// wrong format
export const getEmailValidationMsg = (value: string) => (!EMAIL_REGEXP.test(value) ? ErrorMessages.WrongFormat : '');
export const getNameValidationMsg = (value: string) => {
  if (!value) {
    return ErrorMessages.RequiredField;
  }
  if (!NAME_REGEXP.test(value)) {
    return ErrorMessages.WrongFormat;
  }
  return '';
};
export const getPasswordValidationMsg = (value: string) =>
  !PASSWORD_REGEXP.test(value) ? ErrorMessages.WrongFormat : '';

// mismatch passwords
export const getPasswordMatch = (password: string, confirmPassword: string) =>
  password !== confirmPassword ? ErrorMessages.MismatchPasswords : '';

// required field
export const getRequiredFieldMessage = (value: string) => (!value ? ErrorMessages.RequiredField : '');
export const getFieldValidationMsg = (value: string) => (!FIELD_REGEXP.test(value) ? ErrorMessages.RequiredField : '');
export const isCyrillicOrLatin = (value: string) =>
  !CYRILLIC_LATIN_REGEXP.test(value) ? ErrorMessages.CyrillicOrLatinRequired : '';
export const getMaxLengthField = (value: string, maxLength: number) =>
  value.length > maxLength ? ErrorMessages.OversizeText + maxLength : '';
export const getMinLengthField = (value: string, minLength: number) =>
  value.length < minLength ? ErrorMessages.ShortText + (minLength - 1) : '';

export const getMinMaxCyrillicLatinField = (minLength: number | null, maxLength: number, value: string) =>
  getFieldValidationMsg(value) ||
  (minLength && getMinLengthField(value, minLength)) ||
  getMaxLengthField(value, maxLength) ||
  isCyrillicOrLatin(value);

export const getRequiredUkrainianPhoneNumber = (value: string): string =>
  /\+380\d{9}$/.test(value) ? '' : ErrorMessages.UkrainianPhoneMask;

// image validation
export const getImageValidationMsg = (image: File): string => {
  if (!image || image.size === 0) {
    return ErrorMessages.RequiredField;
  }

  if (image.size / BYTES_IN_MEGABYTE > MAX_SIZE_IMAGE) {
    return ErrorMessages.Oversize;
  }

  if (!image.name.match(IMAGE_TYPE_REGEXP)) {
    return ErrorMessages.ImageWrongFormat;
  }

  return '';
};

export const getImgValidationMsg = (image: File): string => {
  if (!image || image.size === 0) {
    return ErrorMessages.RequiredField;
  }

  if (image.size / BYTES_IN_MEGABYTE > MAX_SIZE_IMAGE || !image.name.match(IMAGE_TYPE_REGEXP)) {
    return ErrorMessages.WrongFormatImage;
  }
  return '';
};
