import { call, put, takeLatest } from 'redux-saga/effects';
import { actionTypes, getFailAction, getSuccessAction } from '../actions/types';
import { showPrivacyPolicy } from '../api/privacyPolicy';

export function* getPrivacyPolicySaga() {
  try {
    const { data } = yield call(showPrivacyPolicy);

    yield put({
      type: getSuccessAction(actionTypes.GET_PRIVACY_POLICY),
      payload: data,
    });
  } catch (error: unknown) {
    yield put({
      type: getFailAction(actionTypes.GET_PRIVACY_POLICY),
      error,
    });
  }
}

export function* watchGetPrivacyPolicy() {
  yield takeLatest(actionTypes.GET_PRIVACY_POLICY, getPrivacyPolicySaga);
}
