import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';

export interface PasswordResetEmailState {
  isLoading: boolean;
  email: string | null;
  error: any;
}

const initialState: PasswordResetEmailState = {
  isLoading: false,
  email: null,
  error: null,
};

export const passwordResetEmailReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.SEND_RESET_EMAIL:
      return {
        ...state,
        isLoading: true,
        email: action.payload,
        error: null,
      };
    case getSuccessAction(actionTypes.SEND_RESET_EMAIL):
      return {
        ...state,
        isLoading: false,
      };
    case getFailAction(actionTypes.SEND_RESET_EMAIL):
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case actionTypes.CLEAR_PASSWORD_RESET_EMAIL:
      return initialState;
    default:
      return {
        ...state,
      };
  }
};
