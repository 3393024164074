import { authorizedInstance } from './axiosInstances';
import { SubmitData } from '../pages/Awards/models/SubmitData';

enum APIUrl {
  newAward = '/badge',
  getAwards = '/badges',
}

export const addNewAward = (newAward: SubmitData) => authorizedInstance.post(APIUrl.newAward, newAward);
export const editAward = (newAward: SubmitData) => authorizedInstance.put(APIUrl.newAward, newAward);
export const getAllAwards = () => authorizedInstance.get(APIUrl.getAwards);
export const deleteAward = (id: number) => authorizedInstance.delete(`${APIUrl.newAward}/${id}`);