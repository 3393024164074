import React, { useContext } from 'react';
import ScheduledModal from '../../ModalsReminder/ScheduledModal';
import AddBtn from '../../../../components/AddBtn';
import { useModal, ModalContext } from '../../useModal';
import AutomatedModal from '../../ModalsReminder/AutomatedModal';
import { ReminderType, isReminderTypeScheduled } from '../../constants';


interface IAddReminderProps {
  placeholder: string;
  type: ReminderType;
}

export const AddReminder = ({ placeholder, type }: IAddReminderProps) => {
  const { openedModal, closeModal, openModal } = useModal();
  const { pushToEdit } = useContext(ModalContext);
  
  const getChildren = () => 
    React.createElement(
      isReminderTypeScheduled(type) ? ScheduledModal : AutomatedModal,
      { defaultValues: pushToEdit, onCloseModal: closeModal, isOpen: openedModal }
    );

  return (
    <>
      <AddBtn placeholder={placeholder} onClick={openModal} />
      {openedModal && getChildren()}
    </>
  );
};
