import React, { useCallback } from 'react';
import { DataGrid, GridComponentProps } from '@mui/x-data-grid';
import TablePagination from '../TablePagination';
import TableNoRowsOverlay from '../TableNoRowsOverlay';

import style from './index.module.scss';

const Table = ({
  rows,
  columns,
  checkboxSelection = true,
  loading,
  onCellClick,
  isRowSelectable = () => true,
}: GridComponentProps) => {
  const getRowClassName = useCallback((params: any) => {
    if (params.row.isDisabled) {
      return 'disabled-row';
    }
    return '';
  }, []);
  return (
    <DataGrid
      className={style.container}
      rows={rows}
      checkboxSelection={checkboxSelection}
      hideFooterSelectedRowCount
      headerHeight={56}
      rowHeight={100}
      isRowSelectable={isRowSelectable}
      isCellEditable={() => true}
      disableColumnMenu
      loading={loading}
      autoPageSize
      components={{
        Pagination: () => <TablePagination rows={rows} isLoading={loading} />,
        NoRowsOverlay: TableNoRowsOverlay,
      }}
      columns={columns.map((column) => ({
        ...column,
        disableClickEventBubbling: true,
        sortable: column.sortable || false,
      }))}
      onCellClick={onCellClick}
      sortingOrder={['asc', 'desc', null]}
      getRowClassName={getRowClassName}
    />
  );
};
export default Table;
