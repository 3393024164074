import { Button, Grid } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import style from './index.module.scss';
import { MainButtonProps } from './interface';

const MainButton = ({ placeholder, disabled, onClick, cn, img }: MainButtonProps) => (
  <Grid className={classNames(style.btn, cn)}>
    <Button
      className={style.rounded_btn}
      disabled={disabled}
      onClick={onClick}
      variant="contained"
      color="primary"
      classes={{ disabled: style.disabled }}
      fullWidth
    >
      {img}
      {placeholder}
    </Button>
  </Grid>
);

export default MainButton;
