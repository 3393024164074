import React from 'react';
import { Grid, InputLabel, makeStyles, Box } from '@material-ui/core';

interface EditFieldProps {
  required?: boolean;
  fieldLabel: string;
  children: JSX.Element;
}

const useStyles = makeStyles(() => ({
  important: {
    color: 'red',
  },
  label: {
    fontWeight: 'bold',
    color: 'black',
  },
}));

export const EditFieldWrapper = ({ required, fieldLabel, children }: EditFieldProps) => {
  const { important, label } = useStyles();

  return (
    <Box sx={{ my: 1 }} style={{ width: '100%' }}>
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item xs={3} container direction="column" justifyContent="space-between">
          <InputLabel className={label}>
            {fieldLabel}
            {required && <span className={important}>*</span>}
          </InputLabel>
        </Grid>
        <Grid item xs={8} container direction="row">
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

EditFieldWrapper.defaultProps = {
  required: false,
};
