import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useFlag } from 'src/hooks';
import styles from './index.module.scss';
import { IAutocompleteProps, DataProps } from './interface';

const AutocompleteMenu = ({ label, data, defaultValue, onChange, disabled }: IAutocompleteProps) => {
  const [autocomplete, openAutoComplete, closeAutoComplete] = useFlag();
  const [selected, setSelected] = useState<DataProps | null>(null);

  useEffect(() => {
    onChange(selected);
  }, [selected]);

  useEffect(() => {
    if (defaultValue && data.length) {
      setSelected(data.find((item: DataProps) => item.name === defaultValue) ?? null);
    }
  }, [data, defaultValue]);

  return (
    <FormControl className={styles.form}>
      {label && <InputLabel className={styles.title}>{label}</InputLabel>}
      <Autocomplete
        value={selected}
        open={autocomplete}
        onOpen={openAutoComplete}
        onClose={closeAutoComplete}
        onChange={(event, newValue) => {
          setSelected(newValue);
        }}
        getOptionLabel={({ name }) => name ?? ''}
        renderOption={(props, option) => (
          <li {...props} key={option.id ?? option.value}>
            {option.name}
          </li>
        )}
        isOptionEqualToValue={(option, value) => option.name === value?.name}
        options={data}
        disabled={disabled}
        noOptionsText="Нічого не знайдено"
        renderInput={(params) => <TextField {...params} key={params.id} placeholder="Не вибрано" />}
      />
    </FormControl>
  );
};

AutocompleteMenu.defaultProps = {
  label: null,
  defaultValue: null,
  disabled: false,
};

export default AutocompleteMenu;
