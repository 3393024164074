import { actionTypes } from './types';

export interface ICategory {
  id: number;
  name: string;
  picture: string;
  faqArticlesCount: number;
  created: string;
  updated: string;
  createdBy: string;
  createdByName: string;
  lastUpdatedBy: string;
  lastUpdatedByName: string;
  isPermanent: boolean;
}
export interface IAddCategory {
  id?: number;
  name: string;
  picture: string;
}

export interface IFilterCategory {
  name: string;
}

export const addCategoryFAQ = (category: IAddCategory) => ({
  type: actionTypes.ADD_CATEGORY_FAQ,
  payload: category,
});

export const getCategoriesFAQ = () => ({
  type: actionTypes.GET_CATEGORIES_FAQ,
});

export const getCategoriesByNameFAQ = (filter: IFilterCategory) => ({
  type: actionTypes.GET_CATEGORIES_BY_NAME_FAQ,
  payload: filter,
});
export const getCategoryInfo = (category: ICategory) => ({
  type: actionTypes.GET_CATEGORY_INFO,
  payload: category,
});

export const clearCategoryInfo = () => ({
  type: actionTypes.CLEAR_CATEGORY_INFO,
});

export const editCategoryFAQ = (category: IAddCategory) => ({
  type: actionTypes.EDIT_CATEGORY_FAQ,
  payload: category,
});

export const removeCategoryFAQ = (categoryId: number) => ({
  type: actionTypes.REMOVE_CATEGORY_FAQ,
  payload: categoryId,
});
