import React from 'react';
import { Grid, Link, Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SecondaryBtn from '../../../components/SecondaryBtn';
import MainButton from '../../../components/MainBtn';
import style from './index.module.scss';

interface IActionButtonsProps {
  onCloseModal: () => void;
  onSaveModal: () => void;
  handleDeleteBtn: () => void;
  handleCancelBtn: () => void;
  disabled: boolean;
  isEditMode: boolean;
  isReminderTypeScheduled: boolean;
  error: boolean;
  formData: any;
}

export const ActionButtons = ({
  onCloseModal,
  onSaveModal,
  disabled,
  isEditMode,
  isReminderTypeScheduled,
  handleDeleteBtn,
  handleCancelBtn,
  error,
  formData,
}: IActionButtonsProps | any) => {
  const disabledButton = (): boolean => {
    if (error || !formData.name || !formData.shortDescription || !formData.longDescription) {
      return true;
    }
    return disabled;
  };
  return (
    <Box sx={{ mx: 4, my: 2 }}>
      <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
        {isEditMode && (
          <Grid item xs={3} className={style.delete_container}>
            <Link component="button" className={style.delete_link} onClick={handleDeleteBtn}>
              <DeleteIcon />
              <Grid>Видалити Нагадування</Grid>
            </Link>
          </Grid>
        )}
        <Grid item className={style.btn_gap} xs={2}>
          <SecondaryBtn placeholder="Скасувати" onClick={onCloseModal} />
        </Grid>
        {isReminderTypeScheduled && (
          <Grid item className={style.btn_gap} xs={3}>
            <SecondaryBtn placeholder="Скасувати відправку" onClick={handleCancelBtn} />
          </Grid>
        )}
        <Grid item className={style.btn_gap} xs={2}>
          <MainButton placeholder="Зберегти" onClick={onSaveModal} disabled={disabledButton()} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ActionButtons;
