import React, { createContext, useContext, ReactElement, useState, useEffect } from 'react';
import { useFlag } from '../../hooks';
import { IPushNotification } from './constants';

type ModalContextType = {
  openedModal: boolean;
  openModal: () => void;
  closeModal: () => void;
  openEditModal: (pushData: IPushNotification) => void;
  pushToEdit: IPushNotification | null;
};
const abstractMethod = () => {
  throw Error('method must be implemented');
};
const defaultValue: ModalContextType = {
  pushToEdit: null,
  openedModal: false,
  openModal: abstractMethod,
  closeModal: abstractMethod,
  openEditModal: abstractMethod,
};
export const ModalContext = createContext<ModalContextType>(defaultValue);
export const useModal = () => useContext(ModalContext);

const clearPushState = (openedModal: boolean, setPushToEdit: (pushInfo: null) => void) => {
  if (!openedModal) {
    setPushToEdit(null);
  }
};

export const ModalContextProvider = ({ children }: { children: ReactElement }) => {
  const [openedModal, openModal, closeModal] = useFlag();
  const [pushToEdit, setPushToEdit] = useState<IPushNotification | null>(null);

  const openEditModal = (pushData: IPushNotification) => {
    setPushToEdit(pushData);
    openModal();
  };

  useEffect(() => {
    clearPushState(openedModal, setPushToEdit);
  }, [openedModal]);

  const modalContext = {
    openedModal,
    openModal,
    closeModal,
    openEditModal,
    pushToEdit,
  };

  return <ModalContext.Provider value={modalContext}>{children}</ModalContext.Provider>;
};
