import { useEffect } from 'react';

export const useConfirmReloadPage = (hasChanged: boolean): void => {
  const beforeUnload = (event: BeforeUnloadEvent): void => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.returnValue = '';
  };

  useEffect(() => {
    if (hasChanged) {
      window.addEventListener('beforeunload', beforeUnload);
    }
    return () => {
      if (hasChanged) {
        window.removeEventListener('beforeunload', beforeUnload);
      }
    };
  }, [hasChanged]);
};
