import React from 'react';

import AddAdminModal from '../AddAdminModal';
import AddBtn from '../../../../../components/AddBtn';
import { useFlag } from '../../../../../hooks/useFlag';

const AddAdmin = () => {
  const [isOpen, onOpen, onClose] = useFlag();

  return (
    <>
      <AddBtn placeholder="Додати учасника" onClick={onOpen} />
      <AddAdminModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default AddAdmin;
