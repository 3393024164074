import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';

interface IAdmin {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  status: number;
  role: string[];
}

export interface IAdminsState {
  isLoading: boolean;
  admins: IAdmin[];
  error: any;
}

const initialState: IAdminsState = {
  isLoading: false,
  admins: [],
  error: null,
};

export const adminsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.GET_ADMINS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case getSuccessAction(actionTypes.GET_ADMINS):
      return {
        ...state,
        admins: action.payload,
        isLoading: false,
      };
    case getFailAction(actionTypes.GET_ADMINS):
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };

    default:
      return state;
  }
};
