import { IDonationType } from 'src/interfaces';
import { actionTypes } from './types';

export const getDonationTypes = () => ({
  type: actionTypes.GET_DONATION_TYPES,
});

export const saveDonationType = (donation: IDonationType) => ({
  type: actionTypes[donation.id ? 'EDIT_DONATION_TYPE' : 'ADD_DONATION_TYPE'],
  payload: { ...donation, id: donation.id || undefined },
});

export const deleteDonationType = (id: number) => ({
  type: actionTypes.CLEAR_DONATION_TYPE,
  payload: id,
});
