import React from 'react';
import { useSelector } from 'react-redux';
import { selectQuestions } from '../../../../selectors/questions';

export const ResultToBold = ({ question }: any) => {
  const { search } = useSelector(selectQuestions);

  const parts = question.split(new RegExp(`(${search})`, 'gi'));
  return <span>{parts.map((part: any) => (part.toLowerCase() === search.toLowerCase() ? <b>{part}</b> : part))}</span>;
};
