import { ChangeEvent, useEffect, useState } from 'react';
import _ from 'lodash';
import { formatTimeToUtc } from 'src/utils/dates/formatDateHelpers';

import { useActions, useConfirmReloadPage } from '../../../hooks';
import {
  SubmitData,
  FieldNames,
  IProps,
  IPushNotification,
  IPushNotificationCategory,
  isReminderTypeScheduled,
  IPushNotificationTrigger,
} from '../constants';
import { DropDownElement } from '../../../generics/DropDownElemnt';

const setIsModalDataChanged = (
  defaultState: IPushNotification | null,
  formState: SubmitData,
  setIsEqualCallback: (value: boolean) => void
) => {
  setIsEqualCallback(
    defaultState ? !_.isEqual(new SubmitData(defaultState), formState) : _.some(formState, (field) => !!field)
  );
};

export const usePushNotification = ({
  defaultValues,
  onCloseModal,
  type,
  isEditMode,
  pushNotificationCategories,
  pushNotificationTriggers,
  createdBy,
  updatedBy,
}: IProps) => {
  const [formData, setFormData] = useState<SubmitData>(new SubmitData(defaultValues));
  const [hasChanged, setHasChanged] = useState(false);
  const {
    addPushNotification,
    editPushNotification,
    deletePushNotification,
    cancelPushNotification,
  } = useActions();

  useConfirmReloadPage(hasChanged);

  useEffect(() => {
    setIsModalDataChanged(defaultValues, formData, setHasChanged);
  }, [formData]);

  const handleDataChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.id]: event.target.value });
  };

  const handleCategoryChange = (event: ChangeEvent<DropDownElement>) => {
    setFormData({ ...formData, categoryId: event.target.value as string });
  };

  const handleTriggerChange = (event: ChangeEvent<DropDownElement>) => {
    setFormData({ ...formData, triggerName: event.target.value as string });
  };

  const handleDaysNumberChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormData({
      ...formData,
      days: +event.target.value,
      sendTime: `${event.target.value}d${formData?.hours}h${formData?.minutes}m`,
    });
  };

  const handleHoursChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormData({
      ...formData,
      hours: +event.target.value,
      sendTime: `${formData?.days}d${event.target.value}h${formData?.minutes}m`,
    });
  };

  const handleMinutesChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormData({
      ...formData,
      minutes: +event.target.value,
      sendTime: `${formData?.days}d${formData?.hours}h${event.target.value}m`,
    });
  };

  const handleStatusChange = () => {
    setFormData({ ...formData, isDisabled: !formData.isDisabled });
  };

  const handleDistributionChange = (value: [Date | null, Date | null]) => {
    setFormData({ ...formData, notificationDistribution: value[0] || '' });
  };

  const getPushNotificationCategoryId = (categoryName: string) => {
    let categoryId;
    pushNotificationCategories.forEach((category: IPushNotificationCategory) => {
      if (categoryName === category.name) {
        categoryId = category.id;
      }
    });
    return categoryId || 0;
  };

  const getPushNotificationTriggerId = (triggerName: string) => {
    let triggerId = 0;
    pushNotificationTriggers && pushNotificationTriggers.forEach((trigger: IPushNotificationTrigger) => {
      if (triggerName === trigger.name) {
        triggerId = trigger.id;
      }
    });
    return triggerId;
  };

  const cleanupAutomatedFormData = (form: SubmitData) => {
    const formDataClone = { ...form };
    delete formDataClone.days;
    delete formDataClone.hours;
    delete formDataClone.minutes;

    return formDataClone;
  }

  const prepareEditFormForSending = (form: SubmitData) => {
    if (isReminderTypeScheduled(type)) {
      return {
        ...defaultValues,
        ...form,
        categoryId: getPushNotificationCategoryId(formData.categoryId as string),
        notificationDistribution: formData.notificationDistribution,
      };
    }

    return {
      ...defaultValues,
      ...cleanupAutomatedFormData(form),
      triggerId: getPushNotificationTriggerId(formData.triggerName as string),
      categoryId: getPushNotificationCategoryId(formData.categoryId as string),
    };
  };

  const prepareAddFormForSending = (form: SubmitData) => {
    if (isReminderTypeScheduled(type)) {
      return {
        ...form,
        categoryId: getPushNotificationCategoryId(formData.categoryId as string),
        notificationDistribution: formatTimeToUtc((formData.notificationDistribution as Date).toJSON()),
      }
    }

    return {
      ...cleanupAutomatedFormData(form),
      categoryId: getPushNotificationCategoryId(formData.categoryId as string),
      triggerId: getPushNotificationTriggerId(formData.triggerName as string),
    };
  };

  const handleSaveModal = () => {
    if (isEditMode) {
      editPushNotification(prepareEditFormForSending(formData));
    } else {
      addPushNotification(prepareAddFormForSending(formData));
    }
    onCloseModal();
  };

  const handleDeletePushNotification = () => {
    deletePushNotification(defaultValues.id);
    onCloseModal();
  };

  const handleCancelPushNotification = () => {
    isReminderTypeScheduled(type) && cancelPushNotification({
      ...defaultValues,
      ...formData,
      categoryId: getPushNotificationCategoryId(formData.categoryId as string),
      notificationDistribution: formData.notificationDistribution,
      isDisabled: true,
    });
    onCloseModal();
  };

  const isScheduledTypeValid = () => !(
    formData[FieldNames.Name].trim() &&
    formData[FieldNames.ShortDescription].trim() &&
    formData[FieldNames.LongDescription].trim() &&
    formData[FieldNames.CategoryId] &&
    formData[FieldNames.NotificationDistribution]
  );

  const isAutomatedTypeValid = () => !(
    formData[FieldNames.Name].trim() &&
    formData[FieldNames.ShortDescription].trim() &&
    formData[FieldNames.LongDescription].trim() &&
    formData[FieldNames.CategoryId] &&
    formData[FieldNames.TriggerName] &&
    Number(formData[FieldNames.Days]) >= 0 &&
    Number(formData[FieldNames.Hours]) >= 0 &&
    Number(formData[FieldNames.Minutes]) >= 0
  );

  const isSaveValid = () =>
    isReminderTypeScheduled(type) ? isScheduledTypeValid() : isAutomatedTypeValid();

  const authorInfo = () => {
    if (defaultValues) {
      return {
        createdBy: [createdBy?.firstName, createdBy?.lastName].join(' ') || '',
        createDate: defaultValues.created || '',
        lastUpdatedBy: [updatedBy?.firstName, updatedBy?.lastName].join(' ') || '',
        updateDate: defaultValues.updated || '',
      };
    }
    return null;
  };

  return {
    handleDataChange,
    handleCategoryChange,
    handleTriggerChange,
    handleDaysNumberChange,
    handleHoursChange,
    handleMinutesChange,
    handleDistributionChange,
    handleStatusChange,
    handleSaveModal,
    handleDeletePushNotification,
    handleCancelPushNotification,
    isSaveValid,
    formData,
    authorInfo,
    hasChanged,
  };
};
