import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { IDonationCenter } from './donationCenterModal';

export interface IDonationCentersListState {
  isLoading: boolean;
  donationCenters: IDonationCenter[];
  filteredDonationCenters: IDonationCenter[];
  error: any;
}

export const initialState: IDonationCentersListState = {
  isLoading: false,
  donationCenters: [],
  filteredDonationCenters: [],
  error: null,
};

export const donationCentersReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.GET_DONATION_CENTERS:
    case actionTypes.GET_DONATION_CENTERS_IN_CITIES:
      return {
        ...state,
        isLoading: true,
        error: null,
        donationCenters: [],
      };
    case actionTypes.GET_FILTERED_DONATION_CENTERS:
      return {
        ...state,
        isLoading: false,
        error: null,
        filteredDonationCenters: action.payload,
      };
    case getSuccessAction(actionTypes.GET_DONATION_CENTERS):
    case getSuccessAction(actionTypes.GET_DONATION_CENTERS_IN_CITIES):
      return {
        ...state,
        donationCenters: action.payload,
        isLoading: false,
      };
    case getFailAction(actionTypes.GET_DONATION_CENTERS):
    case getFailAction(actionTypes.GET_DONATION_CENTERS_IN_CITIES):
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case getSuccessAction(actionTypes.ADD_DONATION_CENTER):
      return {
        ...state,
        donationCenters: [action.payload, ...state.donationCenters],
        isLoading: false,
      };
    case getSuccessAction(actionTypes.EDIT_DONATION_CENTER):
      return {
        ...state,
        donationCenters: [...state.donationCenters].map((item) =>
          action.payload.id === item.id ? action.payload : item
        ),
        isLoading: false,
      };

    default:
      return state;
  }
};
