import { actionTypes, IAction } from '../../../../actions/types';
import { ICategory } from '../../../../actions/category';

export interface IEditCategoryState {
  selectedCategory: null | ICategory;
  isLoading: boolean;
  error: any;
}

export const initialState: IEditCategoryState = {
  selectedCategory: null,
  isLoading: false,
  error: null,
};

export const editCategoryReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORY_INFO: {
      return {
        ...state,
        selectedCategory: action.payload,
        isLoading: false,
      };
    }
    case actionTypes.CLEAR_CATEGORY_INFO: {
      return {
        ...state,
        selectedCategory: null,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
