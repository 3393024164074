import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import Admins from './Admins/Admins';
import Donors from './Donors/Donors';
import style from './index.module.scss';
import LinkTab from '../../../components/LinkTab';

const TabsUsers = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <div className={style.tabs}>
        <LinkTab placeholder="Команда АК" path={`${path}/admins`} />
        <LinkTab placeholder="Донори" path={`${path}/donors`} />
      </div>

      <div className={style.table}>
        <Switch>
          <Redirect exact from={`${path}`} to={`${path}/admins`} />
          <Route path={`${path}/admins`} component={Admins} />
          <Route path={`${path}/donors`} component={Donors} />
        </Switch>
      </div>
    </>
  );
};
export default TabsUsers;
