import { DropdownData } from './interface';
import { Question } from '../Models/Question';

export const useGetUniqe = (questions: Question[]) => {
  const getUniqueCategories = questions.reduce((acc: DropdownData[], current: Question) => {
    const isAdded = acc.some((item: DropdownData) => item.name === current.category?.name);
    if (!isAdded) {
      acc.push({ id: current.category?.id, name: current.category?.name || '' });
    }
    return acc;
  }, []);

  const getUniqueAuthors = questions.reduce((acc: DropdownData[], current: Question) => {
    const isAdded = acc.find((item: DropdownData) => item.name === current.createdByName);
    if (!isAdded) {
      acc.push({ id: current.id, name: current.createdByName });
    }
    return acc;
  }, []);

  return {
    getUniqueCategories,
    getUniqueAuthors,
  };
};
