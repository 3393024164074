import React from 'react';
import { NavLink } from 'react-router-dom';
import style from './index.module.scss';
import { LinkTabProps } from './interface';

const LinkTab = ({ placeholder, path }: LinkTabProps) => (
  <NavLink to={path} className={style.linkTab} activeClassName={style.selected}>
    {placeholder}
  </NavLink>
);

export default LinkTab;
