import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';

export interface IAward {
  id?: number;
  name: string;
  description: string;
  picture: string;
  createDate?: string;
  createdBy?: string;
  updateDate?: string;
  lastUpdatedBy?: string;
}

export interface IAwards {
  isLoading: boolean;
  awards: IAward[];
  error: string | null;
}

export const initialState: IAwards = {
  isLoading: false,
  awards: [],
  error: null,
};

export const awardsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.UPDATE_AWARD: {
      return {
        ...state,
        awards: state.awards.map((award) => {
          if (award.id === action.payload.id) {
            return action.payload;
          }

          return award;
        }),
      };
    }
    case actionTypes.GET_AWARDS:
      return {
        ...state,
        isLoading: !state.awards.length,
        error: null,
      };
    case getSuccessAction(actionTypes.GET_AWARDS):
      return {
        ...state,
        awards: action.payload,
        isLoading: false,
      };
    case getFailAction(actionTypes.GET_AWARDS):
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};
