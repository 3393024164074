import { IAddDonationCenter } from '../../../actions/donationCenters';
import { IAction, actionTypes } from '../../../actions/types';

export interface IDonationCenterState {
  selectedDC: null | IAddDonationCenter;
  isLoading: boolean;
  error: any;
}

export const initialState: IDonationCenterState = {
  selectedDC: null,
  isLoading: false,
  error: null,
};

export const editDonationCenterReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.GET_INFO_DONATION_CENTER: {
      return {
        ...state,
        selectedDC: action.payload,
        isLoading: false,
      };
    }
    case actionTypes.CLEAR_INFO_DONATION_CENTER: {
      return {
        ...state,
        selectedDC: null,
        isLoading: false,
      };
    }
    default:
      return { ...state };
  }
};
