import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import SecondaryButton from '../../../../../components/SecondaryBtn';
import ConfirmationModal from '../../../../../components/ConfirmationModal';
import { useCategoryModal } from '../useCategoryModal';
import { useActions } from '../../../../../hooks/useActions';
import { useFlag } from '../../../../../hooks/useFlag';
import { editSelectedCategory } from '../../../../../selectors/category';

interface ICategoryCancel {
  name: string;
  picture: string;
}

export const CategoryCancelBtn = ({ name, picture }: ICategoryCancel) => {
  const { clearCategoryInfo } = useActions();
  const { closeModal } = useCategoryModal();
  const { selectedCategory } = useSelector(editSelectedCategory);

  const [confirmationModalVisible, openConfirmationModal, closeConfirmationModal] = useFlag();
  const [startValues, setStartValues] = useState({ name: '', pictire: '' });

  const currentValues = { name, picture };

  useEffect(() => {
    if (selectedCategory) {
      setStartValues({ name: selectedCategory?.name, pictire: selectedCategory?.picture });
    }
  }, []);

  const handleCloseModal = () => {
    if (!_.isEqual(startValues.name, currentValues.name) || !_.isEqual(startValues.pictire, currentValues.picture)) {
      openConfirmationModal();
    } else {
      closeModal();
      clearCategoryInfo();
    }
  };

  const handleConfirmModal = () => {
    closeModal();
    clearCategoryInfo();
  };

  return (
    <>
      <Grid style={{ margin: '0 15px' }} item xs={2}>
        <SecondaryButton placeholder="Скасувати" onClick={handleCloseModal} />
      </Grid>
      <ConfirmationModal
        title="Вихід"
        isOpen={confirmationModalVisible}
        leftButtonLabel="Cкасувати"
        rightButtonLabel="Вихід"
        onClose={closeConfirmationModal}
        onCloseAllModals={handleConfirmModal}
      >
        <p>Ви дійсно бажаєте вийти без збереження змін?</p>
        <p>Усі внесені дані не буде збереженно.</p>
      </ConfirmationModal>
    </>
  );
};
