import { IAddDonationCenter } from '../actions/donationCenters';
import { authorizedInstance } from './axiosInstances';

enum APIUrl {
  DonationCenters = '/donationCenters',
}

export const getDonationCenters = () => authorizedInstance.get(APIUrl.DonationCenters);

export const getDonationCentersByFilter = (name?: string, regionId?: number, cityId?: number) => {
  const donationCenterName = name ? `donationCenterName=${name}` : '';
  const region = regionId ? `regionId=${regionId}` : '';
  const city = cityId ? `cityId=${cityId}` : '';

  return authorizedInstance.get(`${APIUrl.DonationCenters}?${donationCenterName}${region}${city}`);
};

export const addDonationCenters = (payload: IAddDonationCenter) =>
  authorizedInstance.post(APIUrl.DonationCenters, { ...payload });

export const editDonationCenters = (payload: IAddDonationCenter) =>
  authorizedInstance.put(APIUrl.DonationCenters, { ...payload });

export const clearDonationCenter = (donationCenterId: number) =>
  authorizedInstance.delete(APIUrl.DonationCenters, { params: { donationCenterId } });
