import { actionTypes } from './types';
import { IServerData } from '../pages/Awards/models/ServerData.interface';

export const getAwards = () => ({
  type: actionTypes.GET_AWARDS,
});

export const addAward = (award: IServerData) => ({
  type: actionTypes.ADD_AWARD,
  payload: award,
});

export const editAward = (award: IServerData) => ({
  type: actionTypes.EDIT_AWARD,
  payload: award,
});
export const updateAward = (award: IServerData) => ({
  type: actionTypes.UPDATE_AWARD,
  payload: award,
});

export const deleteAward = (id: number) => ({
  type: actionTypes.CLEAR_AWARD,
  payload: id,
});
