import _ from 'lodash';
import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { LoginUser } from '../actions/authorization';

export interface LoginState {
  isLoading: boolean;
  user: LoginUser | null;
  error: any;
}

const initialState: LoginState = {
  isLoading: false,
  user: null,
  error: null,
};

export const loginReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case actionTypes.LOGOUT_USER:
      return {
        ...state,
        user: null,
        isLoading: true,
        error: null,
      };
    case getSuccessAction(actionTypes.LOGIN_USER): {
      return {
        ...state,
        user: _.omit(action.payload, ['token', 'refreshToken']),
        isLoading: false,
      };
    }
    case getFailAction(actionTypes.LOGIN_USER):
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};
