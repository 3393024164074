import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import { useFlag } from '../../../../../hooks/useFlag';
import { Option } from '../../Models/Option';
import { FieldNames } from '../constants';
import { useActions } from '../../../../../hooks/useActions';
import { useSelectComponentStyles } from './select-component-classes';
import { ErrorMessages } from '../../../../../utils/formFields/formFields.constants';

interface DataProps {
  options: Option[];
  loading: boolean;
  defaultValue: Option | undefined;
  onValueChange: (data: { [key: string]: Option | null }) => void;
  fieldId: FieldNames;
  startValidate: boolean;
  // eslint-disable-next-line
  maxLength?: number;
}

export const SelectComponent = ({
  options = [],
  loading = false,
  fieldId,
  onValueChange,
  defaultValue,
  startValidate = false,
  maxLength,
}: DataProps) => {
  const [showOptions, openAutoComplete, closeAutoComplete] = useFlag();
  const { getCategoryList } = useActions();
  const classes = useSelectComponentStyles();
  const [value, setValue] = useState<Option | null>(defaultValue || null);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (showOptions && options.length === 0) {
      getCategoryList();
    }
  }, [showOptions]);

  useEffect(() => {
    checkValidation();
  }, [startValidate]);

  useEffect(() => {
    checkValidation();
    onValueChange({ [fieldId]: value });
  }, [value]);

  const checkValidation = () => {
    setError(startValidate && !value ? ErrorMessages.RequiredField : '');
  };

  const onType = (event: any) => {
    setError(maxLength && event.target.value.length > maxLength ? `${ErrorMessages.OversizeText} ${maxLength}` : '');
  };

  return (
    <Autocomplete
      value={value}
      sx={{ width: '145%' }}
      open={showOptions}
      onOpen={openAutoComplete}
      onClose={closeAutoComplete}
      onInput={onType}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      isOptionEqualToValue={(option, category) => option.id === category.id}
      getOptionLabel={({ name }) => name}
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.id}>
          {option.name}
        </li>
      )}
      options={options}
      loading={loading}
      classes={classes}
      fullWidth
      renderInput={(params) => (
        <TextField
          error={!!error}
          helperText={error}
          onBlur={checkValidation}
          variant="outlined"
          // eslint-disable-next-line
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              // eslint-disable-next-line
              <>
                {loading && <CircularProgress color="inherit" size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
