import { IDonationType } from 'src/interfaces';
import { authorizedInstance } from './axiosInstances';

const URL = '/donation/types';

export const getDonationTypes = () => authorizedInstance.get(URL);

export const addDonationType = (data: IDonationType) => authorizedInstance.post(URL, data);

export const editDonationType = (data: IDonationType) => authorizedInstance.put(URL, data);

export const deleteDonationType = (id: number) => authorizedInstance.delete(`${URL}/${id}`);
