import { actionTypes } from './types';
import { IPushNotification, SubmitData } from '../pages/Reminder/constants';

export const getPushNotifications = () => ({
  type: actionTypes.GET_PUSH_NOTIFICATIONS,
});

export const addPushNotification = (remainder: SubmitData) => ({
  type: actionTypes.ADD_PUSH_NOTIFICATION,
  payload: remainder,
});

export const editPushNotification = (remainder: IPushNotification) => ({
  type: actionTypes.EDIT_PUSH_NOTIFICATION,
  payload: remainder,
});

export const deletePushNotification = (id: number) => ({
  type: actionTypes.DELETE_PUSH_NOTIFICATION,
  payload: id,
});

export const cancelPushNotification = (remainder: IPushNotification) => ({
  type: actionTypes.CANCEL_PUSH_NOTIFICATION,
  payload: remainder,
});

export const getFilteredPushNotificationList = (pushNotifications: IPushNotification[]) => ({
  type: actionTypes.GET_FILTER_PUSH_NOTIFICATIONS_LIST,
  payload: pushNotifications,
});

export const getPushNotificationCategories = () => ({
  type: actionTypes.GET_PUSH_NOTIFICATION_CATEGORIES,
});

export const getPushNotificationTriggers = () => ({
  type: actionTypes.GET_PUSH_NOTIFICATION_TRIGGERS,
});

export const getCreatedBy = (id: string) => ({
  type: actionTypes.GET_PUSH_NOTIFICATION_CREATED_BY,
  payload: id,
});

export const getUpdatedBy = (id: string) => ({
  type: actionTypes.GET_PUSH_NOTIFICATION_UPDATED_BY,
  payload: id,
});
