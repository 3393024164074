export interface IDataOption {
  id: string | number;
  name?: string;
  question?: string;
}

export class Option {
  id: string | number;

  name: string;

  constructor(data: IDataOption) {
    this.id = data.id;
    this.name = data.name || data.question || '';
  }
}

export const createOptionArray = (data: IDataOption[] = []) => data.map((item) => new Option(item));
