import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { showNotification } from '../actions/notifications';
import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { getDonations, editDonationStatus } from '../api/donations';

export function* getDonationsSaga(action: IAction) {
  try {
    const response: AxiosResponse = yield call(getDonations, action.payload);

    yield put({
      type: getSuccessAction(actionTypes.GET_DONATIONS),
      payload: response.data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.GET_DONATIONS),
      error,
    });
  }
}

export function* editDonationStatusSaga(action: IAction) {
  try {
    const response: AxiosResponse = yield call(editDonationStatus, action.payload);

    yield put({
      type: getSuccessAction(actionTypes.EDIT_DONATION_STATUS),
      payload: response.data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.EDIT_DONATION_STATUS),
      error,
    });
  }
}

export function* watchGetDonations() {
  yield takeLatest(actionTypes.GET_DONATIONS, getDonationsSaga);
}

export function* watchEditDonationStatus() {
  yield takeLatest(actionTypes.EDIT_DONATION_STATUS, editDonationStatusSaga);
}
