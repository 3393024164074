import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions, useFlag } from 'src/hooks';

import { Box, Grid, Modal, TextField } from '@material-ui/core';
import { EditFieldWrapper } from 'src/components/Fields';
import Dropdown from 'src/components/Dropdown';
import { selectPushNotifications } from 'src/selectors/pushNotifications';
import { selectPushNotificationsModal } from 'src/selectors/pushNotificationsModal';
import ConfirmationModal from 'src/components/ConfirmationModal';
import SwitchButton from 'src/components/Switch';
import AuthorInfo from 'src/components/AuthorInfo';
import { usePushNotification } from '../../hook/usePushNotification';
import style from '../ScheduledModal/index.module.scss';
import { ReactComponent as Cross } from '../../../../assets/images/Cross.svg';
import { FieldNames, ReminderTypes } from '../../constants';
import { ActionButtons } from '../../ActionButtons';

interface IAutomatedModalProps {
  onCloseModal: () => void;
  defaultValues: any;
  isOpen: boolean;
}

const AutomatedModal = ({ onCloseModal, defaultValues, isOpen }: IAutomatedModalProps) => {
    const { pushNotificationCategories, pushNotificationTriggers } = useSelector(selectPushNotifications);
    const [isDelete, setIsDelete] = useState(false);

    const [isEditMode, setIsEditMode] = useState(false);

    const [confirmationModalVisible, openConfirmationModal, closeConfirmationModal] = useFlag();
    const [deleteModalVisible, openDeleteModal, closeDeleteModal] = useFlag();

    const { createdBy, updatedBy } = useSelector(selectPushNotificationsModal);
    const { getCreatedBy, getUpdatedBy } = useActions();
    const {
      handleDataChange,
      handleCategoryChange,
      handleTriggerChange,
      handleDaysNumberChange,
      handleHoursChange,
      handleMinutesChange,
      handleStatusChange,
      handleSaveModal,
      handleDeletePushNotification,
      isSaveValid,
      formData,
      hasChanged,
      authorInfo,
    } = usePushNotification({
      defaultValues,
      type: ReminderTypes.Automated,
      onCloseModal,
      isEditMode,
      pushNotificationCategories,
      pushNotificationTriggers,
      createdBy,
      updatedBy,
    });

    useEffect(() => {
      if (defaultValues) {
        setIsEditMode(true);
        getCreatedBy(defaultValues.createdById);
        getUpdatedBy(defaultValues.lastUpdatedById);
      }
    }, [defaultValues]);
  
    const checkIsStateChanged = () => {
      if (!hasChanged) {
        handleCloseModal();
      }
    };

    const formIsNotEmpty =
      formData.name !== '' ||
      formData.shortDescription !== '' ||
      formData.longDescription !== '' ||
      Number(formData?.days) > 0 ||
      Number(formData?.hours) > 0 ||
      Number(formData?.minutes) > 0 ||
      formData.triggerName !== '' ||
      !formData.isDisabled ||
      formData.categoryId !== '';

    const handleCloseModal = () => {
      if(isEditMode && hasChanged){
        openConfirmationModal();
      }
      else if (formIsNotEmpty && !isEditMode) {
        openConfirmationModal();
      } else {
        onCloseModal();
        closeConfirmationModal();
      }
    };

    const handleDeleteBtn = () => {
      setIsDelete(true);
      openDeleteModal();
    };
  
    const handleCloseDeleteModal = () => {
      setIsDelete(false);
      closeDeleteModal();
    };
  
    return (
      <>
        <Modal open={isOpen} onClose={checkIsStateChanged} className={style.modal}>
          <Box className={style.container}>
            <Grid className={style.modal_body}>
              <Grid className={style.modal_body_content}>
                <Cross className={style.cross} onClick={handleCloseModal} />
  
                <h3 className={style.modal_text}>{formData.id ? 'Редагувати автоматизоване нагадування' : 'Додати автоматизоване нагадування'}</h3>
  
                <EditFieldWrapper required fieldLabel="Назва">
                  <TextField
                    id={FieldNames.Name}
                    fullWidth
                    variant="outlined"
                    value={formData.name}
                    onChange={handleDataChange}
                    required
                    placeholder="Введіть дані..."
                  />
                </EditFieldWrapper>
  
                <EditFieldWrapper required fieldLabel="Категорія">
                  <Dropdown
                    data={pushNotificationCategories}
                    value={formData.categoryId}
                    onChange={handleCategoryChange}
                  />
                </EditFieldWrapper>
  
                <EditFieldWrapper required fieldLabel="Короткий опис">
                  <TextField
                    id={FieldNames.ShortDescription}
                    variant="outlined"
                    placeholder="Введіть дані"
                    fullWidth
                    multiline
                    value={formData.shortDescription}
                    minRows={4}
                    maxRows={4}
                    onChange={handleDataChange}
                  />
                </EditFieldWrapper>
  
                <EditFieldWrapper required fieldLabel="Довгий опис">
                  <TextField
                    id={FieldNames.LongDescription}
                    variant="outlined"
                    placeholder="Введіть дані"
                    fullWidth
                    multiline
                    margin="normal"
                    value={formData.longDescription}
                    minRows={8}
                    maxRows={8}
                    onChange={handleDataChange}
                  />
                </EditFieldWrapper>

                <EditFieldWrapper required fieldLabel="Тригер">
                  <Dropdown
                    data={pushNotificationTriggers}
                    value={formData.triggerName || ''}
                    onChange={handleTriggerChange}
                  />
                </EditFieldWrapper>
  
                <EditFieldWrapper required fieldLabel="Час надсилання">
                  <div className={style.sendingTime}>
                    <TextField type='number' value={formData.days} onChange={handleDaysNumberChange} />
                    <span className={style.timeItem}>дні(в)</span>
                    <TextField type='number' value={formData.hours} onChange={handleHoursChange} />
                    <span className={style.timeItem}>годин</span>
                    <TextField type='number' value={formData.minutes} onChange={handleMinutesChange} />
                    <span className={style.timeItem}>хвилин</span>
                  </div>
                </EditFieldWrapper>

                <EditFieldWrapper fieldLabel="Статус">
                  <>
                    <SwitchButton checked={!formData.isDisabled} onChange={handleStatusChange} />
                    <span className={style.activated}>{formData.isDisabled ? 'Вимкнено' : 'Активовано'}</span>
                  </>
                </EditFieldWrapper>

                <AuthorInfo isEditMode authorInfo={authorInfo()} />
    
                <ActionButtons
                  onCloseModal={handleCloseModal}
                  onSaveModal={handleSaveModal}
                  handleCancelBtn={handleCloseModal}
                  handleDeleteBtn={handleDeleteBtn}
                  isEditMode={isEditMode}
                  isReminderTypeScheduled={false}
                  disabled={isSaveValid()}
                  formData={formData}
                />
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <ConfirmationModal
          title="Вихід"
          isOpen={confirmationModalVisible}
          leftButtonLabel="Cкасувати"
          rightButtonLabel="Вихід"
          onClose={closeConfirmationModal}
          onCloseAllModals={onCloseModal}
        >
          <p>Ви дійсно бажаєте вийти без збереження змін?</p>
          <p>Усі внесені дані не буде збереженно.</p>
        </ConfirmationModal>
        <ConfirmationModal
          title="Видалення"
          isOpen={deleteModalVisible}
          leftButtonLabel="Cкасувати"
          rightButtonLabel="Видалити"
          onClose={handleCloseDeleteModal}
          onCloseAllModals={isDelete ? handleDeletePushNotification : handleCloseModal}
        >
          <p>Ви дійсно бажаєте видалити це нагадування?</p>
          <p>Нагадування буде переміщено до минулих.</p>
        </ConfirmationModal>
      </>
    );
  };
  
  export default AutomatedModal;