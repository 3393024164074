import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import style from './index.module.scss';
import { formatDateToUATime } from '../../utils/dates/formatDateHelpers';

interface IAuthorInfo {
  createdBy: string | undefined;
  createDate: string | undefined;
  lastUpdatedBy: string | undefined;
  updateDate: string | undefined;
}

interface IBadgeAuthorInfo {
  isEditMode: boolean;
  authorInfo: IAuthorInfo | null;
}

const AuthorInfo = ({ isEditMode, authorInfo }: IBadgeAuthorInfo) =>
  (isEditMode && authorInfo && (
    <>
      <Grid container direction="row" justifyContent="center" alignItems="center" className={style.author_info}>
        <Grid item xs={3} container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
          <InputLabel className={style.created_label}>Створено</InputLabel>
        </Grid>
        <Grid item xs={8} container direction="row">
          <Grid item xs={4}>
            {authorInfo.createdBy}
          </Grid>
          <Grid item xs={4}>
            {formatDateToUATime(authorInfo.createDate)}
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justifyContent="center" alignItems="center" className={style.author_info}>
        <Grid item xs={3} container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
          <InputLabel className={style.created_label}>Оновлено</InputLabel>
        </Grid>
        <Grid item xs={8} container direction="row">
          <Grid item xs={4}>
            {authorInfo.lastUpdatedBy}
          </Grid>
          <Grid item xs={4}>
            {formatDateToUATime(authorInfo.updateDate)}
          </Grid>
        </Grid>
      </Grid>
    </>
  )) ||
  null;

export default AuthorInfo;
