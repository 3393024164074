export const addAdminInitialState = {
  firstName: {
    value: '',
    isInvalid: false,
    validationMsg: '',
  },
  lastName: {
    value: '',
    isInvalid: false,
    validationMsg: '',
  },
  email: {
    value: '',
    isInvalid: false,
    validationMsg: '',
  },
};
