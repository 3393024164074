import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { IAdmin, IPushNotification } from '../pages/Reminder/constants';

export interface IPushNotificationModalState {
  isLoading: boolean;
  pushNotification: IPushNotification[] | [];
  createdBy?: IAdmin;
  updatedBy?: IAdmin;
  error: any;
}

export const initialState: IPushNotificationModalState = {
  isLoading: false,
  pushNotification: [],
  error: null,
};

export const pushNotificationModalReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.ADD_PUSH_NOTIFICATION:
    case actionTypes.EDIT_PUSH_NOTIFICATION:
    case actionTypes.CANCEL_PUSH_NOTIFICATION:
      return {
        ...state,
        isLoading: true,
        error: null,
        pushNotification: [],
      };
    case getSuccessAction(actionTypes.GET_PUSH_NOTIFICATION_CREATED_BY): {
      return {
        ...state,
        createdBy: action.payload,
      };
    }
    case getSuccessAction(actionTypes.GET_PUSH_NOTIFICATION_UPDATED_BY): {
      return {
        ...state,
        updatedBy: action.payload,
      };
    }
    case getSuccessAction(actionTypes.ADD_PUSH_NOTIFICATION):
    case getSuccessAction(actionTypes.EDIT_PUSH_NOTIFICATION):
      return {
        ...state,
        pushNotification: [...state.pushNotification, action.payload],
        isLoading: false,
      };

    case getFailAction(actionTypes.ADD_PUSH_NOTIFICATION):
    case getFailAction(actionTypes.EDIT_PUSH_NOTIFICATION):
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
