import React from 'react';
import { useGridSlotComponentProps } from '@mui/x-data-grid';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import style from './index.module.scss';
import { ITablePaginationProps } from './interface';

const TablePagination = ({ rows, isLoading }: ITablePaginationProps) => {
  const { state, apiRef } = useGridSlotComponentProps();
  const {
    pagination: { page, pageSize, rowCount, pageCount },
  } = state;
  const fromShownRecords = page * pageSize + 1;
  const toShownRecords = (page + 1) * pageSize > rowCount ? rowCount : (page + 1) * pageSize;
  const recordsInfo = `${fromShownRecords} - ${toShownRecords} з ${rowCount}`;
  const getRowsSum = () =>
    rows.length === rowCount && rowCount !== 0 ? `Показано: ${recordsInfo}` : `Показано 0 записів`;

  const paginationTitle = isLoading ? null : getRowsSum();

  return (
    <>
      <Typography className={style.title} variant="subtitle1">
        {paginationTitle}
      </Typography>
      <Pagination
        color="primary"
        variant="outlined"
        showFirstButton
        showLastButton
        page={page + 1}
        count={pageCount}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderItem={(props) => <PaginationItem {...props} />}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    </>
  );
};

export default TablePagination;
