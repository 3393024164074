export enum FieldNames {
  category = 'category',
  question = 'question',
  answer = 'answer',
  picture = 'picture',
  relatedQuestions = 'relatedQuestions',
}

export enum MaxLength {
  category = 60,
  question = 160,
  answer = 5000,
}
