import React from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { roleOptions, statusOptions } from '../Filters/AdminsFilters/optionsData';
import { fullNameRenderer, optionNameRenderer, phoneRenderer } from '../util/tableRenderers';
import AdminActionBtns from './AdminActionBtns/AdminActionBtns';

export const adminsTableColumns: GridColDef[] = [
  {
    field: 'fullName',
    headerName: 'Ім‘я, прізвище',
    width: 300,
    sortable: true,
    valueGetter: fullNameRenderer,
  },
  {
    field: 'phone',
    headerName: 'Телефон',
    width: 200,
    valueGetter: phoneRenderer,
  },
  { field: 'email', headerName: 'Email', width: 400 },
  {
    field: 'status',
    headerName: 'Статус',
    width: 200,
    valueGetter: optionNameRenderer(statusOptions),
  },
  {
    field: 'role',
    headerName: 'Роль',
    width: 200,
    valueGetter: optionNameRenderer(roleOptions),
  },
  {
    field: 'actions',
    headerName: ' ',
    width: 200,
    renderCell: (params: GridCellParams) => (
      <AdminActionBtns userEmail={params.row.email} userStatus={params.row.status} />
    ),
  },
];
