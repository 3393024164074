import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';
import { IQuestions, IQuestionsList } from '../../Constants';
import style from './index.module.scss';

export const QuestionInCategory = ({ questionsList }: IQuestionsList) => (
  <>
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={3} container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
        <InputLabel style={{ fontWeight: 'bold', color: 'black' }}>Питання в категорії</InputLabel>
      </Grid>
      {questionsList.length > 0 ? (
        <Grid item xs={8} container direction="column">
          {questionsList.map((item: IQuestions) => (
            <p key={item.id} className={style.question}>
              {item.question}
            </p>
          ))}
          <Grid item className={style.requirements_text}>
            * Щоб видалити категорію у ній не повинно бути жодного питання
          </Grid>
        </Grid>
      ) : (
        <Grid container xs={8} style={{ height: '100px', border: '1px solid #C4C4C4', borderRadius: '5px' }}>
          {null}
        </Grid>
      )}
    </Grid>
  </>
);
