import { put, call, takeEvery } from 'redux-saga/effects';
import _ from 'lodash';
import { AxiosResponse } from 'axios';
import { loginUser } from '../api/authorization';
import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { getLs, setLs } from '../constants/encryptConf';
import { LocalStorageKeys as LSKeys } from '../constants/local-storage-keys';

export function* loginUserSaga(action: IAction) {
  try {
    const response: AxiosResponse = yield call(loginUser, action.payload);

    for (const [key, value] of Object.entries(response.data)) {
      const timeStamp = getLs(LSKeys.RememberValue) === 'true' ? LSKeys.Forever : `${new Date()}`;

      setLs(key, _.isArray(value) ? value[0] : value);
      localStorage.setItem(LSKeys.TimeStamp, timeStamp);
    }

    localStorage.removeItem(LSKeys.RememberValue);

    yield put({
      type: getSuccessAction(actionTypes.LOGIN_USER),
      payload: response.data,
    });
  } catch (error: unknown) {
    yield put({
      type: getFailAction(actionTypes.LOGIN_USER),
      error,
    });
  }
}

export function* watchLoginUser() {
  yield takeEvery(actionTypes.LOGIN_USER, loginUserSaga);
}
