import { format, lightFormat } from 'date-fns'; //eslint-disable-line
import { zonedTimeToUtc } from 'date-fns-tz';
import { uk } from 'date-fns/locale'; //eslint-disable-line
import { GridValueFormatterParams } from '@mui/x-data-grid';

import { MILLISECONDS_IN_SECOND, SECONDS_IN_MINUTE, MINUTES_IN_HOUR, HOURS_IN_DAY, UA_DATE_FORMAT, UA_DATE_FORMAT_MONTH, UA_DATE_WITH_TIME } from '../../constants/date';

export const formatDate = (date: Date, dateFormat: string) => format(date, dateFormat);

export const formatTimeToUtc = (date: string) => zonedTimeToUtc(date, 'UTC');
export const formatDateNote = (date: string) => lightFormat(new Date(date), 'yyyy.MM.dd hh:mm');

export const formatDateToUA = (date: string) => format(formatTimeToUtc(date), UA_DATE_FORMAT);
export const formatDateToUAMonth = (date: string) =>
  format(formatTimeToUtc(date), UA_DATE_FORMAT_MONTH, { locale: uk });
export const formatDateToUATime = (date: string | undefined) => {
  if (date) return formatDate(formatTimeToUtc(date), UA_DATE_WITH_TIME);
  return null;
};

export const dateFormatter = (params: GridValueFormatterParams) => formatDateToUA(params.value as string);
export const dateFormatterForNotifications = (params: GridValueFormatterParams) =>
  formatDateNote(params.value as string);

export const dateFormatterTimeLeft = (params: GridValueFormatterParams): string => {
  const targetDate: Date = new Date(params.value as string);
  const now: Date = new Date();
  const timeDiff: number = targetDate.getTime() - now.getTime();
  const absTimeDiff: number = Math.abs(timeDiff);
  const days: number = Math.floor(absTimeDiff / (MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR 
                        * HOURS_IN_DAY));
  const hours: number = Math.floor((absTimeDiff / (MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE * MINUTES_IN_HOUR))
                        % HOURS_IN_DAY);
  const minutes: number = Math.floor((absTimeDiff / (MILLISECONDS_IN_SECOND * SECONDS_IN_MINUTE)) % MINUTES_IN_HOUR);
  const timeLeft: string = `${days} днів ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  return (timeDiff < 0 ? `-${timeLeft}` : `+${timeLeft}`);
}
