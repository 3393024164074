import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { IPushNotification, IPushNotificationCategory, IPushNotificationTrigger } from '../pages/Reminder/constants';

export interface IPushNotificationsListState {
  isLoading: boolean;
  pushNotifications: IPushNotification[];
  pushNotificationCategories: IPushNotificationCategory[];
  pushNotificationTriggers: IPushNotificationTrigger[];
  filteredPushNotifications: IPushNotification[];
  error: any;
}

export const initialState: IPushNotificationsListState = {
  isLoading: false,
  pushNotifications: [],
  pushNotificationCategories: [],
  pushNotificationTriggers: [],
  filteredPushNotifications: [],
  error: null,
};

export const pushNotificationsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.GET_PUSH_NOTIFICATIONS:
      return {
        ...state,
        isLoading: true,
        error: null,
        pushNotifications: [],
      };
    case actionTypes.GET_FILTER_PUSH_NOTIFICATIONS_LIST:
      return {
        ...state,
        filteredPushNotifications: action.payload,
      };
    case getSuccessAction(actionTypes.GET_PUSH_NOTIFICATIONS):
      return {
        ...state,
        pushNotifications: action.payload,
        filteredPushNotifications: [],
        isLoading: false,
      };
    case getSuccessAction(actionTypes.GET_PUSH_NOTIFICATION_CATEGORIES):
      return {
        ...state,
        pushNotificationCategories: action.payload,
        isLoading: false,
      };
    case getSuccessAction(actionTypes.GET_PUSH_NOTIFICATION_TRIGGERS):
      return {
        ...state,
        pushNotificationTriggers: action.payload,
        isLoading: false,
      };
    case getFailAction(actionTypes.GET_PUSH_NOTIFICATIONS):
    case getFailAction(actionTypes.GET_PUSH_NOTIFICATION_CATEGORIES):
    case getFailAction(actionTypes.GET_PUSH_NOTIFICATION_TRIGGERS):
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
