import { actionTypes, FieldName, FormField } from './formFields.constants';
import {
  getEmailValidationMsg,
  getNameValidationMsg,
  getPasswordMatch,
  getPasswordValidationMsg,
} from './fieldValitationHelper';
import { addAdminInitialState } from '../../pages/Users/TabsUsers/Admins/AddAdminModal/index.constants';

export const validationFunction = {
  [FieldName.FirstName]: getNameValidationMsg,
  [FieldName.LastName]: getNameValidationMsg,
  [FieldName.Email]: getEmailValidationMsg,
  [FieldName.Password]: getPasswordValidationMsg,
  [FieldName.PasswordConfirm]: getPasswordValidationMsg,
};

export function formFieldsReducer(state: { [key: string]: FormField }, action: any) {
  const { fieldName, value } = action.payload;
  const field = fieldName as FieldName;
  switch (action.type) {
    case actionTypes.ON_CHANGE: {
      return {
        ...state,
        [fieldName]: {
          ...state[field],
          value,
        },
      };
    }
    case actionTypes.VALIDATE: {
      const validationMsg = validationFunction[field](value);
      if (
        state[FieldName.PasswordConfirm] &&
        (fieldName === FieldName.Password || fieldName === FieldName.PasswordConfirm)
      ) {
        return validatePasswords(state, value, fieldName, validationMsg);
      }
      return {
        ...state,
        [fieldName]: {
          ...state[field],
          isInvalid: !!validationMsg,
          validationMsg,
        },
      };
    }
    case actionTypes.SET_ERROR: {
      return {
        ...state,
        [fieldName]: {
          ...state[field],
          isInvalid: true,
          validationMsg: value,
        },
      };
    }
    case actionTypes.CLEAR_FORM: {
      return {
        ...state,
        ...addAdminInitialState,
      };
    }
    default:
      return { ...state };
  }
}
export const onChange = (fieldName: FieldName, value: any) => ({
  type: actionTypes.ON_CHANGE,
  payload: { fieldName, value },
});

export const validate = (fieldName: FieldName, value: any) => ({
  type: actionTypes.VALIDATE,
  payload: { fieldName, value },
});

export const setError = (fieldName: FieldName, value: any) => ({
  type: actionTypes.SET_ERROR,
  payload: { fieldName, value },
});

export const clearForm = () => ({
  type: actionTypes.CLEAR_FORM,
  payload: {},
});

// eslint-disable-next-line sonarjs/cognitive-complexity
function validatePasswords(
  state: {
    [key: string]: FormField;
  },
  value: string,
  field: FieldName.Password | FieldName.PasswordConfirm,
  validationMsg: string
) {
  let passwordDontMatch = '';
  if (!validationMsg) {
    passwordDontMatch = getPasswordMatch(value, state[field === 'password' ? 'passwordConfirm' : 'password'].value);
  }
  if (!getPasswordValidationMsg(state.password.value) && !getPasswordValidationMsg(state.passwordConfirm.value)) {
    return {
      ...state,
      password: {
        ...state.password,
        isInvalid: !!validationMsg || !!passwordDontMatch,
        validationMsg,
      },
      passwordConfirm: {
        ...state.passwordConfirm,
        isInvalid: !!validationMsg || !!passwordDontMatch,
        validationMsg: validationMsg || passwordDontMatch,
      },
    };
  }
  return {
    ...state,
    [field]: {
      ...state[field],
      isInvalid: !!validationMsg,
      validationMsg,
    },
  };
}
