export enum Label {
  Search = 'Пошук',
  Category = 'Категорія',
  Status = 'Статус',
  Author = 'Автор',
  Draft = 'чернетка',
  Published = 'опубліковано',
  Question = 'Питання',
  Updated = 'Оновлено',
  AddQuestion = 'Додати питання',
  EditQuestion = 'Редагувати питання',
  AddNewQuestion = 'Додати нове питання',
  Answer = 'Відповідь',
  Picture = 'Зображення',
  RelatedQuestion = 'Пов`язані питання',
}
