import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SnackbarKey, useSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close as IconClose } from '@material-ui/icons';
import { selectNotifications } from '../../selectors/notifications';
import { useActions } from '../../hooks';
import style from './index.module.scss';

let displayed: SnackbarKey[] = [];

const Notifier = () => {
  const { hideNotification } = useActions();
  const notifications = useSelector(selectNotifications);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = displayed.filter((displayedId) => id !== displayedId);
  };

  useEffect(() => {
    notifications.forEach(({ id, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        closeSnackbar(id);
        removeDisplayed(id);
        return;
      }

      if (displayed.includes(id)) return;

      enqueueSnackbar(message, {
        key: id,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event: any, key) => {
          hideNotification(id);
          removeDisplayed(id);
        },
        action: (key) => (
          <IconButton onClick={() => closeSnackbar(key)}>
            <IconClose className={style.close} />
          </IconButton>
        ),
      });

      storeDisplayed(id);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar]);

  return <></>;
};

export default Notifier;
