export const statusOptions = [
  {
    value: 1,
    name: 'Активний',
  },
  {
    value: 2,
    name: 'Запрошений',
  },
  {
    value: 3,
    name: 'Заблокований',
  },
];

export const roleOptions = [
  {
    value: 'admin',
    name: 'Адмін',
  },
];
