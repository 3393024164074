import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getDonationTypes, addDonationType, editDonationType, deleteDonationType } from '../api/donationTypes';
import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { showNotification } from '../actions/notifications';

export function* getDonationTypesSaga() {
  try {
    const { data } = yield call(getDonationTypes);
    yield put({
      type: getSuccessAction(actionTypes.GET_DONATION_TYPES),
      payload: data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.GET_DONATION_TYPES),
      error,
    });
  }
}

export function* addDonationTypeSaga(action: IAction) {
  try {
    const { data } = yield call(addDonationType, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.ADD_DONATION_TYPE),
      payload: data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.ADD_DONATION_TYPE),
      error,
    });
  }
}

export function* editDonationTypeSage(action: IAction) {
  try {
    const { data } = yield call(editDonationType, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.EDIT_DONATION_TYPE),
      payload: data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.EDIT_DONATION_TYPE),
      error,
    });
  }
}

export function* deleteDonationTypeSage(action: IAction) {
  try {
    const { data } = yield call(deleteDonationType, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.CLEAR_DONATION_TYPE),
      payload: data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.CLEAR_DONATION_TYPE),
      error,
    });
  }
}

export function* watchDonationTypes() {
  yield takeLatest(actionTypes.GET_DONATION_TYPES, getDonationTypesSaga);
  yield takeEvery(actionTypes.ADD_DONATION_TYPE, addDonationTypeSaga);
  yield takeEvery(actionTypes.EDIT_DONATION_TYPE, editDonationTypeSage);
  yield takeEvery(actionTypes.CLEAR_DONATION_TYPE, deleteDonationTypeSage);
}
