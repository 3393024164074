import React from 'react';
import ReactDOM from 'react-dom/client';
import { StylesProvider } from '@material-ui/core/styles';
import App from './app';
import './styles/global.scss';

const root = document.getElementById('root') as Element;
ReactDOM.createRoot(root).render(
  <StylesProvider injectFirst>
    <App />
  </StylesProvider>
);
