import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { IDonation } from './donationModal';
import { IFilters } from '../actions/donations';

export interface IDonationsState {
  filters: IFilters;
  donations: IDonation[];
  isLoading: boolean;
  error: any;
}

export const initialState: IDonationsState = {
  filters: {
    fullName: '',
    bloodType: [],
    dateFrom: null,
    dateTo: null,
    donationTypeIds: [],
    regionIds: [],
    cityIds: [],
    donationCenterIds: [],
    status: [],
    page: undefined,
  },
  donations: [],
  isLoading: false,
  error: null,
};

export const donationsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.GET_DONATIONS:
      return {
        ...state,
        filters: action.payload.filters,
        donations: [],
        isLoading: true,
        error: null,
      };
    case getSuccessAction(actionTypes.GET_DONATIONS):
      return {
        ...state,
        donations: action.payload,
        isLoading: false,
      };
    case getFailAction(actionTypes.GET_DONATIONS):
      return {
        ...state,
        donations: [],
        isLoading: false,
        error: action.error,
      };
    case getSuccessAction(actionTypes.EDIT_DONATION_STATUS):
      return {
        ...state,
        donations: [...state.donations].map((item) => (action.payload.id === item.id ? action.payload : item)),
        isLoading: false,
      };

    default:
      return state;
  }
};
