import { Option } from './Option';
import { IServerData } from './ServerData.interface';

const DefaultValues: IServerData = {
  id: undefined,
  category: undefined,
  question: '',
  answer: '',
  picture: '',
  isDraft: false,
  relatedQuestions: [],
  created: '',
  createdByName: '',
  updated: '',
  lastUpdatedByName: '',
};

export class Question {
  id: string | number | undefined;

  category: Option | undefined;

  question: string;

  answer: string;

  picture: string;

  isDraft: boolean;

  relatedQuestions: Option[];

  created: string;

  createdByName: string;

  updated: string;

  lastUpdatedByName: string;

  constructor(data: IServerData = DefaultValues) {
    this.id = data.id;
    this.category = data.category;
    this.question = data.question;
    this.answer = data.answer;
    this.picture = data.picture;
    this.isDraft = data.isDraft;
    this.relatedQuestions = data.relatedQuestions;
    this.created = data.created;
    this.createdByName = data.createdByName;
    this.updated = data.updated;
    this.lastUpdatedByName = data.lastUpdatedByName;
  }
}

export const createQuestionArray = (data: IServerData[]) => data.map((item) => new Question(item));
