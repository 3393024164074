import React from 'react';
import { Grid, Modal } from '@material-ui/core';

import { ReactComponent as Cross } from 'src/assets/images/Cross.svg';
import { ConfirmationModalProps } from './interface';
import SecondaryButton from '../SecondaryBtn';
import LoadingButton from '../LoadingButton';
import style from './index.module.scss';

const ConfirmationModal = ({
  title,
  isOpen,
  onClose,
  leftButtonLabel = null,
  rightButtonLabel,
  onCloseAllModals,
  children,
  disabled = false,
}: ConfirmationModalProps) => {
  const handelCloseModal = () => {
    if (!disabled) {
      onClose();
    }
  };

  return (
    <Modal open={isOpen} className={style.modal}>
      <Grid className={style.modal_body}>
        <Grid className={style.modal_body_content}>
          <Cross className={style.cross} onClick={handelCloseModal} />
          <h2 className={style.modal_title}>{title}</h2>
          <div className={style.modal_text}>{children}</div>
          <Grid container direction="row" justifyContent="center" alignItems="flex-end">
            {leftButtonLabel && (
              <Grid item className={style.btn_gap} xs={2}>
                <SecondaryButton disabled={disabled} placeholder={leftButtonLabel} onClick={onClose} />
              </Grid>
            )}

            <Grid item className={style.btn_gap}>
              <LoadingButton loading={disabled} onClick={onCloseAllModals} text={rightButtonLabel} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ConfirmationModal;
