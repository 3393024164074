import { useState } from 'react';

type Validator = (value: string) => string;

export const useValidator = (validators: Validator[] = [], defaultValue: string = '') => {
  const [touched, setTouched] = useState(false);
  const [value, updateValue] = useState(defaultValue || '');
  const [error, setError] = useState('');
  const validate = () => {
    setTouched(true);
    const errorState = validators?.find((validator) => validator(value))?.(value) || '';
    setError(errorState);

    return Boolean(errorState);
  };

  return {
    error: touched && Boolean(error),
    errorText: error,
    setError,
    validate,
    updateValue,
    value,
  };
};
