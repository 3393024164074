import { actionTypes } from './types';

export interface LoginUser {
  email: string;
  password: string;
}

export const loginUser = (user: LoginUser) => ({
  type: actionTypes.LOGIN_USER,
  payload: user,
});
export const logoutUser = () => ({
  type: actionTypes.LOGOUT_USER,
});
