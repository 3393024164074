import ls from 'localstorage-slim';
import { generateSecretKey } from '../utils/generateSecretKey';

export const configureLS = () => {
    ls.config.encrypt = true;
    ls.config.secret = generateSecretKey();
}

export const getLs = (key: string): string | null => ls.get(key);

export const setLs = (key: string, value: string) => ls.set(key, value);