import { Question } from '../../Models/Question';
import { isJsonString } from '../../../../../utils/JSON/json-utils';
import { FieldNames, MaxLength } from '../constants';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const checkValidationForm = ({ question, answer, category }: Question) => {
  const emptyFields: string[] = [];

  if (!question) emptyFields.push(FieldNames.question);
  if (question && question.length > MaxLength.question) emptyFields.push(FieldNames.question);

  if (isJsonString(answer)) {
    const textEdit = JSON.parse(answer);
    const answerText = textEdit?.blocks?.some(({ text }: { text: string }) => text?.trim());
    if (!answerText) {
      emptyFields.push(FieldNames.answer);
    }
    if (answerText && answerText.length > MaxLength.answer) {
      emptyFields.push(FieldNames.answer);
    }
  } else {
    emptyFields.push(FieldNames.answer);
  }

  if (!category) emptyFields.push(FieldNames.category);

  return emptyFields;
};
