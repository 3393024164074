import React from 'react';
import { Grid } from '@material-ui/core';
import { LoadingButton as Button } from '@mui/lab';

import { ILoadingButtonProps } from './interface';
import style from './index.module.scss';

const LoadingButton = ({ text, loading, variant, onClick, buttonClassName, wrapperClassName }: ILoadingButtonProps) => (
  <Grid className={wrapperClassName}>
    <Button
      loading={loading}
      className={buttonClassName}
      onClick={onClick}
      variant={variant}
      endIcon={<></>}
      loadingPosition="end"
      fullWidth
    >
      {text}
    </Button>
  </Grid>
);

LoadingButton.defaultProps = {
  buttonClassName: style.button,
  wrapperClassName: '',
  variant: 'contained',
};

export default LoadingButton;
