import { makeStyles } from '@material-ui/core';

export const useSelectComponentStyles = makeStyles(() => ({
  root: {
    '& .MuiFormHelperText-root': {
      color: 'red!important',
    },
  },
  inputRoot: {
    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red!important',
    },
  },
}));
