import { put, call, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  getQuestionList,
  addNewQuestion,
  updateQuestion,
  deleteQuestion,
  getFilteredQuestionList,
} from '../api/questions';
import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { showNotification } from '../actions/notifications';

export function* getQuestionListSaga() {
  try {
    const result: AxiosResponse = yield call(getQuestionList);

    yield put({
      type: getSuccessAction(actionTypes.GET_QUESTIONS_LIST),
      payload: result.data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.GET_QUESTIONS_LIST),
      error,
    });
  }
}

export function* addNewQuestionSaga(action: IAction) {
  try {
    const result: AxiosResponse = yield call(addNewQuestion, action.payload);

    yield put({
      type: getSuccessAction(actionTypes.ADD_NEW_QUESTION),
      payload: result.data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.ADD_NEW_QUESTION),
      error,
    });
  }
}

export function* updateQuestionSaga(action: IAction) {
  try {
    const result: AxiosResponse = yield call(updateQuestion, action.payload);

    yield put({
      type: getSuccessAction(actionTypes.UPDATE_QUESTION),
      payload: result.data,
    });
    yield put(showNotification(`Питання успішно редаговано`));
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.UPDATE_QUESTION),
      error,
    });
  }
}

export function* getFelteredQuestionListSaga(action: IAction) {
  try {
    const result: AxiosResponse = yield call(getFilteredQuestionList, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.GET_FILTER_QUESTIONS_LIST),
      payload: result.data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.GET_FILTER_QUESTIONS_LIST),
      error,
    });
  }
}

export function* deleteQuestionSaga(action: IAction) {
  try {
    yield call(deleteQuestion, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.DELETE_QUESTION),
      payload: action.payload,
    });
    yield put(showNotification(`Питання успішно видалено`));
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.DELETE_QUESTION),
      error,
    });
  }
}

export function* watchGetQuestionListSaga() {
  yield takeEvery(actionTypes.GET_QUESTIONS_LIST, getQuestionListSaga);
}

export function* watchAddNewQuestionSaga() {
  yield takeEvery(actionTypes.ADD_NEW_QUESTION, addNewQuestionSaga);
}

export function* watchGetFelteredQuestionListSaga() {
  yield takeEvery(actionTypes.GET_FILTER_QUESTIONS_LIST, getFelteredQuestionListSaga);
}

export function* watchUpdateQuestionSaga() {
  yield takeEvery(actionTypes.UPDATE_QUESTION, updateQuestionSaga);
}

export function* watchDeleteQuestionSaga() {
  yield takeEvery(actionTypes.DELETE_QUESTION, deleteQuestionSaga);
}
