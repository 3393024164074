import { actionTypes } from './types';

export interface IWorkSchedule {
  workDay: string;
  workTimeFrom: string;
  workTimeTo: string;
}

export interface ICenter {
  regionIds: number[];
  cityIds: number[];
}

export interface IUseDonationCenter {
  name: string;
  region: string;
  city: string;
  address: string;
  phones?: Array<string>;
  dutySchedule?: Array<string>;
  workSchedules?: Array<IWorkSchedule>;
  id?: number;
  comment?: string;
  latitude?: number;
  longitude?: number;
  linkOnTelegram?: string;
  createdDate?: string;
  updatedDate?: string;
  lastUpdatedByName?: string;
  createdByName?: string;
}

export interface IAddDonationCenter {
  name: string;
  region: string;
  city: string;
  address: string;
  phones: Array<string>;
  dutySchedule: Array<string>;
  workSchedules: Array<IWorkSchedule>;
  id?: number;
  comment?: string;
  latitude?: number;
  longitude?: number;
  linkOnTelegram?: string;
  createdDate?: string;
  updatedDate?: string;
  lastUpdatedByName?: string;
  createdByName?: string;
}

export const getDonationCenters = () => ({
  type: actionTypes.GET_DONATION_CENTERS,
});

export const getDonationCentersInCities = (centers: ICenter) => ({
  type: actionTypes.GET_DONATION_CENTERS_IN_CITIES,
  payload: centers,
});

export const getFilteredDonationCenters = (data: []) => ({
  type: actionTypes.GET_FILTERED_DONATION_CENTERS,
  payload: data,
});

export const addDonationCenter = (center: IUseDonationCenter) => ({
  type: actionTypes.ADD_DONATION_CENTER,
  payload: center,
});

export const getInfoDonationCenter = (value: IAddDonationCenter) => ({
  type: actionTypes.GET_INFO_DONATION_CENTER,
  payload: value,
});

export const clearInfoDonationCenter = () => ({
  type: actionTypes.CLEAR_INFO_DONATION_CENTER,
});

export const editDonationCenter = (center: IUseDonationCenter) => ({
  type: actionTypes.EDIT_DONATION_CENTER,
  payload: center,
});

export const clearDonationCenter = (donationCenterId: number) => ({
  type: actionTypes.CLEAR_DONATION_CENTER,
  payload: donationCenterId,
});
