import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';

export interface ICreatePasswordState {
  isTokenValid: boolean | null;
  passwordCreated: boolean | null;
  isLoading: boolean;
  error: any;
}

export const initialState: ICreatePasswordState = {
  isTokenValid: null,
  passwordCreated: null,
  isLoading: false,
  error: null,
};

export const createPasswordReducer = (state: ICreatePasswordState = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.CREATE_PASSWORD:
      return {
        ...state,
        isLoading: true,
      };
    case getSuccessAction(actionTypes.CREATE_PASSWORD): {
      return {
        ...state,
        passwordCreated: true,
        isLoading: false,
      };
    }
    case getFailAction(actionTypes.CREATE_PASSWORD): {
      return {
        ...state,
        passwordCreated: false,
        isLoading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
};
