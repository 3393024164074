import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import Spiner from '../components/Spinner';
import TabsFAQ from '../pages/FAQ';
import OtherInfo from '../pages/OtherInfo';
import TabsUsers from '../pages/Users/TabsUsers';
import Reminder from '../pages/Reminder';

const AuthorizedPageWrapper = lazy(() => import('../components/AuthorizedPageWrapper'));
const About = lazy(() => import('../pages/About'));
const LogIn = lazy(() => import('../pages/LogIn'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const CreatePassword = lazy(() => import('../pages/CreatePassword'));
const DonationTypes = lazy(() => import('../pages/DonationTypes'));
const Donations = lazy(() => import('../pages/Donations'));
const DonationCenters = lazy(() => import('../pages/DonationCenters'));
const Awards = lazy(() => import('../pages/Awards'));
const Dashboard = lazy(() => import('../pages/Dashboard'));

export const Routes = () => (
  <Suspense fallback={<Spiner />}>
    <Switch>
      <PrivateRoute path="/about">
        <About />
      </PrivateRoute>
      <Route path="/login">
        <LogIn />
      </Route>
      <Route path="/reset-password">
        <ResetPassword />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/create-password">
        <CreatePassword />
      </Route>
      <PrivateRoute path="/dashboard">
        <AuthorizedPageWrapper>
          <Dashboard />
        </AuthorizedPageWrapper>
      </PrivateRoute>
      <PrivateRoute path="/users">
        <AuthorizedPageWrapper>
          <TabsUsers />
        </AuthorizedPageWrapper>
      </PrivateRoute>
      <PrivateRoute path="/awards">
        <AuthorizedPageWrapper>
          <Awards />
        </AuthorizedPageWrapper>
      </PrivateRoute>
      <PrivateRoute path="/donation-types">
        <AuthorizedPageWrapper>
          <DonationTypes />
        </AuthorizedPageWrapper>
      </PrivateRoute>
      <PrivateRoute path="/donations">
        <AuthorizedPageWrapper>
          <Donations />
        </AuthorizedPageWrapper>
      </PrivateRoute>
      <PrivateRoute path="/FAQ">
        <AuthorizedPageWrapper>
          <TabsFAQ />
        </AuthorizedPageWrapper>
      </PrivateRoute>
      <PrivateRoute path="/donation-centers">
        <AuthorizedPageWrapper>
          <DonationCenters />
        </AuthorizedPageWrapper>
      </PrivateRoute>
      <PrivateRoute path="/other">
        <AuthorizedPageWrapper>
          <OtherInfo />
        </AuthorizedPageWrapper>
      </PrivateRoute>
      <PrivateRoute path="/notifications">
        <AuthorizedPageWrapper>
          <Reminder />
        </AuthorizedPageWrapper>
      </PrivateRoute>
      <PrivateRoute path="">
        <AuthorizedPageWrapper>
          <Dashboard />
        </AuthorizedPageWrapper>
      </PrivateRoute>
    </Switch>
  </Suspense>
);
