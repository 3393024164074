import React from 'react';
import { useCategoryModal } from '../CategoryModal/useCategoryModal';
import AddBtn from '../../../../components/AddBtn';
import { CategoryModal } from '../CategoryModal';

export const AddCategory = () => {
  const { openedModal, openModal } = useCategoryModal();
  return (
    <>
      <AddBtn placeholder="Додати категорію" onClick={openModal} />
      {openedModal && <CategoryModal />}
    </>
  );
};
