import { actionTypes } from './types';
import { NotificationType } from '../constants/notifications';

export const showNotification = (message: string, variant: NotificationType = NotificationType.Error) => ({
  type: actionTypes.SHOW_NOTIFICATION,
  payload: {
    message,
    variant,
  },
});

export const hideNotification = (id: string) => ({
  type: actionTypes.HIDE_NOTIFICATION,
  payload: {
    id,
  },
});
