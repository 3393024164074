import { all } from 'redux-saga/effects';
import { watchGetAdmins, watchAddAdmin, watchBlockAdmin, watchUnblockAdmin, watchResetAdmin } from './admins';
import { watchGetDonors } from './donors';
import { watchLoginUser } from './authorization';
import { watchPasswordResetEmail } from './passwordResetEmail';
import { watchDonationTypes } from './donationTypes';
import { watchResetPassword, watchSecurityTokenValidation } from './resetPassword';
import { watchGetPrivacyPolicy } from './privacyPolicy';
import { watchCreatePassword } from './createPassword';
import { watchGetDonations, watchEditDonationStatus } from './donations';
import {
  watchGetDonationCenters,
  watchAddDonationCenters,
  watchEditDonationCenters,
  watchClearDonationCenter,
} from './donationCenters';
import { watchGetRegions } from './regions';
import { watchGetCitiesInRegions } from './cities';
import {
  watchAddCategoryFAQ,
  watchGetCategoriesFAQ,
  watchEditCategoriesFAQ,
  watchRemoveCategoriesFAQ,
  watchGetCategoriesByNameFAQ,
} from './category';
import {
  watchGetQuestionListSaga,
  watchAddNewQuestionSaga,
  watchGetFelteredQuestionListSaga,
  watchUpdateQuestionSaga,
  watchDeleteQuestionSaga,
} from './questions';
import { watchAddAwardSaga } from './awards';
import {
  watchGetPushNotifications,
  watchAddPushNotifications,
  watchDeletePushNotification,
  watchEditPushNotifications,
  watchGetPushNotificationCategories,
  watchGetPushNotificationTriggers,
  watchGetPushNotificationCreatedBySaga,
  watchGetPushNotificationUpdatedBySaga,
  watchCancelPushNotification,
} from './pushNotifications';

export function* rootSaga() {
  yield all([
    watchGetAdmins(),
    watchAddAdmin(),
    watchBlockAdmin(),
    watchUnblockAdmin(),
    watchResetAdmin(),
    watchCreatePassword(),
    watchGetDonors(),
    watchLoginUser(),
    watchPasswordResetEmail(),
    watchSecurityTokenValidation(),
    watchResetPassword(),
    watchGetDonations(),
    watchEditDonationStatus(),
    watchDonationTypes(),
    watchGetPrivacyPolicy(),
    watchGetDonationCenters(),
    watchGetRegions(),
    watchGetCitiesInRegions(),
    watchAddDonationCenters(),
    watchEditDonationCenters(),
    watchClearDonationCenter(),
    watchAddCategoryFAQ(),
    watchGetQuestionListSaga(),
    watchAddNewQuestionSaga(),
    watchUpdateQuestionSaga(),
    watchDeleteQuestionSaga(),
    watchAddAwardSaga(),
    watchGetCategoriesFAQ(),
    watchGetCategoriesByNameFAQ(),
    watchEditCategoriesFAQ(),
    watchRemoveCategoriesFAQ(),
    watchGetFelteredQuestionListSaga(),
    watchGetPushNotifications(),
    watchAddPushNotifications(),
    watchDeletePushNotification(),
    watchEditPushNotifications(),
    watchGetPushNotificationCategories(),
    watchGetPushNotificationTriggers(),
    watchGetPushNotificationCreatedBySaga(),
    watchGetPushNotificationUpdatedBySaga(),
    watchCancelPushNotification(),
  ]);
}
