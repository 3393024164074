import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { IDropdownProps } from './interface';
import style from './index.module.scss';

const Dropdown = ({ label, data, value, onChange }: IDropdownProps) => (
  <FormControl className={style.form}>
    {label && (
      <InputLabel id="selection" className={style.input_label}>
        {label}
      </InputLabel>
    )}
    <Select
      fullWidth
      labelId="selection"
      value={value}
      onChange={onChange}
      IconComponent={ExpandMore}
      className={style.select}
      disableUnderline
      displayEmpty
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        elevation: 2,
        transitionDuration: 'auto',
      }}
    >
      {data.map((item) => (
        <MenuItem value={item.value || item.name} key={item.value || item.id}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default Dropdown;
