import React from 'react';
import { Button } from '@material-ui/core';
import style from './index.module.scss';
import { AddBtnProps } from './interface';
import { ReactComponent as Plus } from '../../assets/images/Plus.svg';

const AddBtn = ({ placeholder, onClick }: AddBtnProps) => (
  <Button className={style.add_btn} onClick={onClick} variant="contained" color="primary">
    <Plus className={style.plus} />
    {placeholder}
  </Button>
);

export default AddBtn;
