import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import LinkTab from '../../components/LinkTab';
import Question from './Question/question';
import Category from './Category/category';
import { Label } from './Question/Config';

import style from './index.module.scss';

const TabsFAQ = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <div className={style.tabs}>
        <LinkTab placeholder={Label.Question} path={`${path}/question`} />
        <LinkTab placeholder={Label.Category} path={`${path}/category`} />
      </div>

      <div className={style.table}>
        <Switch>
          <Redirect exact from={`${path}`} to={`${path}/question`} />
          <Route exact path={`${path}/question`} component={Question} />
          <Route exact path={`${path}/category`} component={Category} />
        </Switch>
      </div>
    </>
  );
};

export default TabsFAQ;
