import React, { useState, useEffect, ChangeEvent } from 'react';
import AutocompleteMenu from 'src/components/Autocomplete';
import { DataProps } from 'src/components/Autocomplete/interface';
import Search from '../../../../../components/Search';
import { roleOptions, statusOptions } from './optionsData';
import style from '../index.module.scss';
import { useActions } from '../../../../../hooks/useActions';
import { useDebounce } from '../../../../../hooks/useDebounce';

type TFields = 'searchValue' | 'status' | 'role';
const defaultStatus: string = '';
const defaultRole: string = '';

const AdminsFilters = () => {
  const { getAdmins } = useActions();
  const [filter, setFilter] = useState<Record<TFields, string>>({
    searchValue: '',
    status: '',
    role: defaultRole,
  });
  const searchValue = useDebounce<string>(filter.searchValue);

  useEffect(() => {
    getAdmins({ searchValue, status: +filter.status, role: [filter.role || 'admin'] });
  }, [filter.role, filter.status, searchValue]);

  const handleChangeStatus = (data: DataProps | null) => {
    setFilter({ ...filter, status: (data?.value as string) ?? defaultStatus });
  };

  const handleChangeRole = (data: DataProps | null) => {
    setFilter({ ...filter, role: (data?.value as string) ?? defaultRole });
  };

  const handleChangeSearchValue = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, searchValue: target.value });
  };

  return (
    <div className={style.container}>
      <Search
        label="Пошук"
        value={filter.searchValue}
        onChange={handleChangeSearchValue}
        placeholder="Введіть ім’я або електронну адресу"
      />
      <AutocompleteMenu label="Статус" data={statusOptions} value={filter.status} onChange={handleChangeStatus} />
      <AutocompleteMenu
        label="Роль"
        data={roleOptions}
        value={filter.role}
        onChange={handleChangeRole}
        defaultValue={defaultRole}
      />
    </div>
  );
};

export default AdminsFilters;
