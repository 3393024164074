import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Link } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationModal from '../../../../../components/ConfirmationModal';
import { editSelectedCategory } from '../../../../../selectors/category';
import { useActions } from '../../../../../hooks/useActions';
import { useFlag } from '../../../../../hooks/useFlag';
import { useCategoryModal } from '../useCategoryModal';
import style from './index.module.scss';

export const CategoryRemoveBtn = () => {
  const { selectedCategory } = useSelector(editSelectedCategory);
  const { removeCategoryFAQ, clearCategoryInfo } = useActions();
  const [confirmationModalVisible, openConfirmationModal, closeConfirmationModal] = useFlag();
  const [notificationModalVisible, openNotificationModal, closeNotificationModal] = useFlag();
  const { closeModal } = useCategoryModal();

  const handleRemoveCategory = () => {
    if (selectedCategory) {
      removeCategoryFAQ(selectedCategory?.id);
      clearCategoryInfo();
      closeModal();
    }
  };

  return (
    <>
      <Grid item xs={3} className={style.delete_container}>
        <Link
          component="button"
          onClick={selectedCategory?.isPermanent ? openNotificationModal : openConfirmationModal}
          className={style.delete_link}
        >
          <DeleteIcon />
          <Grid>Видалити Категорію</Grid>
        </Link>
      </Grid>
      <ConfirmationModal
        title="Видалити"
        isOpen={confirmationModalVisible}
        leftButtonLabel="Cкасувати"
        rightButtonLabel="Видалити"
        onClose={closeConfirmationModal}
        onCloseAllModals={handleRemoveCategory}
      >
        <p>Ви дійсно бажаєте видалити цю Категорію?</p>
        <p>Цю дію неможливо відмінити</p>
      </ConfirmationModal>
      <ConfirmationModal
        title="Увага!"
        isOpen={notificationModalVisible}
        rightButtonLabel="Зрозуміло"
        onClose={closeNotificationModal}
        onCloseAllModals={closeNotificationModal}
      >
        <p>
          Категорію неможливо видалити, оскільки користувачі мають посилання на сторінку категорії у мобільному додатку
          після планування донації.
        </p>
      </ConfirmationModal>
    </>
  );
};
