import { call, put, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { resetPassword, validateSecurityToken } from '../api/resetPassword';
import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { showNotification } from '../actions/notifications';
import { NotificationType } from '../constants/notifications';

export function* securityTokenValidationSaga(action: IAction) {
  try {
    const { email, securityToken } = action.payload;
    const response: AxiosResponse = yield call(validateSecurityToken, email, securityToken);

    yield put({
      type: getSuccessAction(actionTypes.VALIDATE_SECURITY_TOKEN),
      payload: response.data,
    });
  } catch (error) {
    yield put({
      type: getFailAction(actionTypes.VALIDATE_SECURITY_TOKEN),
      error,
    });
  }
}

export function* watchSecurityTokenValidation() {
  yield takeEvery(actionTypes.VALIDATE_SECURITY_TOKEN, securityTokenValidationSaga);
}

export function* passwordResetSaga(action: IAction) {
  try {
    const { email, securityToken, password, passwordConfirm } = action.payload;
    const response: AxiosResponse = yield call(resetPassword, email, securityToken, password, passwordConfirm);

    yield put({
      type: getSuccessAction(actionTypes.RESET_PASSWORD),
      payload: response.data,
    });

    yield put(showNotification('Пароль змінено успішно', NotificationType.Success));
  } catch (error) {
    yield put({
      type: getFailAction(actionTypes.RESET_PASSWORD),
      error,
    });
  }
}

export function* watchResetPassword() {
  yield takeEvery(actionTypes.RESET_PASSWORD, passwordResetSaga);
}
