import React, { ChangeEvent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AutocompleteMenu from 'src/components/Autocomplete';
import { DataProps } from 'src/components/Autocomplete/interface';
import Search from '../../../../components/Search';
import { selectQuestions } from '../../../../selectors/questions';
import { useActions } from '../../../../hooks/useActions';
import { useGetUniqe } from './useGetUniqe';
import { statusData, Label } from '../Config';
import { DropdownData } from './interface';
import style from './index.module.scss';

export const FilterQuestion = () => {
  const { questions } = useSelector(selectQuestions);
  const { getFilteredQuestionList, getSearchQuestion } = useActions();
  const { getUniqueCategories, getUniqueAuthors } = useGetUniqe(questions);
  const [category, setCategory] = useState<string>('');
  const [categoryId, setCategoryId] = useState<number>(0);
  const limitSymbols = 2;
  const [status, setStatus] = useState<string>('');
  const [isDraft, setIsDraft] = useState<boolean>();
  const [createdBy, setCreatedBy] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>('');
  const [categoriesData, setCategoriesData] = useState<DropdownData[]>([]);
  const [authorsData, setAuthorsData] = useState<DropdownData[]>([]);

  const defaultId: number = 0;

  const handleCategoryIdChange = (data: DataProps | null) => {
    setCategory(data?.name as string);
    setCategoryId((data?.id as number) ?? defaultId);
  };

  const handleStatusChange = (data: DataProps | null) => {
    setStatus(data?.name ?? '');
    setIsDraft(data?.name === Label.Draft);
  };

  const handleCreatedByChange = (data: DataProps | null) => {
    setCreatedBy(data?.name ?? '');
  };

  const handleSearchChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
    setSearchValue(event.target.value);
    if (event.target.value.length >= limitSymbols) {
      setSearch(event.target.value);
    } else {
      setSearch('');
    }
  };

  useEffect(() => {
    getSearchQuestion(search);
    getFilteredQuestionList({
      categoryId,
      ...(status ? { isDraft } : {}),
      createdBy,
      search,
    });
  }, [categoryId, createdBy, search, status]);

  useEffect(() => {
    if (!categoriesData.length && getUniqueCategories.length) {
      setCategoriesData(getUniqueCategories);
    } else if (!authorsData.length && getUniqueAuthors.length) {
      setAuthorsData(getUniqueAuthors);
    }
  }, [getUniqueCategories, getUniqueAuthors]);

  return (
    <div className={style.container}>
      <Search label={Label.Search} value={searchValue} onChange={handleSearchChange} placeholder={Label.Search} />
      <AutocompleteMenu
        label={Label.Category}
        data={categoriesData}
        value={category}
        onChange={handleCategoryIdChange}
      />
      <AutocompleteMenu label={Label.Status} data={statusData} value={status} onChange={handleStatusChange} />
      <AutocompleteMenu label={Label.Author} data={authorsData} value={createdBy} onChange={handleCreatedByChange} />
    </div>
  );
};
