import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { IDropdownMultipleProps } from './interface';
import style from './index.module.scss';

const DropdownMultiple = ({ label, data, value, onChange }: IDropdownMultipleProps<string | number>) => (
  <FormControl className={style.form}>
    {label && (
      <InputLabel id="selection" className={style.input_label}>
        {label}
      </InputLabel>
    )}
    <Select
      fullWidth
      multiple
      labelId="selection"
      value={value}
      onChange={onChange}
      IconComponent={ExpandMore}
      className={style.select}
      disableUnderline
      displayEmpty
      renderValue={(selected: any) => {
        if (selected.length === 0) {
          return <em className={style.input_plaseholder}>Не вибрано</em>;
        }
        return selected.join(', ');
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        elevation: 2,
        transitionDuration: 'auto',
      }}
    >
      {data.map((item) => (
        <MenuItem value={item.name} key={`${item.value} ${item.id}`}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
export default DropdownMultiple;
