import { Question } from './Question';
import { Option } from './Option';

export class SubmitData {
  id: string | number | undefined;

  category: Option | undefined;

  question: string;

  answer: string;

  picture: string;

  isDraft: boolean;

  relatedQuestions: Option[];

  created: string | undefined;

  createdByName: string | undefined;

  updated: string | undefined;

  lastUpdatedByName: string | undefined;

  constructor(formData?: Question) {
    this.id = formData?.id;
    this.category = formData?.category;
    this.question = formData?.question || '';
    this.answer = formData?.answer || '';
    this.picture = formData?.picture || '';
    this.isDraft = formData?.isDraft || false;
    this.relatedQuestions = formData?.relatedQuestions || [];

    if (formData?.id) {
      this.created = formData.created;
      this.createdByName = formData.createdByName;
      this.updated = formData.updated;
      this.lastUpdatedByName = formData.lastUpdatedByName;
    }
  }
}
