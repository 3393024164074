import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AutocompleteMenu from 'src/components/Autocomplete';
import { DataProps } from 'src/components/Autocomplete/interface';
import Search from '../../../../../components/Search';
import { useDebounce } from '../../../../../hooks/useDebounce';
import { getDonors } from '../../../../../actions/donors';
import { statusOptions } from './optionsData';
import style from '../index.module.scss';

type TFields = 'searchValue' | 'status';

const DonorsFilters = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<Record<TFields, string>>({
    searchValue: '',
    status: '',
  });
  const searchValue = useDebounce<string>(filter.searchValue);

  useEffect(() => {
    dispatch(getDonors({ searchValue, status: +filter.status }));
  }, [searchValue, filter.status]);

  const handleChangeStatus = (data: DataProps | null) => {
    setFilter({ ...filter, status: `${data?.value ?? ''}` });
  };

  const handleChangeSearchValue = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setFilter({ ...filter, searchValue: target.value });
  };

  return (
    <div className={style.container}>
      <Search
        label="Пошук"
        value={filter.searchValue}
        onChange={handleChangeSearchValue}
        placeholder="Введіть ім’я або електронну адресу"
      />
      <AutocompleteMenu label="Статус" data={statusOptions} value={filter.status} onChange={handleChangeStatus} />
    </div>
  );
};

export default DonorsFilters;
