import { createSelector } from 'reselect';
import { IPushNotificationModalState } from 'src/reducers/pushNotificationModal';
import { IRootState } from '../reducers';

const getPushNotificationsModal = (state: IRootState) => state.pushNotificationModal;

export const selectPushNotificationsModal = createSelector(
  getPushNotificationsModal,
  (pushNotificationsModalState: IPushNotificationModalState) => pushNotificationsModalState
);
