import { combineReducers } from 'redux';
import { IAdminsState, adminsReducer } from './admins';
import { addAdminReducer, unblockAdminReducer, IAdminState, blockAdminReducer, resetAdminReducer } from './admin';
import { IDonorsState, donorsReducer } from './donors';
import { LoginState, loginReducer } from './authorization';
import { PasswordResetEmailState, passwordResetEmailReducer } from './passwordResetEmail';
import { INotification, notificationsReducer } from './notifications';
import { donationTypesReducer, IDonationTypesListState } from './donationTypes';
import { resetPasswordReducer, ResetPasswordState } from './resetPassword';
import { donationsReducer, IDonationsState } from './donations';
import { donationCentersReducer, IDonationCentersListState } from './donationCenters';
import { privacyPolicyReducer, IPrivacyPolicyState } from './privacyPolicy';
import { createPasswordReducer, ICreatePasswordState } from './createPassword';
import { regionsReducer, IRegionsState } from './regions';
import { citiesReducer, ICitiesState } from './cities';
import { editDonationCenterReducer, IDonationCenterState } from '../pages/DonationCenters/DonationCenterForm/reduсer';
import { categoryReducer, ICategoryState } from './category';
import { questionsReducer, IQuestions } from './questions';
import { awardsReducer, IAwards } from './awards';
import { awardModalReducer, IAwardModalState } from './awardModal';
import { editCategoryReducer, IEditCategoryState } from '../pages/FAQ/Category/CategoryReducer/editCategoryReducer';
import { IPushNotificationsListState, pushNotificationsReducer } from "./pushNotification";
import { IPushNotificationModalState, pushNotificationModalReducer } from './pushNotificationModal';

export interface IRootState {
  admins: IAdminsState;
  addAdmin: IAdminState;
  blockAdmin: IAdminState;
  unblockAdmin: IAdminState;
  resetAdmin: IAdminState;
  createPassword: ICreatePasswordState;
  donors: IDonorsState;
  login: LoginState;
  notifications: INotification[];
  donationTypes: IDonationTypesListState;
  passwordResetEmail: PasswordResetEmailState;
  resetPassword: ResetPasswordState;
  donations: IDonationsState;
  donationCenters: IDonationCentersListState;
  privacyPolicy: IPrivacyPolicyState;
  regions: IRegionsState;
  cities: ICitiesState;
  donationCenter: IDonationCenterState;
  categories: ICategoryState;
  questions: IQuestions;
  awards: IAwards;
  awardModal: IAwardModalState;
  selectedCategory: IEditCategoryState;
  pushNotifications: IPushNotificationsListState;
  pushNotificationModal: IPushNotificationModalState;
}

export const rootReducer = combineReducers({
  admins: adminsReducer,
  addAdmin: addAdminReducer,
  blockAdmin: blockAdminReducer,
  unblockAdmin: unblockAdminReducer,
  resetAdmin: resetAdminReducer,
  createPassword: createPasswordReducer,
  donors: donorsReducer,
  login: loginReducer,
  notifications: notificationsReducer,
  donationTypes: donationTypesReducer,
  passwordResetEmail: passwordResetEmailReducer,
  resetPassword: resetPasswordReducer,
  donations: donationsReducer,
  donationCenters: donationCentersReducer,
  privacyPolicy: privacyPolicyReducer,
  regions: regionsReducer,
  cities: citiesReducer,
  donationCenter: editDonationCenterReducer,
  categories: categoryReducer,
  questions: questionsReducer,
  awards: awardsReducer,
  awardModal: awardModalReducer,
  selectedCategory: editCategoryReducer,
  pushNotifications: pushNotificationsReducer,
  pushNotificationModal: pushNotificationModalReducer,
});
