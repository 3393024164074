import React from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MainButton from '../MainBtn';
import style from './index.module.scss';

const ErrorIndicator = ({ call }: any) => {
  const history = useHistory();

  const redirectToMainPage = () => {
    call();
    history.push('/login');
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ height: '100%' }}
      className={style.error_indicator_wrap}
    >
      <Grid item xs={5} className={style.text_content}>
        <h2 className={style.title}>Помилка</h2>
        <p>
          Трапилася непередбачувана помилка.
          <br />
          Щоб перейти на головну сторінку натисніть на кнопку нижче.
        </p>
      </Grid>
      <Grid item xs={3} className={style.btn_wrap}>
        <MainButton placeholder="Перейти на головну сторінку" onClick={redirectToMainPage} />
      </Grid>
    </Grid>
  );
};

export default ErrorIndicator;
