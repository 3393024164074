import { put, call, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { getRegions } from '../api/regions';
import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { showNotification } from '../actions/notifications';

export function* getRegionsSaga(action: IAction) {
  try {
    const result: AxiosResponse = yield call(getRegions);
    yield put({
      type: getSuccessAction(actionTypes.GET_REGIONS),
      payload: result.data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.GET_REGIONS),
      error,
    });
  }
}

export function* watchGetRegions() {
  yield takeEvery(actionTypes.GET_REGIONS, getRegionsSaga);
}
