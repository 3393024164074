import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  sizeAvatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
}));

interface IProps {
  iconData: string;
  alt: string;
}

export const Icon = ({ iconData, alt }: IProps) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Avatar src={iconData} className={classes.sizeAvatar} alt={alt} />
    </Grid>
  );
};
