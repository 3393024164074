import React from 'react';
import { useSelector } from 'react-redux';

import Table from '../../../../components/Table';
import Spinner from '../../../../components/Spinner';
import DonorsFilters from '../Filters/DonorsFilters';
import { donorsTableColumns } from './tableConfig';
import { selectDonors } from '../../../../selectors/donors';

const Donors = () => {
  const { isLoading, donors } = useSelector(selectDonors);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <DonorsFilters />
      {isLoading ? <Spinner /> : <Table rows={donors} columns={donorsTableColumns} signature="false" />}
    </div>
  );
};

export default Donors;
