import { IDonation } from 'src/reducers/donationModal';
import { actionTypes } from './types';

export interface IFilters {
  fullName?: string;
  bloodType?: string[];
  dateFrom?: string | null;
  dateTo?: string | null;
  donationTypeIds?: number[];
  regionIds?: number[];
  cityIds?: number[];
  donationCenterIds?: number[];
  status?: number[];
  bloodVolume?: number[];
  page: IPage | undefined;
}
export interface IPage {
  number?: number;
  packageSize: number;
}

export const getDonations = (filters: IFilters) => ({
  type: actionTypes.GET_DONATIONS,
  payload: filters,
});

export const editDonationStatus = (donation: IDonation) => ({
  type: actionTypes.EDIT_DONATION_STATUS,
  payload: donation,
});
