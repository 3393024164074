import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Search from '../../../components/Search';
import DropdownMultiple from '../../../components/DropdownMultiple';
import DateDropdown from '../../../components/DateDropdown';
import style from './index.module.scss';
import { useActions } from '../../../hooks';
import { useCheckDate } from '../../../hooks/useCheckDate';
import { selectPushNotifications } from '../../../selectors/pushNotifications';
import { IPushNotification, IPushNotificationCategory } from '../constants';

const FilterScheduled = ({ on, off }: { on: () => void; off: () => void }) => {
  const { pushNotifications, pushNotificationCategories } = useSelector(selectPushNotifications);
  const [category, setCategory] = useState([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const limitSymbols = 2;
  const { getFilteredPushNotificationList } = useActions();
  const [dateRange, setDateRange] = useState<Date | [Date | null, Date | null]>([null, null]);
  const { startDate, endDate } = useCheckDate(dateRange);
  const handleCategoryChange = (event: ChangeEvent<any>) => {
    setCategory(event.target.value);
  };

  const handleSearchValueChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
    setSearchValue(event.target.value);
  };

  const handleChangeDate = (update: [Date | null, Date | null]) => {
    setDateRange(update);
  };

  const getFilteredPushNotificationsByDate = () =>
    pushNotifications.filter((notification: IPushNotification) => {
      let isDateValid = true;
      if (startDate && endDate) {
        const currentDate = new Date(notification.notificationDistribution);
        isDateValid = startDate.getTime() <= currentDate.getTime() && endDate.getTime() >= currentDate.getTime();
      }
      return isDateValid;
    });

  const getFilteredPushNotificationsBySearch = () =>
    pushNotifications.filter((notification: IPushNotification) =>
      notification.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    );

  const getFilteredPushNotificationsByCategory = () => {
    const categoriesIds: any = [];
    category?.forEach((item) => {
      pushNotificationCategories.forEach((cat: IPushNotificationCategory) => {
        if (item === cat.name) {
          categoriesIds.push(cat.id);
        }
      });
    });
    return pushNotifications.filter((note: IPushNotification) =>
      categoriesIds.some((id: number) => note.categoryId === id)
    );
  };

  useEffect(() => {
    if (!_.isEqual(dateRange, [null, null])) {
      on();
      getFilteredPushNotificationList(getFilteredPushNotificationsByDate());
    } else {
      off();
    }
  }, [dateRange]);

  useEffect(() => {
    if (searchValue.length > limitSymbols) {
      on();
      getFilteredPushNotificationList(getFilteredPushNotificationsBySearch());
    } else {
      off();
      getFilteredPushNotificationList([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (category.length) {
      on();
      getFilteredPushNotificationList(getFilteredPushNotificationsByCategory());
    } else {
      off();
      getFilteredPushNotificationList([]);
    }
  }, [category]);

  return (
    <div className={style.container}>
      <Search placeholder="Введіть назву" value={searchValue} onChange={handleSearchValueChange} />
      <DropdownMultiple
        label="Категорія"
        data={pushNotificationCategories}
        value={category}
        onChange={handleCategoryChange}
        isDirty={false}
      />
      <DateDropdown
        label="Заплановано на:"
        startDate={startDate}
        endDate={endDate}
        onDateChange={handleChangeDate}
        isDirty={false}
        isRange
      />
    </div>
  );
};

export default FilterScheduled;
