import { actionTypes } from './types';

export interface IAdminsFilterData {
  searchValue: string;
  status: string | number;
  role: string[];
}

export interface IAddAdmin {
  name: string;
  lastName: string;
  email: string;
  role: string;
}

export const getAdmins = (filters: IAdminsFilterData) => ({
  type: actionTypes.GET_ADMINS,
  payload: filters,
});

export const addAdmin = (admin: IAddAdmin) => ({
  type: actionTypes.ADD_ADMIN,
  payload: admin,
});

export const blockAdmin = (userEmail: string) => ({
  type: actionTypes.BLOCK_ADMIN,
  payload: userEmail,
});

export const unblockAdmin = (userEmail: string) => ({
  type: actionTypes.UNBLOCK_ADMIN,
  payload: userEmail,
});

export const resetAdmin = (userEmail: string) => ({
  type: actionTypes.RESET_ADMIN,
  payload: userEmail,
});
