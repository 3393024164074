import { IAction, actionTypes, getFailAction, getSuccessAction } from '../actions/types';

export interface IAward {
  id?: number;
  name: string;
  description: string;
  picture: string;
  createDate?: string;
  createdBy?: string;
  updateDate?: string;
  lastUpdatedBy?: string;
}

export interface IAwardModalState {
  isLoading: boolean;
  award: IAward | null;
  error: string | null;
}

export const initialState: IAwardModalState = {
  isLoading: false,
  award: null,
  error: null,
};

export const awardModalReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.ADD_AWARD:
    case actionTypes.EDIT_AWARD:
    case actionTypes.CLEAR_AWARD:
      return {
        ...state,
        isLoading: true,
        error: null,
        award: [],
      };
    case getSuccessAction(actionTypes.ADD_AWARD):
    case getSuccessAction(actionTypes.EDIT_AWARD):
    case getSuccessAction(actionTypes.CLEAR_AWARD):
      return {
        ...state,
        award: action.payload,
        isLoading: false,
      };
    case getFailAction(actionTypes.ADD_AWARD):
    case getFailAction(actionTypes.EDIT_AWARD):
    case getFailAction(actionTypes.CLEAR_AWARD):
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};