import { call, put, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { getCitiesInRegions } from '../api/cities';
import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { showNotification } from '../actions/notifications';

export function* getCitiesInRegionsSaga(action: IAction) {
  try {
    const result: AxiosResponse = yield call(getCitiesInRegions, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.GET_CITIES_IN_REGIONS),
      payload: result.data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.GET_CITIES_IN_REGIONS),
      error,
    });
  }
}

export function* watchGetCitiesInRegions() {
  yield takeEvery(actionTypes.GET_CITIES_IN_REGIONS, getCitiesInRegionsSaga);
}
