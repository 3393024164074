import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';

export interface IPrivacyPolicyState {
  isLoading: boolean;
  policy: [];
  error: any;
}

const initialState: IPrivacyPolicyState = {
  isLoading: false,
  policy: [],
  error: null,
};

export const privacyPolicyReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.GET_PRIVACY_POLICY:
      return {
        ...state,
        isLoading: true,
        error: null,
        policy: [],
      };
    case getSuccessAction(actionTypes.GET_PRIVACY_POLICY):
      return {
        ...state,
        policy: action.payload.text,
        isLoading: false,
      };
    case getFailAction(actionTypes.GET_PRIVACY_POLICY):
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
      case actionTypes.CLEAR_PRIVACY_POLICY:
      return {
        ...state,
        isLoading: false,
        error: null,
        policy: [],
      };
    default:
      return state;
  }
};
