import React from 'react';
import { FormControl, InputLabel, Input } from '@material-ui/core';
import { ISearchProps } from './interface';
import style from './index.module.scss';

const Search = ({ label, value, onChange, placeholder }: ISearchProps) => (
  <FormControl className={style.form}>
    {label && (
      <InputLabel shrink className={style.input_label}>
        {label}
      </InputLabel>
    )}
    <Input
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disableUnderline
      className={style.input}
      type="search"
    />
  </FormControl>
);
export default Search;
