import { actionTypes } from './types';

export const sendResetEmail = (email: string | null) => ({
  type: actionTypes.SEND_RESET_EMAIL,
  payload: email,
});

export const clearPasswordResetEmail = () => ({
  type: actionTypes.CLEAR_PASSWORD_RESET_EMAIL,
});
