import { utcToZonedTime, format } from 'date-fns-tz';
import { KEY_COEFICIENT, TIME_ZONE } from '../constants/date';

const sumOfDateNumbers = (date: string) =>
  date
    .split('/')
    .map((el) => Number(el))
    .reduce((previousState: number, currentState: number) => previousState + currentState);

export const generateSecretKey = () =>
  Math.abs(Number(sumOfDateNumbers(format(utcToZonedTime(new Date(), TIME_ZONE), 'MM/dd/yyyy'))) - KEY_COEFICIENT);
