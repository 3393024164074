import { call, put, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { showNotification } from '../actions/notifications';
import { NotificationType } from '../constants/notifications';
import { createPassword } from '../api/createPassword';

export function* createPasswordSaga(action: IAction) {
  try {
    const { inviteToken, password, passwordConfirm } = action.payload;
    const response: AxiosResponse = yield call(createPassword, inviteToken, password, passwordConfirm);

    yield put({
      type: getSuccessAction(actionTypes.CREATE_PASSWORD),
      payload: response.data,
    });

    yield put(showNotification('Пароль створено успішно', NotificationType.Success));
  } catch (error: unknown) {
    yield put({
      type: getFailAction(actionTypes.CREATE_PASSWORD),
      error,
    });
  }
}

export function* watchCreatePassword() {
  yield takeEvery(actionTypes.CREATE_PASSWORD, createPasswordSaga);
}
