import React from 'react';
import Popover from '@mui/material/Popover';
import { PopoverProps } from './interface';

const Popup = ({
    openPopover,
    onClosePopover,
    anchorElement,
    idPopover,
    widthPopover,
    heightPopover,
    image,
}: PopoverProps) => (
  <Popover
    id={idPopover}
    sx={{
      pointerEvents: 'none',
      maxWidth: widthPopover,
      maxHeight: heightPopover,
    }}
    open={openPopover}
    anchorEl={anchorElement}
    anchorOrigin={{
      vertical: 'center',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'center',
      horizontal: 'left',
    }}
    onClose={onClosePopover}
    disableRestoreFocus
  >
    <img
      src={image}
      width='100%'
      height='100%'
      alt='full size'
    />
  </Popover>
);

export default Popup;
