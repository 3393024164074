import { authorizedInstance } from './axiosInstances';
import { IPushNotification } from '../pages/Reminder/constants';

enum APIUrl {
  PushNotifications = '/PushNotifications',
  PushNotification = '/PushNotification',
  PushNotificationCategories = '/PushNotificationCategories',
  PushNotificationTriggers = '/PushNotificationTriggers',
}

export const getPushNotifications = () => authorizedInstance.get(APIUrl.PushNotifications);

export const addPushNotification = (payload: IPushNotification) =>
  authorizedInstance.post(APIUrl.PushNotification, payload);

export const editPushNotification = (payload: IPushNotification) =>
  authorizedInstance.put(APIUrl.PushNotification, payload);

export const cancelPushNotification = (payload: IPushNotification) =>
  authorizedInstance.put(`${APIUrl.PushNotification}/isDisabled`, payload);

export const deletePushNotification = (pushNotificationId: number) =>
  authorizedInstance.delete(APIUrl.PushNotification, { params: { id: pushNotificationId } });

export const getPushNotificationCategories = () => authorizedInstance.get(APIUrl.PushNotificationCategories);

export const getPushNotificationTriggers = () => authorizedInstance.get(APIUrl.PushNotificationTriggers);
