import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Grid, IconButton } from '@material-ui/core';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';

import { getImageValidationMsg } from '../../../../../utils/formFields/fieldValitationHelper';
import { ErrorMessages } from '../../../../../utils/formFields/formFields.constants';
import { FieldNames } from '../constants';
import style from '../index.module.scss';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  startValidate: boolean;
  hintMessage?: string;
}

const ImageComponent = ({ value = '', onChange, startValidate = false, hintMessage = '' }: IProps) => {
  const imageInput = useRef<HTMLInputElement>(null);
  const [validateMessage, setValidateMessage] = useState<string>('');

  useEffect(() => {
    if (startValidate && !value && !validateMessage) {
      setValidateMessage(ErrorMessages.RequiredField);
    }
  }, [startValidate]);

  const handleSelectImage = (event: any) => {
    const image = event.target?.files[0];
    const validationMsg = getImageValidationMsg(image);

    if (!validationMsg) {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result !== null) {
          onChange(String(reader.result));
        }
      };
      reader.readAsDataURL(image);
    } else {
      onChange('');
    }

    setValidateMessage(validationMsg);
  };

  const onClickButton = () => imageInput.current?.click();

  return (
    <Grid item xs={12}>
      <Grid container alignItems="center">
        <Grid item>
          <Avatar src={value} variant="square" />
        </Grid>
        <Grid item>
          <IconButton color="primary" aria-label="upload picture" component="span" onClick={onClickButton}>
            <DataSaverOnIcon />
            <input
              id={FieldNames.picture}
              style={{ display: 'none' }}
              type="file"
              accept="image/png, image/jpeg, image/gif"
              onChange={handleSelectImage}
              ref={imageInput}
            />
          </IconButton>
        </Grid>
        <Grid item xs={9}>
          {validateMessage && (
            <div className={style.invalid_text} style={{ marginLeft: '14px' }}>
              {validateMessage}
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          {hintMessage && (
            <div className={style.hint}>
              {hintMessage}
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
ImageComponent.defaultProps = {
  hintMessage: '',
};

export { ImageComponent };
