import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Modal } from '@material-ui/core';
import { GridCellParams } from '@mui/x-data-grid';
import { QuestionModalContextProvider, useQuestionModal } from './QuestionModal/useQuestionModal';
import { QuestionModal } from './QuestionModal';
import { FilterQuestion } from './FilterQuestion';
import AddBtn from '../../../components/AddBtn';
import Table from '../../../components/Table';
import { questionTableColumns, Label } from './Config';
import { useActions } from '../../../hooks/useActions';
import { selectQuestions } from '../../../selectors/questions';
import Spinner from '../../../components/Spinner';

import modalStyles from './QuestionModal/index.module.scss';

import style from './index.module.scss';
import { Question as QuestionModel } from './Models/Question';

const QuestionComponent = () => {
  const { getQuestionList } = useActions();
  const { openedModal, closeModal, openModal } = useQuestionModal();
  const { container, modal } = modalStyles;
  const { isLoading, questions } = useSelector(selectQuestions);
  const [selectedQuestion, setSelectedQuestion] = useState<QuestionModel>(new QuestionModel());

  useEffect(() => {
    getQuestionList();
  }, []);

  const onCloseModal = () => {
    setSelectedQuestion(new QuestionModel());
    closeModal();
  };

  const onCellClick = ({ row }: GridCellParams) => {
    setSelectedQuestion(row as QuestionModel);
    openModal();
  };

  return (
    <>
      <div className={style.btn}>
        <AddBtn placeholder={Label.AddQuestion} onClick={openModal} />
      </div>

      <FilterQuestion />

      {isLoading ? (
        <Spinner />
      ) : (
        <Table
          rows={questions}
          columns={questionTableColumns}
          loading={isLoading}
          signature="false"
          onCellClick={onCellClick}
          isRowSelectable={() => false}
        />
      )}

      <Modal open={openedModal} onClose={onCloseModal} className={modal}>
        <Box className={container}>
          <QuestionModal defaultValues={selectedQuestion} onCloseModal={onCloseModal} />
        </Box>
      </Modal>
    </>
  );
};

function Question() {
  return (
    <QuestionModalContextProvider>
      <QuestionComponent />
    </QuestionModalContextProvider>
  );
}

export default Question;
