import React, { useEffect, forwardRef, useImperativeHandle } from 'react';

import { TextField } from '@material-ui/core';
import { EditFieldWrapper } from './EditFieldWrapper';
import { useValidator } from '../../hooks/useValidator';
import { EditFieldProps, OnChangeCb } from '../../interfaces';
import { getMinMaxCyrillicLatinField } from '../../utils/formFields/fieldValitationHelper';

export const TextEditField = forwardRef(
  (
    {
      className,
      required,
      defaultValue,
      fieldId,
      fieldLabel,
      onChange,
      validators,
      disabled,
      startValidate,
      rows,
      multiline,
      maxLength,
      minLength,
      placeholder,
      pattern = null,
    }: EditFieldProps,
    ref: any
  ) => {
    const { error, errorText, setError, updateValue, validate, value } = useValidator(validators, defaultValue);

    useEffect(() => {
      checkValidation();
    }, [startValidate, value]);

    useImperativeHandle(ref, () => ({
      clear: () => {
        updateValue('');
      },
    }));

    const onTyped: OnChangeCb = (event) => {
      const rawValue = event.target.value;
      const resValue = pattern ? rawValue.replace(pattern, '') : rawValue;
      updateValue(resValue);
      const newEventObj = pattern ? { ...event } : event;
      if (pattern) newEventObj.target.value = resValue;
      onChange(newEventObj);
    };

    const checkValidation = () => {
      if (!(startValidate && validate())) {
        setError(getMinMaxCyrillicLatinField(minLength || null, maxLength || Infinity, value));
      }
    };

    return (
      <EditFieldWrapper required={required} fieldLabel={fieldLabel}>
        <TextField
          className={className}
          multiline={multiline}
          minRows={rows}
          disabled={disabled}
          style={{ width: '100%' }}
          error={error}
          helperText={errorText}
          id={fieldId}
          defaultValue={defaultValue}
          value={value}
          onChange={onTyped}
          variant="outlined"
          onBlur={checkValidation}
          placeholder={placeholder}
        />
      </EditFieldWrapper>
    );
  }
);

TextEditField.defaultProps = {
  className: '',
  rows: 0,
  maxLength: undefined,
  multiline: false,
  required: false,
  defaultValue: '',
  validators: [],
  startValidate: false,
};
