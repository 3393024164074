import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, EditorState, convertFromRaw } from 'draft-js';

import { ErrorMessages } from 'src/utils/formFields/formFields.constants';
import { TextEditorWrapper } from './TextEditorWrapper';
import { toolbarConfig } from './toolbar-config';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './index.module.scss';
import { isJsonString } from '../../utils/JSON/json-utils';

interface IProps {
  fieldLabel: string;
  onChange: (value: string) => void;
  required?: boolean;
  startValidate?: boolean;
  error?: string;
  maxLength?: number;
  // eslint-disable-next-line react/require-default-props
  defaultValue?: string;
}

const TextEditor = ({ fieldLabel, required, onChange, defaultValue, startValidate, maxLength, error }: IProps) => {
  const [value, setValue] = useState(() =>
    defaultValue && isJsonString(defaultValue)
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(defaultValue)))
      : EditorState.createEmpty()
  );
  const [err, setErr] = useState('');

  useEffect(() => {
    checkValidation();
  }, [startValidate]);

  useEffect(() => {
    checkValidation();
    onChange(JSON.stringify(convertToRaw(value.getCurrentContent())));
  }, [value]);

  const checkValidation = () => {
    const text = value.getCurrentContent().getPlainText();
    if (startValidate && !text) setErr(ErrorMessages.RequiredField);
    else if (maxLength && text.length > maxLength) {
      setErr(`${ErrorMessages.OversizeText} ${maxLength}`);
    } else setErr('');
  };

  return (
    <TextEditorWrapper required={required} fieldLabel={fieldLabel} error={error || err}>
      <Editor
        toolbar={toolbarConfig}
        editorState={value}
        onEditorStateChange={setValue}
        wrapperClassName={`${styles.wrapper_text_edit} ${error || (err && styles.has_error)}`}
        editorClassName={`${styles.editor_text_edit} ${error || (err && styles.has_error)}`}
      />
    </TextEditorWrapper>
  );
};

TextEditor.defaultProps = {
  required: false,
  error: '',
  startValidate: false,
  maxLength: undefined,
};

export { TextEditor };
