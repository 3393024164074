import React from 'react';
import { TextField } from '@material-ui/core';
import style from '../index.module.scss';
import { InputProps } from '../interface';

const Input = ({ id, label, type, placeholder, value, onChange, onBlur, validationMsg, isInvalid }: InputProps) => (
  <>
    <TextField
      id={id}
      label={label}
      type={type}
      placeholder={placeholder}
      error={isInvalid}
      helperText={validationMsg}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      InputLabelProps={{
        shrink: true,
        className: style.input_label,
      }}
      InputProps={{
        className: isInvalid ? style.invalid : style.underline,
        disableUnderline: true,
      }}
      fullWidth
      variant="standard"
    />
  </>
);

export default Input;
