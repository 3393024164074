import React from 'react';
import { useSelector } from 'react-redux';
import AdminsFilters from '../Filters/AdminsFilters';
import Table from '../../../../components/Table';
import { adminsTableColumns } from './tableConfig';
import { selectAdmins } from '../../../../selectors/admins';
import Spinner from '../../../../components/Spinner';
import AddAdmin from './AddAdmin';
import style from '../index.module.scss';

const Admins = () => {
  const { isLoading, admins } = useSelector(selectAdmins);
  return (
    <>
      <div className={style.btn}>
        <AddAdmin />
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <AdminsFilters />
        {isLoading ? <Spinner /> : <Table rows={admins} columns={adminsTableColumns} signature="false" checkboxSelection={false} />}
      </div>
    </>
  );
};

export default Admins;
