import * as AdminActionCreators from './admins';
import * as CreatePasswordActionCreator from './createPassword';
import * as DonorActionCreators from './donors';
import * as AuthorizationActionCreators from './authorization';
import * as NotificationsActionCreators from './notifications';
import * as DonationTypesActionCreators from './donationTypes';
import * as PasswordResetActionCreators from './passwordResetEmail';
import * as ResetPasswordActionCreators from './resetPassword';
import * as DonationActionCreators from './donations';
import * as DonationCenterCreators from './donationCenters';
import * as PrivacyPolicyCreators from './privacyPolicy';
import * as RegionCreators from './regions';
import * as CitiesCreators from './cities';
import * as CategoryCreators from './category';
import * as QuestionCreators from './questions';
import * as AwardsCreators from './awards';
import * as PushNotificationCreators from './pushNotifications';

const ActionCreators = {
  ...AdminActionCreators,
  ...CreatePasswordActionCreator,
  ...DonorActionCreators,
  ...AuthorizationActionCreators,
  ...NotificationsActionCreators,
  ...DonationTypesActionCreators,
  ...PasswordResetActionCreators,
  ...ResetPasswordActionCreators,
  ...DonationActionCreators,
  ...DonationCenterCreators,
  ...PrivacyPolicyCreators,
  ...RegionCreators,
  ...CitiesCreators,
  ...CategoryCreators,
  ...QuestionCreators,
  ...AwardsCreators,
  ...PushNotificationCreators,
};
export default ActionCreators;
