export interface IAdmin {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  status: number;
  role: string[];
}

export interface IPushNotification {
  id: number;
  name: string;
  categoryId: number | string;
  shortDescription: string;
  longDescription: string;
  triggerId?: number | string;
  notificationDistribution: string;
  createdById?: string;
  lastUpdatedById?: string;
  created?: string;
  updated?: string;
  isDisabled?: boolean;
}

export interface IForm {
  name: string;
  shortDescription: string;
  longDescription: string;
  categoryId: number | string;
  notificationDistribution: string;
}

export const INITIAL_FORM_VALUES: IForm = {
  name: '',
  shortDescription: '',
  longDescription: '',
  categoryId: 0,
  notificationDistribution: '',
};

export enum FieldNames {
  Name = 'name',
  ShortDescription = 'shortDescription',
  LongDescription = 'longDescription',
  CategoryId = 'categoryId',
  TriggerName = 'triggerName',
  NotificationDistribution = 'notificationDistribution',
  Days = 'days',
  Hours = 'hours',
  Minutes = 'minutes',
}

export enum ReminderTypes {
  Scheduled = 'scheduled',
  Automated = 'automated',
  Outdated = 'outdated',
}

export type ReminderType = ReminderTypes.Automated | ReminderTypes.Outdated | ReminderTypes.Scheduled;

export const isReminderTypeScheduled = (type: ReminderType) => type === ReminderTypes.Scheduled;

export const isReminderTypeAutomated = (type: ReminderType) => type === ReminderTypes.Automated;

const getCreatedDefaultValue = (formData: SubmitData | null) => formData?.created;
const getUpdatedDefaultValue = (formData: SubmitData | null) => formData?.updated;

export class SubmitData {
  id: number;

  name: string;

  shortDescription: string;

  longDescription: string;

  categoryId: number | string;

  triggerName?: string;

  days?: number | string;

  hours?: number | string;

  minutes?: number | string;

  sendTime?: string;

  notificationDistribution?: Date | string;

  created?: string;

  updated?: string;

  isDisabled?: boolean;

  constructor(formData: SubmitData | null) {
    this.id = formData?.id || 0;
    this.name = formData?.name || '';
    this.shortDescription = formData?.shortDescription || '';
    this.longDescription = formData?.longDescription || '';
    this.categoryId = formData?.categoryId || '';
    this.notificationDistribution = formData?.notificationDistribution || new Date();

    this.days = formData?.days || 0;
    this.hours = formData?.hours || 0;
    this.minutes = formData?.minutes || 0;
    this.sendTime = `${this.days || '0'}d${this.hours || '0'}h${this.minutes || '0'}m`;

    this.triggerName = formData?.triggerName || '';

    this.created = getCreatedDefaultValue(formData);
    this.updated = getUpdatedDefaultValue(formData);
    this.isDisabled = formData?.isDisabled ?? true;
  }
}

export interface IPushNotificationCategory {
  id: number | string;
  name: string;
  picture: string;
  pushNotificationsCount: number;
  createdById: string;
  lastUpdatedById: string;
  created: string;
  updated: string;
  isDisabled: boolean;
}

export interface IPushNotificationTrigger {
  id: number;
  name: string;
}

export interface IProps {
  defaultValues: any;
  type: ReminderType;
  onCloseModal: () => void;
  isEditMode: boolean;
  pushNotificationCategories: IPushNotificationCategory[];
  pushNotificationTriggers?: IPushNotificationTrigger[];
  createdBy?: IAdmin;
  updatedBy?: IAdmin;
}

export interface IPastNotification extends Omit<IPushNotification, 'isDisabled'> {
  isDisabled: string;
}
