import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';

export interface ResetPasswordState {
  isTokenValid: boolean | null;
  isLoading: boolean;
  error: any;
  email: string | null;
  securityToken: string | null;
  isPasswordReset: boolean | null;
}

export const initialState: ResetPasswordState = {
  isTokenValid: null,
  isLoading: false,
  error: null,
  email: null,
  securityToken: null,
  isPasswordReset: null,
};

export const resetPasswordReducer = (state: ResetPasswordState = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.VALIDATE_SECURITY_TOKEN:
      return {
        ...state,
        isLoading: true,
        email: action.payload.email,
        securityToken: action.payload.securityToken,
      };
    case getSuccessAction(actionTypes.VALIDATE_SECURITY_TOKEN): {
      return {
        ...state,
        isTokenValid: action.payload,
        isLoading: false,
      };
    }
    case getFailAction(actionTypes.VALIDATE_SECURITY_TOKEN):
      return {
        ...state,
        isTokenValid: false,
        isLoading: false,
        error: action.error,
      };
    case actionTypes.RESET_PASSWORD:
      return {
        ...state,
        isLoading: true,
      };
    case getSuccessAction(actionTypes.RESET_PASSWORD): {
      return {
        ...state,
        isLoading: false,
        isPasswordReset: true,
      };
    }
    case getFailAction(actionTypes.RESET_PASSWORD): {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        isPasswordReset: false,
      };
    }
    default:
      return state;
  }
};
