import React from 'react';

export enum Actions {
  Block = 'Block',
  Unblock = 'Unblock',
  Reset = 'Reset',
  Delete = 'Delete',
  Null = '',
}

export const closeModalTitle = (action: Actions) => {
  if (action === Actions.Block) {
    return 'Заблокувати користувача';
  }
  if (action === Actions.Unblock) {
    return 'Розблокувати користувача';
  }
  if (action === Actions.Reset) {
    return 'Надіслати лист з відновленням паролю ';
  }
  if (action === Actions.Delete) {
    return 'Видалити користувача';
  }
  return '';
};

export function CloseModalBody({ action }: { action: string }) {
  if (action === Actions.Block) {
    return (
      <>
        <p>Ви дійсно бажаєте заблокувати користувача?</p>
        <p>Користувач буде заблокований</p>
      </>
    );
  }
  if (action === Actions.Unblock) {
    return (
      <>
        <p>Ви дійсно бажаєте розблокувати користувача?</p>
        <p>Користувач буде заблокований</p>
      </>
    );
  }
  if (action === Actions.Reset) {
    return (
      <>
        <p>Ви дійсно хочете надіслати лист на відновлення паролю?</p>
      </>
    );
  }
  if (action === Actions.Delete) {
    return (
      <>
        <p>Ви дійсно хочете видалити користувача ? </p>
        <p>Користувач буде видалений.</p>
      </>
    );
  }
  return <></>;
}

export const closeModalButton = (action: string) => {
  if (action === Actions.Block) {
    return 'Заблокувати';
  }
  if (action === Actions.Unblock) {
    return 'Розблокувати';
  }
  if (action === Actions.Reset) {
    return 'Надіслати';
  }
  if (action === Actions.Delete) {
    return 'Видалити';
  }
  return '';
};
