import { authorizedInstance } from './axiosInstances';
import { SubmitData } from '../pages/FAQ/Question/Models/SubmitData';

import { IFilteredQuestionLis } from '../actions/questions';

export const getQuestionList = () => authorizedInstance.get(`/faqs`);
export const getCategoryList = () => authorizedInstance.get(`/faqs/categories`);

export const getFilteredQuestionList = (payload: IFilteredQuestionLis) =>
  authorizedInstance.post(`/faqs/filter`, payload);
export const addNewQuestion = (newQuestion: SubmitData) => authorizedInstance.post(`/faqs`, newQuestion);
export const updateQuestion = (Question: SubmitData) => authorizedInstance.put(`/faqs`, Question);
export const deleteQuestion = (id: number) => authorizedInstance.delete(`faqs/${id}`);
