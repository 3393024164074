export interface FormField {
  value: string;
  isInvalid: boolean;
  validationMsg: string;
}

export const actionTypes = {
  ON_CHANGE: 'ON_CHANGE',
  VALIDATE: 'VALIDATE',
  SET_ERROR: 'SET_ERROR',
  CLEAR_FORM: 'CLEAR_FORM',
};

export enum FieldName {
  FirstName = 'firstName',
  LastName = 'lastName',
  Password = 'password',
  Email = 'email',
  PasswordConfirm = 'passwordConfirm',
}

export enum ErrorMessages {
  WrongFormat = 'Невірний формат. Перевірте правильність написання.',
  MismatchPasswords = 'Паролі не співпадають. Перевірте правильність написання.',
  RequiredField = 'Поле є обов’язковим. Щоб продовжити, заповніть дане поле.',
  Oversize = 'Розмір зображення має не перевищувати 3 мегабайти.',
  OversizeText = 'Перевищено кількість введених літер, допустима кількість ',
  ShortText = 'Кількість введених літер має перевищувати ',
  UkrainianPhoneMask = 'Повинен бути український номер телефону у форматі +38 000 000 00 00',
  CyrillicOrLatinRequired = 'Тільки кирилиця або латиниця',
  ImageWrongFormat = 'Невірне розширеня картинки, підтримуються тільки такі формати: jpg, jpeg, png, gif, svg',
  WrongFormatImage = 'Неправильний формат, файл неможливо завантажити',
}

export const FIELD_REGEXP = new RegExp(/(.|\s)*\S(.|\s)*/);
export const EMAIL_REGEXP = new RegExp(
  /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/
);
export const PASSWORD_REGEXP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
export const NAME_REGEXP = new RegExp(/^[а-яёґєіїa-z]{2,50}$/i);
export const CYRILLIC_LATIN_REGEXP = /^[!"#$№%&'()*+,-./\\:;<=>–?@[\]^_`{|}~а-яґєіїёЁa-z0-9\s]+$/i;
export const MAX_SIZE_IMAGE = 3;
export const BYTES_IN_MEGABYTE = Math.pow(1024, 2);
export const IMAGE_TYPE_REGEXP = /\.(jpg|jpeg|png|gif|svg)$/;
