import React from 'react';
import { Button, FormControl, InputLabel } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import { useDateDropdown } from './useDateDropdown';
import 'react-datepicker/dist/react-datepicker.css';
import { IDateDropdownProps } from './interface';
import style from './index.module.scss';

// eslint-disable-next-line sonarjs/cognitive-complexity
const DateDropdown = ({ label, startDate, endDate, onDateChange, isDirty, isRange }: IDateDropdownProps) => {
  const [
    handleClickCell,
    handleSelectSameDate,
    todaySelected,
    cellSelected,
    handleClickToday,
    tomorrowSelected,
    handleClickTomorrow,
  ] = useDateDropdown(startDate, endDate, onDateChange, isDirty, isRange);

  return isRange ? (
    <FormControl className={style.form} variant="standard">
      {label && (
        <InputLabel id="selection" className={style.input_label}>
          {label}
        </InputLabel>
      )}
      <DatePicker
        className={style.datepicker}
        selected={startDate ? new Date(startDate) : null}
        dayClassName={() => 'day--bg-transparent'}
        dateFormat="dd.MM.yyyy"
        selectsRange={isRange}
        startDate={startDate}
        endDate={endDate}
        onChange={handleClickCell}
        onClickOutside={handleSelectSameDate}
        isClearable
        placeholderText="Обрати дату"
        locale="uk"
      >
        <div>
          <Button
            className={todaySelected && !cellSelected ? style.btn_selected : style.btn}
            onClick={handleClickToday}
          >
            Сьогодні
          </Button>
          <Button
            className={tomorrowSelected && !cellSelected ? style.btn_selected : style.btn}
            onClick={handleClickTomorrow}
          >
            Завтра
          </Button>
        </div>
      </DatePicker>
    </FormControl>
  ) : (
    <DatePicker
      selected={startDate ? new Date(startDate) : null}
      onChange={handleClickCell}
      dateFormat="yyyy.MM.dd hh:mm"
      showTimeInput
    />
  );
};

export default DateDropdown;
