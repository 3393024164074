import { useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import { DateChange, IDateDropdownPropsArray, InputDate } from './interface';

function useDirty(isDirty: boolean, handleReset: () => void) {
  useEffect(() => {
    if (!isDirty) {
      handleReset();
    }
  }, [isDirty]);
}

export const useDateDropdown = (
  startDate: InputDate,
  endDate: InputDate,
  onDateChange: (value: DateChange) => void,
  isDirty: boolean,
  isRange: boolean
): IDateDropdownPropsArray => {
  const [todaySelected, setTodaySelected] = useState<boolean>(false);
  const [tomorrowSelected, setTomorrowSelected] = useState<boolean>(false);
  const [cellSelected, setCellSelected] = useState<boolean>(false);

  const todayDate = new Date();
  const tomorrowDate = addDays(todayDate, 1);

  const handleClickToday = (): void => {
    setCellSelected(false);
    setTodaySelected(!todaySelected);
    if (startDate) {
      setTodaySelected(true);
    }
  };

  const handleClickTomorrow = (): void => {
    setCellSelected(false);
    setTomorrowSelected(!tomorrowSelected);
    if (endDate) {
      setTomorrowSelected(true);
    }
  };

  const handleClickCell = (update: DateChange | Date | null): void => {
    setTodaySelected(false);
    setTomorrowSelected(false);
    setCellSelected(true);
    if (isRange) {
      onDateChange(update as DateChange);
    } else {
      onDateChange([update as Date, null]);
    }
  };

  const handleResetSelectedState = (): void => {
    setTodaySelected(false);
    setTomorrowSelected(false);
    setCellSelected(false);
  };

  const handleSelectSameDate = (): void => {
    if (startDate && !endDate) {
      onDateChange([startDate, startDate]);
    }
  };

  useDirty(isDirty, handleResetSelectedState);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    let start = startDate || null;
    let finish = endDate || null;

    if (cellSelected) {
      return;
    }

    if (todaySelected || tomorrowSelected) {
      start = todaySelected ? todayDate : tomorrowDate;
      finish = tomorrowSelected ? tomorrowDate : todayDate;
    }

    onDateChange([start, finish]);
  }, [todaySelected, tomorrowSelected]);

  return [
    handleClickCell,
    handleSelectSameDate,
    todaySelected,
    cellSelected,
    handleClickToday,
    tomorrowSelected,
    handleClickTomorrow,
  ];
};
