import React, { ChangeEvent, useState, useEffect } from 'react';
import { Box, Grid, Modal } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { CategoryHeading, CategoryUpload, CategoryCancelBtn, CategorySaveBtn, CategoryRemoveBtn } from './components';
import { EditFieldWrapper, TextEditField } from '../../../../components/Fields';
import { editSelectedCategory, selectCategory } from '../../../../selectors/category';
import AuthorInfo from '../../../../components/AuthorInfo';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import { useActions } from '../../../../hooks/useActions';
import { useCategoryModal } from './useCategoryModal';
import { useFlag } from '../../../../hooks/useFlag';
import { useConfirmReloadPage } from '../../../../hooks/useConfirmReloadPage';
import { ICategory } from '../../../../reducers/category';
import { getRequiredFieldMessage } from '../../../../utils/formFields/fieldValitationHelper';
import style from './components/index.module.scss';
import { selectQuestions } from '../../../../selectors/questions';
import { QuestionInCategory } from './components/QuestionInCategory';
import { Question } from '../../Question/Models/Question';

export enum ErrorMessages {
  SameCategoryName = 'Дана Категорія вже існує',
  WithoutError = '',
}

export const CategoryModal = () => {
  const { openedModal, closeModal } = useCategoryModal();
  const { selectedCategory } = useSelector(editSelectedCategory);
  const [name, setName] = useState<string>(selectedCategory?.name || '');
  const [isChanged, setIsChanged] = useState(false);
  const [questionsList, setQuestionsList] = useState<Question[]>([]);
  const [picture, setPicture] = useState<string>(selectedCategory?.picture || '');
  const { clearCategoryInfo } = useActions();
  const [confirmationModalVisible, openConfirmationModal, closeConfirmationModal] = useFlag();
  const { getQuestionList } = useActions();
  const { categories } = useSelector(selectCategory);
  const { questions } = useSelector(selectQuestions);

  const handleNameChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setName(event.target.value);
  };
  useConfirmReloadPage(isChanged);

  const isModalEqual = () => {
    if (selectedCategory) {
      const { name: initName, picture: initPicture } = selectedCategory;
      return initName === name && initPicture === picture;
    }
    return name === '' && picture === '';
  };

  useEffect(() => {
    setIsChanged(!isModalEqual());
  }, [name, picture]);

  const handleCloseModal = () => {
    if (isChanged) {
      openConfirmationModal();
    } else {
      clearCategoryInfo();
      onCloseModals();
    }
  };

  const onCloseModals = () => {
    closeConfirmationModal();
    closeModal();
  };

  useEffect(() => {
    getQuestionList();
  }, []);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    if (questions.length && selectedCategory) {
      const questionItem = questions.filter((item: Question) => item.category?.id === selectedCategory.id);
      const sortedQuestion = questionItem.sort((a: Question, b: Question) => {
        if (a.question.toLowerCase() < b.question.toLowerCase()) return -1;
        if (a.question.toLowerCase() > b.question.toLowerCase()) return 1;
        return 0;
      });
      setQuestionsList(sortedQuestion);
    }
  }, [questions]);

  const isCategoryExist = categories.some((element: ICategory) => element.name === name);
  const isValid = () => !(name.length && picture.length && !isCategoryExist);
  const errorCategoriesMessage = () => (isCategoryExist ? ErrorMessages.SameCategoryName : '');

  return (
    <>
      <Modal open={openedModal} onClose={handleCloseModal} className={style.modal}>
        <Box className={style.container}>
          <Grid container>
            <CategoryHeading categoryData={selectedCategory} handleCloseModal={handleCloseModal} />
            <TextEditField
              onChange={handleNameChange}
              fieldId="name"
              required
              fieldLabel="Категорія"
              defaultValue={selectedCategory?.name}
              disabled={false}
              rows={0}
              validators={[getRequiredFieldMessage, errorCategoriesMessage]}
            />
            {name && isCategoryExist && (
              <Grid className={style.error_text}>
                {selectedCategory?.name ? ErrorMessages.WithoutError : ErrorMessages.SameCategoryName}
              </Grid>
            )}
            <EditFieldWrapper fieldLabel="Зображення" required>
              <CategoryUpload onImageChange={setPicture} />
            </EditFieldWrapper>
            <QuestionInCategory questionsList={questionsList} />
            {!!selectedCategory && (
              <AuthorInfo
                isEditMode
                authorInfo={{
                  createDate: selectedCategory?.created,
                  updateDate: selectedCategory?.updated,
                  lastUpdatedBy: selectedCategory?.lastUpdatedByName,
                  createdBy: selectedCategory?.createdByName,
                }}
              />
            )}
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              style={{ padding: '10px 25px' }}
            >
              {!!selectedCategory && <CategoryRemoveBtn />}
              <CategoryCancelBtn name={name} picture={picture} />
              <CategorySaveBtn isDisabled={isValid()} name={name} picture={picture} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ConfirmationModal
        title="Вихід"
        isOpen={confirmationModalVisible}
        leftButtonLabel="Cкасувати"
        rightButtonLabel="Вихід"
        onClose={closeConfirmationModal}
        onCloseAllModals={onCloseModals}
      >
        <p>Ви дійсно бажаєте вийти без збереження змін?</p>
        <p>Усі внесені дані не буде збереженно.</p>
      </ConfirmationModal>
    </>
  );
};
