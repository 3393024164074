import React, { useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useActions } from '../../hooks/useActions';
import { selectPrivacyPolicy } from '../../selectors/privacyPolicy';
import Spinner from '../../components/Spinner';
import style from './index.module.scss';

const PrivacyPolicy = () => {
  const { isLoading, policy } = useSelector(selectPrivacyPolicy);
  const { getPrivacyPolicy, clearPrivacyPolicyState } = useActions();
  const { path } = useRouteMatch();
  const history = useHistory();

  useEffect(() => {
    if (!policy.length) {
      getPrivacyPolicy();
    }
    return () => {
      if (path !== history.location.pathname) {
        clearPrivacyPolicyState();
      }
    };
  }, [history, path]);

  return (
    <div className={style.container}>{isLoading ? <Spinner /> : <span className={style.policy}>{policy}</span>}</div>
  );
};

export default PrivacyPolicy;
