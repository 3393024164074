import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Search from '../../../../components/Search';
import DropdownMultiple from '../../../../components/DropdownMultiple';
import style from './index.module.scss';
import { useActions } from '../../../../hooks';
import { selectPushNotifications } from '../../../../selectors/pushNotifications';
import { IPushNotification, IPushNotificationCategory } from '../../constants';

const FilterAutomatization = ({ on, off }: { on: () => void; off: () => void }) => {
  const { pushNotifications, pushNotificationCategories } = useSelector(selectPushNotifications);
  const [category, setCategory] = useState([]);
  const [trigger, setTrigger] = useState([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const limitSymbols = 2;
  const { getFilteredPushNotificationList } = useActions();
  
  const handleCategoryChange = (event: ChangeEvent<any>) => {
    setCategory(event.target.value);
  };

  const handleSearchValueChange = (event: ChangeEvent<{ name?: string; value: string }>) => {
    setSearchValue(event.target.value);
  };
  const handleTriggerChange = (event: ChangeEvent<any>) => {
    setTrigger(event.target.value);
  };

  const getFilteredPushNotificationsBySearch = () =>
    pushNotifications.filter((notification: IPushNotification) =>
      notification.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    );

  const getFilteredPushNotificationsByCategory = () => {
    const categoriesIds: any = [];
    category?.forEach((item) => {
      pushNotificationCategories.forEach((cat: IPushNotificationCategory) => {
        if (item === cat.name) {
          categoriesIds.push(cat.id);
        }
      });
    });
    return pushNotifications.filter((note: IPushNotification) =>
      categoriesIds.some((id: number) => note.categoryId === id)
    );
  };
  
//   const getFilteredPushNotificationsByTrigger = () => {
//     
//   };

  useEffect(() => {
    if (searchValue.length > limitSymbols) {
      on();
      getFilteredPushNotificationList(getFilteredPushNotificationsBySearch());
    } else {
      off();
      getFilteredPushNotificationList([]);
    }
  }, [searchValue]);

  useEffect(() => {
    if (category.length) {
      on();
      getFilteredPushNotificationList(getFilteredPushNotificationsByCategory());
    } else {
      off();
      getFilteredPushNotificationList([]);
    }
  }, [category]);

//   useEffect(() => {
//     if (trigger.length) {
//       on();
//       getFilteredPushNotificationList(getFilteredPushNotificationsByTrigger());
//     } else {
//       off();
//       getFilteredPushNotificationList([]);
//     }
//   }, [trigger]);

  return (
    <div className={style.container}>
      <Search placeholder="Введіть назву" value={searchValue} onChange={handleSearchValueChange} />
      <DropdownMultiple
        label="Категорія"
        data={pushNotificationCategories}
        value={category}
        onChange={handleCategoryChange}
        isDirty={false}
      />
      <DropdownMultiple
        label="Тригер"
        data={[]}
        value={trigger}
        onChange={handleTriggerChange}
        isDirty={false}
      />
    </div>
  );
};

export default FilterAutomatization;
