export const statusOptions = [
  {
    value: 1,
    name: 'Активний',
  },
  {
    value: 2,
    name: 'Заблокований',
  },
];
