import React from 'react';
import { Button, Grid } from '@material-ui/core';
import classNames from 'classnames';
import { SecondaryButtonProps } from './interface';

import style from './index.module.scss';

const SecondaryButton = ({ placeholder, disabled, onClick, cn, img, count }: SecondaryButtonProps) => (
  <Grid className={classNames(style.btn, cn)}>
    <Button
      className={style.rounded_btn}
      disabled={disabled}
      onClick={onClick}
      variant="outlined"
      classes={{ disabled: style.disabled }}
      fullWidth
    >
      {img}
      {placeholder}
      {count}
    </Button>
  </Grid>
);

export default SecondaryButton;
