import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';

export interface IRegionsState {
    regions: [];
    isLoading: boolean;
    error: any;
  }

export const initialState: IRegionsState = {
  regions: [],
  isLoading: false,
  error: null,
};

export const regionsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.GET_REGIONS:
      return {
        ...state,
        regions: [],
        isLoading: true,
        error: null,
      };
    case getSuccessAction(actionTypes.GET_REGIONS):
      return {
        ...state,
        regions: action.payload,
        isLoading: false,
      };
    case getFailAction(actionTypes.GET_REGIONS):
      return {
        ...state,
        regions: [],
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
