import { useEffect, useState } from 'react';
import { formatISO } from 'date-fns';

export const useCheckDate = (dateRange: any) => {
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const startDate = dateRange[0];
  const endDate = dateRange[1];

  const dateToISO = (date: Date | null) => (date ? formatISO(date, { representation: 'date' }) : date);

  useEffect(() => {
    const [startDay, endDay] = dateRange as [Date | null, Date | null];

    const dateFromInfo = dateToISO(startDay);
    const dateToInfo = dateToISO(endDay);

    setDateFrom(dateFromInfo);
    setDateTo(dateToInfo);
  }, [dateRange]);

  return { dateFrom, dateTo, startDate, endDate, setDateFrom, setDateTo };
};
