import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectPushNotifications } from 'src/selectors/pushNotifications';
import { useActions } from 'src/hooks';
import { GridCellParams } from '@mui/x-data-grid';
import { useFlag } from '../../../../hooks/useFlag';
import { automatedTableColumns } from './automatedTableConfig';
import { IPushNotification, IPushNotificationCategory, ReminderTypes } from '../../constants';
import Table from '../../../../components/Table';
import style from '../index.module.scss';
import FilterAutomatization from '../../FiltersReminder/FilterAutomatization';
import { AddReminder } from '../../ButtonsReminder/AddReminder';
import { ModalContextProvider, useModal } from '../../useModal';

const Authomation = () => {
  const { getPushNotifications, getPushNotificationCategories, getPushNotificationTriggers } = useActions();
  const {
    pushNotifications,
    isLoading,
    filteredPushNotifications,
    pushNotificationCategories,
  } = useSelector(selectPushNotifications);
  const { openEditModal } = useModal();

  const [notifications, setNotifications] = useState(pushNotifications);
  const [filter, on, off] = useFlag();

  const getAutomatedPushNotificationsWithCategoryNames = (automatedPushNotes: IPushNotification[]) => {
    const automatedModifedPushNotifications: IPushNotification[] = [];

    automatedPushNotes.forEach((note: IPushNotification) => {
      pushNotificationCategories.forEach((category: IPushNotificationCategory) => {
        if (note.categoryId === category.id) {
          automatedModifedPushNotifications.push({ ...note, categoryId: category.name });
        }
      });
    });
    
    return automatedModifedPushNotifications;
  };

  useEffect(() => {
    getPushNotifications();
    getPushNotificationCategories();
    getPushNotificationTriggers();
    setNotifications(pushNotifications);
  }, []);

  useEffect(() => {
    if (filteredPushNotifications.length) {
      setNotifications(filteredPushNotifications);
    } else if (!filter) {
      setNotifications(pushNotifications);
    } else if (!filteredPushNotifications.length && filter) {
      setNotifications([]);
    }
  }, [filteredPushNotifications, filter]);

  return (
    <>
      <div className={style.btn}>
        <AddReminder placeholder='Додати автоматизацію' type={ReminderTypes.Automated} />
      </div>
      <FilterAutomatization on={on} off={off} />
      <div className={style.table}>
        <Table
          rows={getAutomatedPushNotificationsWithCategoryNames(notifications)}
          columns={automatedTableColumns}
          checkboxSelection={false}
          isRowSelectable={() => false}
          signature="false"
          loading={isLoading}
          onCellClick={(params: GridCellParams) => {
            openEditModal(params.row as IPushNotification);
          }}
        />
      </div>
    </>
  );
};
function AutomatedNotifications() {
  return (
    <ModalContextProvider>
      <Authomation />
    </ModalContextProvider>
  );
}

export default AutomatedNotifications;
