import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GridCellParams } from '@mui/x-data-grid';
import { AddReminder } from '../../ButtonsReminder/AddReminder';
import FilterScheduled from '../../FiltersReminder';
import { selectPushNotifications } from '../../../../selectors/pushNotifications';
import { useActions } from '../../../../hooks';
import { useFlag } from '../../../../hooks/useFlag';
import style from '../index.module.scss';
import { plannedTableColumns } from './tableConfig';
import Table from '../../../../components/Table';
import {
  ModalContextProvider,
  useModal,
} from '../../useModal';
import { IPushNotification, IPushNotificationCategory, ReminderTypes } from '../../constants';

const PlannedNotifications = () => {
  const { getPushNotifications, getPushNotificationCategories } = useActions();
  const { pushNotifications, isLoading, filteredPushNotifications, pushNotificationCategories } =
    useSelector(selectPushNotifications);
  const { openEditModal } = useModal();
  const [notifications, setNotifications] = useState(pushNotifications);
  const [filter, on, off] = useFlag();

  const getPushNotificationsWithCategoryNames = (pushNotes: IPushNotification[]) => {
    const modifedPushNotifications: IPushNotification[] = [];
    const filtered = pushNotes.filter((notification) => !notification.isDisabled);

    filtered.forEach((note: IPushNotification) => {
      pushNotificationCategories.forEach((category: IPushNotificationCategory) => {
        if (note.categoryId === category.id) {
          modifedPushNotifications.push({ ...note, categoryId: category.name });
        }
      });
    });
    return modifedPushNotifications;
  };

  useEffect(() => {
    getPushNotifications();
    getPushNotificationCategories();
    setNotifications(pushNotifications);
  }, []);

  useEffect(() => {
    if (filteredPushNotifications.length) {
      setNotifications(filteredPushNotifications);
    } else if (!filter) {
      setNotifications(pushNotifications);
    } else if (!filteredPushNotifications.length && filter) {
      setNotifications([]);
    }
  }, [filteredPushNotifications, filter]);

  return (
    <>
      <div className={style.btn}>
        <AddReminder placeholder='Запланувати нагадування' type={ReminderTypes.Scheduled} />
      </div>

      <FilterScheduled on={on} off={off} />
      <div className={style.table}>
        <Table
          rows={getPushNotificationsWithCategoryNames(notifications)}
          columns={plannedTableColumns}
          checkboxSelection={false}
          isRowSelectable={() => false}
          signature="false"
          loading={isLoading}
          onCellClick={(params: GridCellParams) => {
            openEditModal(params.row as IPushNotification);
          }}
        />
      </div>
    </>
  );
};
function Scheduled() {
  return (
    <ModalContextProvider>
      <PlannedNotifications />
    </ModalContextProvider>
  );
}

export default Scheduled;
