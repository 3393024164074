import React from 'react';
import { Grid } from '@material-ui/core';
import { ReactComponent as Cross } from '../../../../../assets/images/Cross.svg';
import { ICategory } from '../../../../../actions/category';
import style from './index.module.scss';

interface IProps {
  categoryData: ICategory | null;
  handleCloseModal: () => void;
}

export const CategoryHeading = ({ categoryData, handleCloseModal }: IProps) => (
  <Grid container direction="row" item xs={12}>
    <Grid container direction="row" alignItems="center" justifyContent="center">
      <Grid>
        {categoryData?.id ? <h3>Редагувати категорію</h3> : <h3>Додати нову категорію</h3>}
        <Cross className={style.cross} onClick={handleCloseModal} />
      </Grid>
    </Grid>
  </Grid>
);
