import { call, put, takeLatest } from 'redux-saga/effects';
import { getUsers } from '../api/users';
import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { showNotification } from '../actions/notifications';

export function* getDonorsSaga(action: IAction) {
  try {
    const { data } = yield call(getUsers, action.payload.searchValue, action.payload.status, ['user']);
    yield put({
      type: getSuccessAction(actionTypes.GET_DONORS),
      payload: data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.GET_DONORS),
      error,
    });
  }
}

export function* watchGetDonors() {
  yield takeLatest(actionTypes.GET_DONORS, getDonorsSaga);
}
