import React, { useState } from 'react';
import { Grid, Link, Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import ConfirmationModal from '../../../../../components/ConfirmationModal';
import SecondaryBtn from '../../../../../components/SecondaryBtn';
import MainButton from '../../../../../components/MainBtn';
import { Question } from '../../Models/Question';
import { useFlag } from '../../../../../hooks/useFlag';
import { useActions } from '../../../../../hooks/useActions';

import style from '../index.module.scss';

interface IProps {
  formData: Question;
  handleSave: (isDraft: boolean) => () => void;
  onCloseModal: () => void;
  disabledMain: boolean;
}
enum EActions {
  delete = 'Видалити',
  cancel = 'Вихід',
}

// eslint-disable-next-line sonarjs/cognitive-complexity
const ModalButtons = ({ formData, handleSave, onCloseModal, disabledMain = false }: IProps) => {
  const { deleteQuestion } = useActions();
  const [confirmationModalVisible, openConfirmationModal, closeConfirmationModal] = useFlag();
  const { id, isDraft } = formData;
  const [action, setAction] = useState<EActions>(EActions.delete);

  const handleConfirmModal = () => {
    if (id && action === EActions.delete) {
      deleteQuestion(id as number);
    }
    closeConfirmationModal();
    onCloseModal();
  };

  const isFormFieldsEmpty = () => !(isAnswerFieldEmpty() && formData.category && formData.question);

  const isAnswerFieldEmpty = () => {
    try {
      const parsedAnswer = JSON.parse(formData.answer);
      return Boolean(parsedAnswer.blocks[0].text);
    } catch {
      return false;
    }
  };

  const handleConfirmCancelModal = () => {
    setAction(EActions.cancel);
    onCloseModal();
  };

  const handleConfirmDeleteModal = () => {
    setAction(EActions.delete);
    openConfirmationModal();
  };

  return (
    <Box sx={{ mx: 2, my: 2 }}>
      <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
        {id && (
          <Grid item xs={4} className={style.delete_container}>
            <Link component="button" className={style.delete_link} onClick={handleConfirmDeleteModal}>
              <DeleteIcon />
              <Grid>Видалити Питання</Grid>
            </Link>
          </Grid>
        )}
        <Grid item className={style.btn_gap} xs={2}>
          <SecondaryBtn placeholder="Скасувати" onClick={handleConfirmCancelModal} />
        </Grid>
        <Grid item className={style.btn_gap} xs={2}>
          <SecondaryBtn
            placeholder={id && isDraft ? 'Зберегти' : 'В чернетки'}
            disabled={isFormFieldsEmpty()}
            onClick={handleSave(true)}
          />
        </Grid>
        <Grid item className={style.btn_gap} xs={2}>
          <MainButton
            placeholder={id && !isDraft ? 'Зберегти' : 'Опублікувати'}
            onClick={handleSave(false)}
            disabled={disabledMain}
          />
        </Grid>

        <ConfirmationModal
          isOpen={confirmationModalVisible}
          onClose={closeConfirmationModal}
          onCloseAllModals={action ? handleConfirmModal : handleConfirmCancelModal}
          leftButtonLabel="Cкасувати"
          title={action ? 'Видалення' : 'Вихід'}
          rightButtonLabel={action ? 'Видалення' : 'Вихід'}
        >
          {action === EActions.delete ? (
            <>
              <p>Ви дійсно бажаєте видалити це Питання?</p>
              <p>Цю дію неможливо відмінити</p>
            </>
          ) : (
            <>
              <p>Ви дійсно бажаєте вийти без збереження змін?</p>
              <p>Усі внесені дані не буде збереженно.</p>
            </>
          )}
        </ConfirmationModal>
      </Grid>
    </Box>
  );
};

export { ModalButtons };
