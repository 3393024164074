import { call, put, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { showNotification } from '../actions/notifications';
import { getSuccessAction, actionTypes, getFailAction, IAction } from '../actions/types';
import { addNewAward, editAward, getAllAwards, deleteAward } from '../api/awards';

export function* addAwardSaga(action: IAction) {
  try {
    const response: AxiosResponse = yield call(addNewAward, action.payload);

    yield put({
      type: getSuccessAction(actionTypes.ADD_AWARD),
      payload: response.data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.ADD_AWARD),
      error,
    });
  }
}

export function* editAwardSaga(action: IAction) {
  try {
    const response: AxiosResponse = yield call(editAward, action.payload);

    yield put({
      type: getSuccessAction(actionTypes.EDIT_AWARD),
      payload: response.data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.EDIT_AWARD),
      error,
    });
  }
}

export function* getAwardsSaga() {
  try {
    const response: AxiosResponse = yield call(getAllAwards);

    yield put({
      type: getSuccessAction(actionTypes.GET_AWARDS),
      payload: response.data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.GET_AWARDS),
      error,
    });
  }
}

export function* deleteAwardSaga(action: IAction) {
  try {
    const response: AxiosResponse = yield call(deleteAward, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.CLEAR_AWARD),
      payload: response.data,
    });

  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.CLEAR_AWARD),
      error,
    });
  }
}

export function* watchAddAwardSaga() {
  yield takeEvery(actionTypes.ADD_AWARD, addAwardSaga);
  yield takeEvery(actionTypes.EDIT_AWARD, editAwardSaga);
  yield takeEvery(actionTypes.GET_AWARDS, getAwardsSaga);
  yield takeEvery(actionTypes.CLEAR_AWARD, deleteAwardSaga);
}