import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getUsers } from '../api/users';
import { addAdmin, blockAdmin, unblockAdmin, resetAdmin } from '../api/admins';
import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { showNotification } from '../actions/notifications';

export function* getAdminsSaga(action: IAction) {
  try {
    const { data } = yield call(getUsers, action.payload.searchValue, action.payload.status, action.payload.role);
    yield put({
      type: getSuccessAction(actionTypes.GET_ADMINS),
      payload: data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.GET_ADMINS),
      error,
    });
  }
}

export function* addAdminSaga(action: IAction) {
  try {
    yield call(addAdmin, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.ADD_ADMIN),
      payload: action.payload.email,
    });

    yield put(showNotification(`Лист успішно надіслано на ${action.payload.email}`));
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));
    yield put({
      type: getFailAction(actionTypes.ADD_ADMIN),
      error,
    });
  }
}

export function* blockAdminSaga(action: IAction) {
  try {
    yield call(blockAdmin, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.BLOCK_ADMIN),
      payload: action.payload,
    });

    yield put(showNotification(`Успішно заблоковано ${action.payload}`));
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));
    yield put({
      type: getFailAction(actionTypes.BLOCK_ADMIN),
      error,
    });
  }
}

export function* unblockAdminSaga(action: IAction) {
  try {
    yield call(unblockAdmin, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.UNBLOCK_ADMIN),
      payload: action.payload,
    });

    yield put(showNotification(`Успішно розблоковано ${action.payload}`));
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));
    yield put({
      type: getFailAction(actionTypes.UNBLOCK_ADMIN),
      error,
    });
  }
}

export function* resetAdminSaga(action: IAction) {
  try {
    yield call(resetAdmin, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.RESET_ADMIN),
      payload: action.payload,
    });

    yield put(showNotification(`Успішно надіслано скидання ${action.payload}`));
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));
    yield put({
      type: getFailAction(actionTypes.RESET_ADMIN),
      error,
    });
  }
}

export function* watchGetAdmins() {
  yield takeLatest(actionTypes.GET_ADMINS, getAdminsSaga);
}

export function* watchAddAdmin() {
  yield takeEvery(actionTypes.ADD_ADMIN, addAdminSaga);
}

export function* watchBlockAdmin() {
  yield takeEvery(actionTypes.BLOCK_ADMIN, blockAdminSaga);
}

export function* watchUnblockAdmin() {
  yield takeEvery(actionTypes.UNBLOCK_ADMIN, unblockAdminSaga);
}

export function* watchResetAdmin() {
  yield takeEvery(actionTypes.RESET_ADMIN, resetAdminSaga);
}
