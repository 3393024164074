import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button } from '@material-ui/core';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { useFlag, useActions } from 'src/hooks';
import { statusOptions } from '../../Filters/AdminsFilters/optionsData';
import { Actions, CloseModalBody, closeModalButton, closeModalTitle } from './AdminActionHelpers';
import style from './index.module.scss';

export default function AdminActionBtns({ userEmail, userStatus }: { userEmail: string; userStatus: number }) {
  const statusValue = statusOptions.find((item) => item.value === userStatus)?.value;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [action, setAction] = useState<Actions>(Actions.Null);

  const [confirmationModalVisible, openConfirmationModal, closeConfirmationModal] = useFlag();
  const { unblockAdmin, blockAdmin, resetAdmin } = useActions();

  const handleOpenActions = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAction(Actions.Null);
  };

  const handleOpenModal = (type: string) => {
    setAnchorEl(null);
    openConfirmationModal();

    if (type === Actions.Block) {
      setAction(Actions.Block);
    }
    if (type === Actions.Unblock) {
      setAction(Actions.Unblock);
    }
    if (type === Actions.Delete) {
      setAction(Actions.Delete);
    }
    if (type === Actions.Reset) {
      setAction(Actions.Reset);
    }
  };

  const handleCloseModal = () => {
    closeConfirmationModal();
    setAction(Actions.Null);

    if (action === Actions.Block) {
      blockAdmin(userEmail);
    }
    if (action === Actions.Unblock) {
      unblockAdmin(userEmail);
    }
    // Uncomment once delete endpoint is done
    // if (action === Actions.Delete) {
    //   console.log(`Deleted user: ${userEmail}`);
    // }
    if (action === Actions.Reset) {
      resetAdmin(userEmail);
    }
  };

  return (
    <>
      <div>
        {statusValue !== 2 && (
          <Button
            aria-controls={anchorEl ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={anchorEl ? 'true' : undefined}
            onClick={handleOpenActions}
          >
            <MoreHorizIcon className={style.menu_icon} />
          </Button>
        )}
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {statusValue !== 2 && (
            <MenuItem
              style={{ fontSize: '14px' }}
              onClick={() => handleOpenModal(statusValue === 3 ? Actions.Unblock : Actions.Block)}
            >
              {statusValue === 3 ? 'Розблокувати' : 'Заблокувати'}
            </MenuItem>
          )}
          {statusValue === 1 && (
            <MenuItem style={{ fontSize: '14px' }} onClick={() => handleOpenModal(Actions.Reset)}>
              Пароль
            </MenuItem>
          )}

          {/* Uncomment once delete endpoint is done
          <Button
            onClick={() => handleOpenModal(Actions.Delete)}
            className={style.delete_btn}
            fullWidth
            variant="contained"
            color="primary"
          >
            Видалити
          </Button> */}
        </Menu>
      </div>
      <ConfirmationModal
        title={closeModalTitle(action)}
        isOpen={confirmationModalVisible}
        leftButtonLabel="Cкасувати"
        rightButtonLabel={closeModalButton(action)}
        onClose={closeConfirmationModal}
        onCloseAllModals={handleCloseModal}
      >
        <CloseModalBody action={action} />
      </ConfirmationModal>
    </>
  );
}
