import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import { IAdminsState } from '../reducers/admins';
import { IAdminState } from '../reducers/admin';

const getAdmins = (state: IRootState) => state.admins;

export const selectAdmins = createSelector(getAdmins, (adminsState: IAdminsState) => ({
  ...adminsState,
  admins: adminsState.admins.map((admin) => ({
    ...admin,
    id: admin.email,
  })),
}));

const addAdmin = (state: IRootState) => state.addAdmin;

export const selectAddAdmin = createSelector(addAdmin, (adminState: IAdminState) => ({
  ...adminState,
}));
