import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';

export interface ICities {
  id: number;
  name: string;
  regionId: number;
  regionName: string;
}
export interface ICitiesState {
  cities: ICities[];
  isLoading: boolean;
  error: any;
}

export const initialState: ICitiesState = {
  cities: [],
  isLoading: false,
  error: null,
};

export const citiesReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.GET_CITIES_IN_REGIONS:
      return {
        ...state,
        cities: [],
        isLoading: true,
        error: null,
      };
    case getSuccessAction(actionTypes.GET_CITIES_IN_REGIONS):
      return {
        ...state,
        cities: action.payload,
        isLoading: false,
      };
    case getFailAction(actionTypes.GET_CITIES_IN_REGIONS):
      return {
        ...state,
        cities: [],
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
