import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FilterScheduled from '../../FiltersReminder';
import { selectPushNotifications } from '../../../../selectors/pushNotifications';
import { useActions } from '../../../../hooks';
import { useFlag } from '../../../../hooks/useFlag';
import style from '../index.module.scss';
import Table from '../../../../components/Table';
import { IPushNotification, IPushNotificationCategory, IPastNotification } from '../../constants';
import { pastTableColumns } from './tableConfig';

const PastNotifications = () => {
  const { getPushNotifications, getPushNotificationCategories } = useActions();
  const { pushNotifications, isLoading, filteredPushNotifications, pushNotificationCategories } =
    useSelector(selectPushNotifications);
  const [notifications, setNotifications] = useState(pushNotifications);
  const [filter, on, off] = useFlag();

  const getPushNotificationsWithCategoryNames = (pushNotes: IPushNotification[]) => {
    const modifedPushNotifications: IPastNotification[] = [];
    const filtered = pushNotes.filter((notification) => notification.isDisabled);

    filtered.forEach((note: IPushNotification) => {
      pushNotificationCategories.forEach((category: IPushNotificationCategory) => {
        if (note.categoryId === category.id) {
          modifedPushNotifications.push({ ...note, categoryId: category.name, isDisabled: 'Скасовано' });
        }
      });
    });
    return modifedPushNotifications;
  };

  useEffect(() => {
    getPushNotifications();
    getPushNotificationCategories();
    setNotifications(pushNotifications);
  }, []);

  useEffect(() => {
    if (filteredPushNotifications.length) {
      setNotifications(filteredPushNotifications);
    } else if (!filter) {
      setNotifications(pushNotifications);
    } else if (!filteredPushNotifications.length && filter) {
      setNotifications([]);
    }
  }, [filteredPushNotifications, filter]);

  return (
    <>
      <FilterScheduled on={on} off={off} />
      <div className={style.table}>
        <Table
          rows={getPushNotificationsWithCategoryNames(notifications)}
          columns={pastTableColumns}
          checkboxSelection={false}
          isRowSelectable={() => false}
          signature="false"
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default PastNotifications;
