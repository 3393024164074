import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AddCategory } from './AddCategory/AddCategory';
import { CategoryModalContextProvider, useCategoryModal } from './CategoryModal/useCategoryModal';
import { categoryTableColumns } from './Constants/categoryTableConfig';
import { CategoryFilters } from './FilterCategory';
import { useActions } from '../../../hooks/useActions';
import { selectCategory } from '../../../selectors/category';
import Table from '../../../components/Table';
import Spiner from '../../../components/Spinner';
import style from './index.module.scss';
import { useDebounce } from '../../../hooks/useDebounce';

const Categorization = () => {
  const [searchName, setSearchName] = useState('');
  const { getCategoriesFAQ, getCategoriesByNameFAQ, getCategoryInfo } = useActions();
  const dispatch = useDispatch();
  const { openModal } = useCategoryModal();
  const { openedModal } = useCategoryModal();
  const { isLoading, categories } = useSelector(selectCategory);
  const limitSymbols = 3;

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  };

  const name = useDebounce<string>(searchName);

  useEffect(() => {
    if (name.length >= limitSymbols) {
      getCategoriesByNameFAQ({ name });
    }
  }, [name]);

  const onCategoryClick = (params: any) => {
    dispatch(getCategoryInfo(params.row));
    openModal();
  };

  useEffect(() => {
    if (!openedModal) {
      getCategoriesFAQ();
    }
  }, [openedModal]);
  return (
    <>
      <div className={style.btn}>
        <AddCategory />
      </div>
      <div>
        <CategoryFilters searchName={searchName} onFieldChange={handleFieldChange} />
      </div>
      {isLoading ? (
        <Spiner />
      ) : (
        <Table
          rows={categories}
          columns={categoryTableColumns}
          loading={isLoading}
          onCellClick={onCategoryClick}
          signature="false"
        />
      )}
    </>
  );
};

function Category() {
  return (
    <CategoryModalContextProvider>
      <Categorization />
    </CategoryModalContextProvider>
  );
}

export default Category;
