export const actionTypes = {
  GET_ADMINS: 'GET_ADMINS',
  ADD_ADMIN: 'ADD_ADMIN',
  BLOCK_ADMIN: 'BLOCK_ADMIN',
  UNBLOCK_ADMIN: 'UNBLOCK_ADMIN',
  RESET_ADMIN: 'RESET_ADMIN',
  CREATE_PASSWORD: 'CREATE_PASSWORD',
  GET_DONORS: 'GET_DONORS',
  GET_DONATION_TYPES: 'GET_DONATION_TYPES',
  ADD_DONATION_TYPE: 'ADD_DONATION_TYPE',
  EDIT_DONATION_TYPE: 'EDIT_DONATION_TYPE',
  CLEAR_DONATION_TYPE: 'CLEAR_DONATION_TYPE',
  CLEAR_ADD_DONATION_TYPE: 'CLEAR_ADD_DONATION_TYPE',
  ADD_DONATION_TYPE_CENTER: 'ADD_DONATION_TYPE_CENTER',
  LOGIN_USER: 'LOGIN_USER',
  LOGOUT_USER: 'LOGOUT_USER',
  SHOW_NOTIFICATION: 'SHOW_NOTIFICATION',
  HIDE_NOTIFICATION: 'HIDE_NOTIFICATION',
  SEND_RESET_EMAIL: 'SEND_RESET_EMAIL',
  CLEAR_PASSWORD_RESET_EMAIL: 'CLEAR_PASSWORD_RESET_EMAIL',
  VALIDATE_SECURITY_TOKEN: 'VALIDATE_SECURITY_TOKEN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  GET_DONATIONS: 'GET_DONATIONS',
  GET_DONATION: 'GET_DONATION',
  EDIT_DONATION_STATUS: 'EDIT_DONATION_STATUS',
  GET_DONATION_CENTER: 'GET_DONATION_CENTER',
  GET_DONATION_CENTERS_IN_CITIES: 'GET_DONATION_CENTERS_IN_CITIES',
  GET_FILTERED_DONATION_CENTERS: 'GET_FILTERED_DONATION_CENTERS',
  ADD_DONATION_CENTER: 'ADD_DONATION_CENTER',
  EDIT_DONATION_CENTER: 'EDIT_DONATION_CENTER',
  GET_INFO_DONATION_CENTER: 'GET_INFO_DONATION_CENTER',
  CLEAR_INFO_DONATION_CENTER: 'CLEAR_INFO_DONATION_CENTER',
  CLEAR_DONATION_CENTER: 'CLEAR_DONATION_CENTER',
  GET_DONATION_CENTERS: 'GET_DONATION_CENTERS',
  GET_PRIVACY_POLICY: 'GET_PRIVACY_POLICY',
  GET_REGIONS: 'GET_REGIONS',
  GET_CITIES: 'GET_CITIES',
  GET_CITIES_IN_REGIONS: 'GET_CITIES_IN_REGIONS',
  CLEAR_PRIVACY_POLICY: 'CLEAR_PRIVACY_POLICY',
  ADD_CATEGORY_FAQ: 'ADD_CATEGORY_FAQ',
  GET_CATEGORY_INFO: 'GET_CATEGORY_INFO',
  CLEAR_CATEGORY_INFO: 'CLEAR_CATEGORY_INFO',
  GET_CATEGORIES_FAQ: 'GET_CATEGORIES_FAQ',
  EDIT_CATEGORY_FAQ: 'EDIT_CATEGORY_FAQ',
  FILTER_CATEGORY_FAQ: 'FILTER_CATEGORY_FAQ',
  ADD_NEW_QUESTION: 'ADD_NEW_QUESTION',
  UPDATE_QUESTION: 'UPDATE_QUESTION',
  DELETE_QUESTION: 'DELETE_QUESTION',
  REMOVE_CATEGORY_FAQ: 'REMOVE_CATEGORY_FAQ',
  GET_QUESTIONS_LIST: 'GET_QUESTIONS_LIST',
  GET_FILTER_QUESTIONS_LIST: 'GET_FILTER_QUESTIONS_LIST',
  GET_SEARCH_QUESTIONS: 'GET_SEARCH_QUESTIONS',
  GET_FAQ_CATEGORY_LIST: 'GET_FAQ_CATEGORY_LIST',
  GET_AWARDS: 'GET_AWARDS',
  ADD_AWARD: 'ADD_AWARD',
  EDIT_AWARD: 'EDIT_AWARD',
  UPDATE_AWARD: 'UPDATE_AWARD',
  GET_CATEGORIES_BY_NAME_FAQ: 'GET_CATEGORIES_BY_NAME_FAQ',
  CLEAR_AWARD: 'CLEAR_AWARD',
  GET_PUSH_NOTIFICATIONS: 'GET_PUSH_NOTIFICATIONS',
  ADD_PUSH_NOTIFICATION: 'ADD_PUSH_NOTIFICATION',
  EDIT_PUSH_NOTIFICATION: 'EDIT_PUSH_NOTIFICATION',
  DELETE_PUSH_NOTIFICATION: 'DELETE_PUSH_NOTIFICATION',
  CANCEL_PUSH_NOTIFICATION: 'CANCEL_PUSH_NOTIFICATION',
  GET_FILTER_PUSH_NOTIFICATIONS_LIST: 'GET_FILTER_PUSH_NOTIFICATIONS_LIST',
  GET_SEARCH_PUSH_NOTIFICATIONS: 'GET_SEARCH_PUSH_NOTIFICATIONS',
  GET_PUSH_NOTIFICATION_CATEGORIES: 'GET_PUSH_NOTIFICATION_CATEGORIES',
  GET_PUSH_NOTIFICATION_TRIGGERS: 'GET_PUSH_NOTIFICATION_TRIGGERS',
  GET_PUSH_NOTIFICATION_CREATED_BY: 'GET_PUSH_NOTIFICATION_CREATED_BY',
  GET_PUSH_NOTIFICATION_UPDATED_BY: 'GET_PUSH_NOTIFICATION_UPDATED_BY',
};

export interface IAction {
  type: string;
  payload?: any;
  error?: any;
}

export const getSuccessAction = (actionName: string) => `${actionName}_SUCCESS`;
export const getFailAction = (actionName: string) => `${actionName}_FAIL`;
