import { actionTypes } from './types';

export interface IDonorsFilterData {
  searchValue: string;
  status: number;
}

export const getDonors = (filters: IDonorsFilterData) => ({
  type: actionTypes.GET_DONORS,
  payload: filters,
});
