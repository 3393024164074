import React, { useReducer, useState, ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Grid, Modal, Select, InputLabel, MenuItem } from '@material-ui/core';
import { ReactComponent as Cross } from '../../../../../assets/images/Cross.svg';
import styles from './index.module.scss';
import { userRoles } from '../AddAdmin/index.constants';
import { clearForm, formFieldsReducer, onChange, validate } from '../../../../../utils/formFields/formFieldsReducer';
import Input from '../../../../../components/Inputs/Input';
import MainButton from '../../../../../components/MainBtn';
import { FieldName } from '../../../../../utils/formFields/formFields.constants';
import { addAdminInitialState } from './index.constants';
import { IAddAdminModalProps } from './index.interface';
import { useActions } from '../../../../../hooks/useActions';
import { selectAddAdmin } from '../../../../../selectors/admins';

export default function AddAdminModal({ isOpen, onClose }: IAddAdminModalProps) {
  const [role, setRole] = useState(userRoles[0].value);
  const [state, dispatch] = useReducer(formFieldsReducer, addAdminInitialState);
  const { addAdmin } = useActions();
  const { email } = useSelector(selectAddAdmin);

  const handleRoleChange = (event: ChangeEvent<any>) => {
    setRole(event.target.value);
  };

  const handleChange = (fieldName: FieldName) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(onChange(fieldName, event.target.value));
  };

  const handleValidation = (fieldName: FieldName) => (event: React.FocusEvent<HTMLInputElement>) => {
    dispatch(validate(fieldName, event.target.value));
  };

  const handleAddAdmin = () => {
    addAdmin({
      name: state[FieldName.FirstName].value,
      lastName: state[FieldName.LastName].value,
      email: state[FieldName.Email].value,
      role,
    });
  };

  useEffect(() => {
    if (email === state[FieldName.Email].value) {
      onClose();
    }
  }, [email]);

  const handleCloseModal = () => {
    onClose();
    dispatch(clearForm());
  };

  const isLoginValid = () => !_.every(state, (field) => !field.isInvalid && !!field.value);

  return (
    <Modal open={isOpen} onClose={handleCloseModal} className={styles.modal}>
      <Grid className={styles.modal_body}>
        <Grid className={styles.modal_body_content}>
          <Cross className={styles.cross} onClick={handleCloseModal} />
          <Grid className={styles.modal_text}>
            <h2>Додати нового учасника</h2>
            <p>
              На вказану електронну адресу, користувач отримає посилання для входу в адмінпортал, яке буде активне
              протягом 24 годин.
            </p>
          </Grid>
          <Grid className={styles.modal_input}>
            <Input
              label="Ім'я"
              type="text"
              placeholder="Ліна"
              value={state[FieldName.FirstName].value}
              onChange={handleChange(FieldName.FirstName)}
              onBlur={handleValidation(FieldName.FirstName)}
              validationMsg={state[FieldName.FirstName].validationMsg}
              isInvalid={state[FieldName.FirstName].isInvalid}
            />
          </Grid>

          <Grid className={styles.modal_input}>
            <Input
              label="Прізвище"
              type="text"
              placeholder="Костенко"
              value={state[FieldName.LastName].value}
              onChange={handleChange(FieldName.LastName)}
              onBlur={handleValidation(FieldName.LastName)}
              validationMsg={state[FieldName.LastName].validationMsg}
              isInvalid={state[FieldName.LastName].isInvalid}
            />
          </Grid>

          <Grid className={styles.modal_input}>
            <Input
              label="Електронна адреса"
              type="email"
              placeholder="l.kostenko@gmail.com"
              value={state[FieldName.Email].value}
              onChange={handleChange(FieldName.Email)}
              onBlur={handleValidation(FieldName.Email)}
              isInvalid={state[FieldName.Email].isInvalid}
              validationMsg={state[FieldName.Email].validationMsg}
            />
          </Grid>
          <InputLabel className={styles.modal_label}>Роль</InputLabel>
          <Select value={role} className={styles.modal_role} onChange={handleRoleChange}>
            {userRoles.map((userRole) => (
              <MenuItem key={userRole.value} value={userRole.value}>
                {userRole.option}
              </MenuItem>
            ))}
          </Select>
          <MainButton placeholder="Надіслати" cn={styles.btn_gap} onClick={handleAddAdmin} disabled={isLoginValid()} />
        </Grid>
      </Grid>
    </Modal>
  );
}
