import { actionTypes } from './types';

export const validateSecurityToken = (email: string | null, securityToken: string | null) => ({
  type: actionTypes.VALIDATE_SECURITY_TOKEN,
  payload: { email, securityToken },
});

export const resetPassword = (
  email: string | null,
  securityToken: string | null,
  password: string,
  passwordConfirm: string
) => ({
  type: actionTypes.RESET_PASSWORD,
  payload: { email, securityToken, password, passwordConfirm },
});
