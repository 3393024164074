import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import { ICategoryState } from '../reducers/category';
import { IEditCategoryState } from '../pages/FAQ/Category/CategoryReducer/editCategoryReducer';

const getCategoryByNameFAQ = (state: IRootState) => state.categories;

export const selectCategory = createSelector(getCategoryByNameFAQ, (categoryState: ICategoryState) => ({
  ...categoryState,
}));

const editCategory = (state: IRootState) => state.selectedCategory;

export const editSelectedCategory = createSelector(editCategory, (changeCategory: IEditCategoryState) => ({
  ...changeCategory,
}));
