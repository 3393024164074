import { GridColDef } from '@mui/x-data-grid';
import { dateFormatterForNotifications } from '../../../../utils/dates/formatDateHelpers';

export const plannedTableColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Назва',
    flex: 3,
    headerAlign: 'center',
    align: 'center',
  },

  {
    field: 'categoryId',
    headerName: 'Категорія',
    flex: 2,
    headerAlign: 'center',
    align: 'center',
  },

  {
    field: 'shortDescription',
    headerName: 'Короткий опис',
    flex: 4,
    headerAlign: 'center',
    align: 'center',
  },

  {
    field: 'notificationDistribution',
    headerName: 'Заплановано на',
    flex: 2,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: dateFormatterForNotifications,
  },
];
