import { IAddCategory, IFilterCategory } from '../actions/category';
import { authorizedInstance } from './axiosInstances';

enum APIUrl {
  Category = '/faqs/category',
  Categories = '/faqs/categories',
}

export const getCategoriesByNameFAQ = (payload: IFilterCategory ) => 
  authorizedInstance.post(APIUrl.Categories, payload);

export const getCategoriesFAQ = () => authorizedInstance.get(APIUrl.Categories);

export const addCategoryFAQ = (payload: IAddCategory) => authorizedInstance.post(APIUrl.Category, payload);

export const editCategoryFAQ = (payload: IAddCategory) => authorizedInstance.put(APIUrl.Category, payload);

export const removeCategoryFAQ = (payload: number) => authorizedInstance.delete(`${APIUrl.Categories}/${payload}`);
