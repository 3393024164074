import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid, Modal } from '@material-ui/core';
import _ from 'lodash';
import AuthorInfo from 'src/components/AuthorInfo';
import { ModalButtons } from './components/ModalButtons';
import { ImageComponent } from './components/ImageComponent';
import { SelectComponent } from './components/SelectComponent';
import { MultiSelectComponent } from './components/MultiSelectComponent';
import { TextEditor } from '../../../../components/TextEditor/TextEditor';
import { TextEditField, EditFieldWrapper } from '../../../../components/Fields';
import { FieldNames, MaxLength } from './constants';
import { getRequiredFieldMessage } from '../../../../utils/formFields/fieldValitationHelper';
import { ReactComponent as Cross } from '../../../../assets/images/Cross.svg';
import { useConfirmReloadPage } from '../../../../hooks/useConfirmReloadPage';
import { Option, IDataOption } from '../Models/Option';
import { useActions } from '../../../../hooks/useActions';
import { Question as QuestionModel, Question } from '../Models/Question';
import { useFlag } from '../../../../hooks/useFlag';
import { IRootState } from '../../../../reducers';
import { IQuestions } from '../../../../reducers/questions';
import { ICategoryState } from '../../../../reducers/category';
import { checkValidationForm } from './utils/validation';
import { SubmitData } from '../Models/SubmitData';
import { Label } from '../Config';

import style from './index.module.scss';
import ConfirmationModal from '../../../../components/ConfirmationModal';

interface IProps {
  defaultValues: QuestionModel;
  onCloseModal: () => void;
}

export const QuestionModal = ({ defaultValues, onCloseModal }: IProps) => {
  const [formData, setFormData] = useState<Question>(new Question(defaultValues));
  const [fieldsWithError, setFieldsWithError] = useState<string[] | undefined>(undefined);
  const [isChanged, setIsChanged] = useState(false);
  const [questionsAsOptions, setQuestions] = useState<Option[]>([]);
  const { addNewQuestion, getCategoriesFAQ, updateQuestion } = useActions();
  const { questions, isLoading: loadingQuestions } = useSelector<IRootState, IQuestions>(
    (state: IRootState) => state.questions
  );
  const { categories, isLoading: loadingCategories } = useSelector<IRootState, ICategoryState>(
    (state: IRootState) => state.categories
  );
  const [visible] = useFlag();
  const [confirmationModalVisible, openConfirmationModal, closeConfirmationModal] = useFlag();
  const imageHintMessage = 'Формат зображення повинен бути JPG, GIF, чи PNG і розмір не більшим 3 Mb';
  useConfirmReloadPage(isChanged);

  useEffect(() => {
    getCategoriesFAQ();
    setQuestions(questions.map((item) => new Option(item as IDataOption)));
  }, []);

  useEffect(() => {
    if (fieldsWithError) {
      setFieldsWithError(checkValidationForm(formData));
    }
  }, [formData]);

  const handleChangeData = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.id]: event.target.value });
  };

  const handleChangeImage = (value: string) => {
    setFormData({ ...formData, [FieldNames.picture]: value });
  };

  const handleChangeSelect = (value: { [key: string]: Option | Option[] | undefined | null }) => {
    setFormData({ ...formData, ...value });
  };

  const handleChangeTextEditor = (value: string) => {
    setFormData({ ...formData, [FieldNames.answer]: value });
  };

  const checkValidation = () => {
    const invalidFields = checkValidationForm(formData);
    setFieldsWithError(invalidFields);
    return !invalidFields.length;
  };

  const handleSave = (isDraft: boolean) => () => {
    if (checkValidation()) {
      (formData.id ? updateQuestion : addNewQuestion)(new SubmitData({ ...formData, isDraft }));
      onCloseModal();
    }
  };

  const onCloseModals = () => {
    closeConfirmationModal();
    onCloseModal();
  };

  const checkAnswerField = () => {
    if (formData.answer) {
      const formAnswer = JSON.parse(formData.answer);
      const defaultAnswer = JSON.parse(defaultValues.answer);
      if (formAnswer.blocks[0].text !== defaultAnswer.blocks[0].text) return false;
    }
    return true;
  };

  const isEqualForm = () =>
    _.isEqual(defaultValues.category, formData.category) &&
    checkAnswerField() &&
    defaultValues.question === formData.question &&
    defaultValues.picture === formData.picture &&
    _.isEqual(defaultValues.relatedQuestions, formData.relatedQuestions);

  useEffect(() => {
    setIsChanged(!isEqualForm());
  }, [formData]);

  const handleCloseModal = () => {
    if (isEqualForm()) {
      onCloseModals();
    }
    openConfirmationModal();
  };

  return (
    <>
      <Modal open={!visible} onClose={handleCloseModal} className={style.modal}>
        <Box className={style.container}>
          <Grid className={style.modal_body}>
            <Grid className={style.modal_body_content}>
              <Cross className={style.cross} onClick={handleCloseModal} />

              <h3 className={style.modal_text}>{formData.id ? Label.EditQuestion : Label.AddNewQuestion}</h3>

              <EditFieldWrapper fieldLabel={Label.Category} required>
                <SelectComponent
                  options={categories}
                  loading={loadingCategories}
                  onValueChange={handleChangeSelect}
                  defaultValue={formData.category}
                  fieldId={FieldNames.category}
                  startValidate={!!fieldsWithError?.length}
                  maxLength={MaxLength.category}
                />
              </EditFieldWrapper>

              <TextEditField
                onChange={handleChangeData}
                fieldId={FieldNames.question}
                fieldLabel={Label.Question}
                defaultValue={formData.question}
                disabled={false}
                validators={[getRequiredFieldMessage]}
                startValidate={!!fieldsWithError?.length}
                maxLength={MaxLength.question}
                required
              />

              <TextEditor
                fieldLabel={Label.Answer}
                onChange={handleChangeTextEditor}
                startValidate={!!fieldsWithError?.length}
                defaultValue={formData.answer}
                maxLength={MaxLength.answer}
                required
              />

              <EditFieldWrapper fieldLabel={Label.Picture}>
                <ImageComponent
                  onChange={handleChangeImage}
                  value={formData[FieldNames.picture]}
                  startValidate={false}
                  hintMessage={imageHintMessage}
                />
              </EditFieldWrapper>

              <EditFieldWrapper fieldLabel={Label.RelatedQuestion}>
                <MultiSelectComponent
                  options={questionsAsOptions}
                  loading={loadingQuestions}
                  onValueChange={handleChangeSelect}
                  defaultValue={formData?.relatedQuestions}
                  fieldId={FieldNames.relatedQuestions}
                  startValidate={false}
                />
              </EditFieldWrapper>

              {formData.id && (
                <AuthorInfo
                  isEditMode
                  authorInfo={{
                    createDate: formData?.created,
                    updateDate: formData?.updated,
                    lastUpdatedBy: formData?.lastUpdatedByName,
                    createdBy: formData?.createdByName,
                  }}
                />
              )}

              <ModalButtons
                formData={formData}
                handleSave={handleSave}
                onCloseModal={handleCloseModal}
                disabledMain={!!fieldsWithError?.length}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ConfirmationModal
        title="Вихід"
        isOpen={confirmationModalVisible}
        leftButtonLabel="Cкасувати"
        rightButtonLabel="Вихід"
        onClose={closeConfirmationModal}
        onCloseAllModals={onCloseModals}
      >
        <>
          <p>Ви дійсно бажаєте вийти без збереження змін?</p>
          <p>Усі внесені дані не буде збереженно.</p>
        </>
      </ConfirmationModal>
    </>
  );
};
