import { SubmitData } from '../pages/FAQ/Question/Models/SubmitData';
import { actionTypes } from './types';

export interface IFilteredQuestionLis {
  categoryId?: number;
  isDraft?: boolean;
  createdBy?: string;
  search?: string;
}

export const getQuestionList = () => ({
  type: actionTypes.GET_QUESTIONS_LIST,
});

export const getCategoryList = () => ({
  type: actionTypes.GET_FAQ_CATEGORY_LIST,
});

export const addNewQuestion = (newQuestion: SubmitData) => ({
  type: actionTypes.ADD_NEW_QUESTION,
  payload: newQuestion,
});

export const getFilteredQuestionList = (filter: IFilteredQuestionLis) => ({
  type: actionTypes.GET_FILTER_QUESTIONS_LIST,
  payload: filter,
});

export const getSearchQuestion = (search: string) => ({
  type: actionTypes.GET_SEARCH_QUESTIONS,
  payload: search,
});

export const updateQuestion = (Question: SubmitData) => ({
  type: actionTypes.UPDATE_QUESTION,
  payload: Question,
});

export const deleteQuestion = (id: number) => ({
  type: actionTypes.DELETE_QUESTION,
  payload: id,
});
