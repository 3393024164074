interface IOption {
  name: string;
  value: string | number;
}

export const optionNameRenderer = (options: IOption[]) => (params: any) => {
  const option = options.find((item: IOption) => item.value === params.row.status || item.value === params.row.role[0]);

  if (!option) {
    return '';
  }

  return option.name;
};

export const fullNameRenderer = (params: any) => `${params.row.firstName || ''} ${params.row.lastName || ''}`;

export const phoneRenderer = (params: any) => `${params.row.phoneNumber || ''}`;
