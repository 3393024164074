import { uniqueId } from 'lodash';
import { actionTypes, IAction } from '../actions/types';

export interface INotification {
  id: string;
  message: string;
  options: any;
  dismissed: boolean;
}

export const notificationsReducer = (state: INotification[] = [], action: IAction) => {
  switch (action.type) {
    case actionTypes.SHOW_NOTIFICATION:
      return [
        ...state,
        {
          id: uniqueId(),
          ...action.payload,
        },
      ];
    case actionTypes.HIDE_NOTIFICATION:
      return state.filter((notification: any) => notification.id !== action.payload.id);

    default:
      return state;
  }
};
