import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import LinkTab from '../../components/LinkTab';
import style from './index.module.scss';
import PlannedNotifications from './ComponentsReminder/PlannedNotifications/PlannedNotifications';
import PastNotifications from './ComponentsReminder/PastNotifications/PastNotifications';
import AutomatedNotifications from './ComponentsReminder/AutomatedNotifications/AutomatedNotifications';

const Reminder = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <div className={style.tabs}>
        <LinkTab placeholder="Заплановані" path={`${path}/scheduled`} />
        <LinkTab placeholder="Минулі" path={`${path}/last`} />
        <LinkTab placeholder="Автоматизовані" path={`${path}/automated`} />
      </div>
      <div className={style.table}>
        <Switch>
          <Redirect exact from={`${path}`} to={`${path}/scheduled`} />
          <Route exact path={`${path}/scheduled`} component={PlannedNotifications} />
          <Route exact path={`${path}/last`} component={PastNotifications} />
          <Route exact path={`${path}/automated`} component={AutomatedNotifications} />
        </Switch>
      </div>
    </>
  );
};

export default Reminder;
