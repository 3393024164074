import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import { IDonorsState } from '../reducers/donors';

const getDonors = (state: IRootState) => state.donors;

export const selectDonors = createSelector(getDonors, (donorsState: IDonorsState) => ({
  ...donorsState,
  donors: donorsState.donors.map((donor) => ({
    ...donor,
    id: donor.email,
  })),
}));
