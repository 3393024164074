import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { getUser } from 'src/api/users';
import { showNotification } from '../actions/notifications';
import { getSuccessAction, actionTypes, getFailAction, IAction } from '../actions/types';

import { getPushNotifications as getPushNotificationsAction } from '../actions/pushNotifications';
import {
  getPushNotifications,
  addPushNotification,
  deletePushNotification,
  editPushNotification,
  cancelPushNotification,
  getPushNotificationCategories,
  getPushNotificationTriggers,
} from '../api/pushNotifications';

export function* getPushNotificationsSaga() {
  try {
    const { data } = yield call(getPushNotifications);
    yield put({
      type: getSuccessAction(actionTypes.GET_PUSH_NOTIFICATIONS),
      payload: data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));
    yield put({
      type: getFailAction(actionTypes.GET_PUSH_NOTIFICATIONS),
      error,
    });
  }
}

export function* addPushNotificationSaga(action: IAction) {
  try {
    const result: AxiosResponse = yield call(addPushNotification, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.ADD_PUSH_NOTIFICATION),
      payload: result.data,
    });
    yield put(getPushNotificationsAction());
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));
    yield put({
      type: getFailAction(actionTypes.ADD_PUSH_NOTIFICATION),
      error,
    });
  }
}

export function* editPushNotificationSaga(action: IAction) {
  try {
    const result: AxiosResponse = yield call(editPushNotification, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.EDIT_PUSH_NOTIFICATION),
      payload: result.data,
    });
    yield put(getPushNotificationsAction());
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));
    yield put({
      type: getFailAction(actionTypes.EDIT_PUSH_NOTIFICATION),
      error,
    });
  }
}

export function* cancelPushNotificationSaga(action: IAction) {
  try {
    const result: AxiosResponse = yield call(cancelPushNotification, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.CANCEL_PUSH_NOTIFICATION),
      payload: result.data,
    });
    yield put(getPushNotificationsAction());
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));
    yield put({
      type: getFailAction(actionTypes.CANCEL_PUSH_NOTIFICATION),
      error,
    });
  }
}

export function* deletePushNotificationSaga(action: IAction) {
  try {
    const result: AxiosResponse = yield call(deletePushNotification, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.DELETE_PUSH_NOTIFICATION),
      payload: result.data,
    });
    yield put(getPushNotificationsAction());
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));
    yield put({
      type: getFailAction(actionTypes.DELETE_PUSH_NOTIFICATION),
      error,
    });
  }
}

export function* getPushNotificationCategoriesSaga() {
  try {
    const { data } = yield call(getPushNotificationCategories);
    yield put({
      type: getSuccessAction(actionTypes.GET_PUSH_NOTIFICATION_CATEGORIES),
      payload: data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.GET_PUSH_NOTIFICATION_CATEGORIES),
      error,
    });
  }
}

export function* getPushNotificationTriggersSaga() {
  try {
    const { data } = yield call(getPushNotificationTriggers);
    yield put({
      type: getSuccessAction(actionTypes.GET_PUSH_NOTIFICATION_TRIGGERS),
      payload: data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.GET_PUSH_NOTIFICATION_TRIGGERS),
      error,
    });
  }
}

export function* getPushNotificationCreatedBySaga(action: IAction) {
  try {
    const { data } = yield call(getUser, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.GET_PUSH_NOTIFICATION_CREATED_BY),
      payload: data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));
    yield put({
      type: getFailAction(actionTypes.GET_PUSH_NOTIFICATION_CREATED_BY),
      error,
    });
  }
}

export function* getPushNotificationUpdatedBySaga(action: IAction) {
  try {
    const { data } = yield call(getUser, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.GET_PUSH_NOTIFICATION_UPDATED_BY),
      payload: data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));
    yield put({
      type: getFailAction(actionTypes.GET_PUSH_NOTIFICATION_UPDATED_BY),
      error,
    });
  }
}

export function* watchGetPushNotifications() {
  yield takeLatest(actionTypes.GET_PUSH_NOTIFICATIONS, getPushNotificationsSaga);
}

export function* watchAddPushNotifications() {
  yield takeLatest(actionTypes.ADD_PUSH_NOTIFICATION, addPushNotificationSaga);
}
export function* watchEditPushNotifications() {
  yield takeLatest(actionTypes.EDIT_PUSH_NOTIFICATION, editPushNotificationSaga);
}

export function* watchCancelPushNotification() {
  yield takeLatest(actionTypes.CANCEL_PUSH_NOTIFICATION, cancelPushNotificationSaga);
}

export function* watchDeletePushNotification() {
  yield takeLatest(actionTypes.DELETE_PUSH_NOTIFICATION, deletePushNotificationSaga);
}

export function* watchGetPushNotificationCategories() {
  yield takeLatest(actionTypes.GET_PUSH_NOTIFICATION_CATEGORIES, getPushNotificationCategoriesSaga);
}

export function* watchGetPushNotificationTriggers() {
  yield takeLatest(actionTypes.GET_PUSH_NOTIFICATION_TRIGGERS, getPushNotificationTriggersSaga);
}

export function* watchGetPushNotificationCreatedBySaga() {
  yield takeLatest(actionTypes.GET_PUSH_NOTIFICATION_CREATED_BY, getPushNotificationCreatedBySaga);
}

export function* watchGetPushNotificationUpdatedBySaga() {
  yield takeLatest(actionTypes.GET_PUSH_NOTIFICATION_UPDATED_BY, getPushNotificationUpdatedBySaga);
}
