import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Grid, IconButton } from '@material-ui/core';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import Popup from '../../../../../components/Popover';
import { ICategoryUploadProps } from './CategotyUpload.constants';
import { getImgValidationMsg } from '../../../../../utils/formFields/fieldValitationHelper';
import { editSelectedCategory } from '../../../../../selectors/category';

import style from './index.module.scss';

export const CategoryUpload = ({ onImageChange }: ICategoryUploadProps) => {
  const { selectedCategory } = useSelector(editSelectedCategory);

  const [imageFile, setImageFile] = useState<string>(selectedCategory?.picture || '');

  const anchorEl = React.useRef<HTMLElement | null>(null);
  const [, setMouseOver] = useState(false);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMouseOver(true);
    anchorEl.current = event.currentTarget;
  };
  const handlePopoverClose = () => {
    setMouseOver(false);
    anchorEl.current = null;
  };
  const open = Boolean(anchorEl?.current);

  const imageInput = useRef<HTMLInputElement>(null);
  const onClickButton = () => imageInput.current?.click();

  const handleSelectImage = (event: any) => {
    const image = event.target?.files[0];
    const validationMsg = getImgValidationMsg(image);

    if (!validationMsg) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result !== null) {
          setImageFile(String(reader.result));
          onImageChange(String(reader.result));
        }
      };
      reader.readAsDataURL(image);
    } else {
      setImageFile('');
    }
  };

  return (
    <Grid item xs={9}>
      <Grid container alignItems="center">
        <Grid item>
          <Avatar
            src={imageFile}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          />
        </Grid>
        <Grid item>
          <IconButton color="primary" aria-label="upload picture" component="span" onClick={onClickButton}>
            <DataSaverOnIcon />
            <input
              style={{ display: 'none' }}
              type="file"
              accept="image/png, image/jpeg, image/gif"
              onChange={handleSelectImage}
              ref={imageInput}
            />
          </IconButton>
        </Grid>
        <Grid item className={style.requirements_text}>
          Формат зображення повинен бути JPG, GIF чи PNG і розмір не більшим 3 Mb
        </Grid>
      </Grid>
      {imageFile.length !== 0 && (
        <Popup
          idPopover="mouse-over-popover"
          widthPopover="80%"
          heightPopover="80%"
          openPopover={open}
          anchorElement={anchorEl?.current}
          onClosePopover={handlePopoverClose}
          image={imageFile}
        />
      )}
    </Grid>
  );
};
