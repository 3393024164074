import React from 'react';
import { GridColDef } from '@mui/x-data-grid';

import { Icon } from 'src/components/Icon';
import { dateFormatter } from 'src/utils/dates/formatDateHelpers';

export const categoryTableColumns: GridColDef[] = [
  {
    field: 'picture',
    headerName: 'Зображення',
    width: 200,
    sortable: true,
    renderCell: (params) => <Icon iconData={params.row.picture} alt="Icon Donation Type" />,
  },
  {
    field: 'name',
    headerName: 'Категорія',
    width: 200,
    sortable: true,
  },
  {
    field: 'faqArticlesCount',
    headerName: 'Кількість питань в категорії',
    width: 250,
    sortable: true,
  },
  {
    field: 'updated',
    headerName: 'Оновлено',
    width: 200,
    sortable: true,
    valueFormatter: dateFormatter,
  },
  {
    field: 'createdByName',
    headerName: 'Автор',
    width: 200,
    sortable: true,
  },
];
