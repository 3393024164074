import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { dateFormatter } from '../../../../utils/dates/formatDateHelpers';

import { Label } from './constants';
import { ResultToBold } from '../FilterQuestion/resultToBold';

export const questionTableColumns: GridColDef[] = [
  {
    field: 'question',
    headerName: Label.Question,
    width: 300,
    sortable: true,
    renderCell: (params) => <ResultToBold question={params.value} />,
  },
  {
    field: 'category',
    headerName: Label.Category,
    width: 200,
    sortable: true,
    valueFormatter: ({ value }) => (value as any).name,
  },
  {
    field: 'isDraft',
    headerName: Label.Status,
    width: 200,
    sortable: true,
    valueFormatter: ({ value }) => (value ? Label.Draft : Label.Published),
  },
  {
    field: 'updated',
    headerName: Label.Updated,
    width: 200,
    sortable: true,
    valueFormatter: dateFormatter,
  },
  {
    field: 'createdByName',
    headerName: Label.Author,
    width: 200,
    sortable: true,
  },
];
