import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';

export interface IAdminState {
  isLoading: boolean;
  email: null;
  error: any;
}

const initialAdminState: IAdminState = {
  isLoading: false,
  email: null,
  error: null,
};

export const addAdminReducer = (state = initialAdminState, action: IAction) => {
  switch (action.type) {
    case actionTypes.ADD_ADMIN:
      return {
        ...state,
        isLoading: null,
        error: null,
      };
    case getSuccessAction(actionTypes.ADD_ADMIN):
      return {
        ...state,
        email: action.payload,
        isLoading: false,
      };
    case getFailAction(actionTypes.ADD_ADMIN):
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const blockAdminReducer = (state = initialAdminState, action: IAction) => {
  switch (action.type) {
    case actionTypes.BLOCK_ADMIN:
      return {
        ...state,
        isLoading: null,
        error: null,
      };
    case getSuccessAction(actionTypes.BLOCK_ADMIN):
      return {
        ...state,
        email: action.payload,
        isLoading: false,
      };
    case getFailAction(actionTypes.BLOCK_ADMIN):
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const unblockAdminReducer = (state = initialAdminState, action: IAction) => {
  switch (action.type) {
    case actionTypes.UNBLOCK_ADMIN:
      return {
        ...state,
        isLoading: null,
        error: null,
      };
    case getSuccessAction(actionTypes.UNBLOCK_ADMIN):
      return {
        ...state,
        email: action.payload,
        isLoading: false,
      };
    case getFailAction(actionTypes.UNBLOCK_ADMIN):
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

export const resetAdminReducer = (state = initialAdminState, action: IAction) => {
  switch (action.type) {
    case actionTypes.RESET_ADMIN:
      return {
        ...state,
        isLoading: null,
        error: null,
      };
    case getSuccessAction(actionTypes.RESET_ADMIN):
      return {
        ...state,
        email: action.payload,
        isLoading: false,
      };
    case getFailAction(actionTypes.RESET_ADMIN):
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};
