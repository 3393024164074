import React from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import style from '../Users/TabsUsers/index.module.scss';
import LinkTab from '../../components/LinkTab';
import PrivacyPolicy from './PrivacyPolicy';

const OtherInfo = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <div className={style.tabs}>
        <LinkTab placeholder="Privacy policy" path={`${path}/privacypolicy`} />
      </div>

      <div className={style.table}>
        <Switch>
          <Redirect exact from={`${path}`} to={`${path}/privacypolicy`} />
          <Route path={`${path}/privacypolicy`} component={PrivacyPolicy} />
        </Switch>
      </div>
    </>
  );
};
export default OtherInfo;
