import React, { useEffect } from 'react';
import { Redirect, Route, RouteProps, useHistory } from 'react-router-dom';
import { getLs } from '../../constants/encryptConf';
import { LocalStorageKeys as LSKeys } from '../../constants/local-storage-keys';

const PrivateRoute = ({ children, ...routeProps }: RouteProps) => {
  const history = useHistory();

  const expirationTime = 3600000;
  const timeStamp = `${localStorage.getItem(LSKeys.TimeStamp)}`;

  useEffect(() => {
    let logoutTimeout: ReturnType<typeof setTimeout>;

    const timeDifference = timeStamp === LSKeys.Forever ? 0 : Date.now() - Date.parse(timeStamp);

    const logOutWhenTokenExpired = () => {
      if (timeDifference < expirationTime) {
        logoutTimeout = setTimeout(logOutWhenTokenExpired, expirationTime - timeDifference);
      } else {
        localStorage.clear();
        history.push('/login');
      }
    };

    logOutWhenTokenExpired();

    return () => {
      clearTimeout(logoutTimeout);
    };
  });

  return getLs(LSKeys.Token) ? <Route {...routeProps}>{children}</Route> : <Redirect to="/login" />;
};

export default PrivateRoute;
