import { IDonationType } from 'src/interfaces';
import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';

export interface IDonationTypesListState {
  isLoading: boolean;
  donationTypes: IDonationType[];
  error: any;
}

export const initialState: IDonationTypesListState = {
  isLoading: false,
  donationTypes: [],
  error: null,
};

export const donationTypesReducer = (state = initialState, { type, payload, error }: IAction) => {
  switch (type) {
    case actionTypes.GET_DONATION_TYPES:
      return {
        ...state,
        isLoading: !state.donationTypes.length,
        error: null,
      };

    case getSuccessAction(actionTypes.GET_DONATION_TYPES):
      return {
        ...state,
        donationTypes: payload,
        isLoading: false,
      };
    case getSuccessAction(actionTypes.ADD_DONATION_TYPE):
      return {
        ...state,
        donationTypes: [...state.donationTypes, payload],
        error: null,
      };
    case getSuccessAction(actionTypes.EDIT_DONATION_TYPE):
      return {
        ...state,
        donationTypes: [...state.donationTypes].map((item) => (item.id === payload.id ? payload : item)),
        error: null,
      };
    case getSuccessAction(actionTypes.CLEAR_DONATION_TYPE):
      return {
        ...state,
        donationTypes: [...state.donationTypes].filter((item) => item.id !== payload.id),
        error: null,
      };

    case getFailAction(actionTypes.GET_DONATION_TYPES):
    case getFailAction(actionTypes.ADD_DONATION_TYPE):
    case getFailAction(actionTypes.EDIT_DONATION_TYPE):
    case getFailAction(actionTypes.CLEAR_DONATION_TYPE):
      return { ...state, error, isLoading: false };

    default:
      return state;
  }
};
