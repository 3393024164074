import React from 'react';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { EditRounded } from '@material-ui/icons';
import { statusOptions } from '../Filters/DonorsFilters/optionsData';
import { fullNameRenderer, optionNameRenderer, phoneRenderer } from '../util/tableRenderers';

export const donorsTableColumns: GridColDef[] = [
  {
    field: 'fullName',
    headerName: 'Ім‘я, прізвище',
    width: 300,
    valueGetter: fullNameRenderer,
    editable: true,
  },
  { 
    field: 'phone', 
    headerName: 'Телефон', 
    width: 200, 
    valueGetter: phoneRenderer, 
    editable: true, 
  },
  { field: 'email', headerName: 'Email', width: 400, editable: true },
  {
    field: 'status',
    headerName: 'Статус',
    width: 200,
    valueGetter: optionNameRenderer(statusOptions),
  },
  {
    field: '',
    headerName: '',
    width: 300,
    renderCell: (params: GridCellParams) => <EditRounded style={{ display: 'none' }} htmlColor="#828282" />,
  },
];
