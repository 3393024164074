import { createSelector } from 'reselect';
import { IRootState } from '../reducers';
import { IPushNotificationsListState } from '../reducers/pushNotification';

const getPushNotifications = (state: IRootState) => state.pushNotifications;

export const selectPushNotifications = createSelector(
  getPushNotifications,
  (pushNotificationsState: IPushNotificationsListState) => pushNotificationsState
);
