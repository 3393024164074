import { GridColDef } from '@mui/x-data-grid';
import { dateFormatterTimeLeft } from 'src/utils/dates/formatDateHelpers';

export const automatedTableColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Назва',
    flex: 3,
    headerAlign: 'center',
    align: 'center',
  },

  {
    field: 'categoryId',
    headerName: 'Категорія',
    flex: 2,
    headerAlign: 'center',
    align: 'center',
  },

  {
    field: 'shortDescription',
    headerName: 'Короткий опис',
    flex: 4,
    headerAlign: 'center',
    align: 'center',
  },

  {
    field: 'trigger',
    headerName: 'Тригер',
    flex: 2,
    headerAlign: 'center',
    align: 'center',
  },

  {
    field: 'notificationDistribution',
    headerName: 'Час надсилання',
    flex: 2,
    headerAlign: 'center',
    align: 'center',
    valueFormatter: dateFormatterTimeLeft,
  },
  {
    field: 'isDisabled',
    headerName: 'Статус',
    flex: 2,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => params.row.isDisabled ? 'Вимкнено' : 'Активовано',
  },
];
