import axios, { AxiosResponse } from 'axios';
import { getLs, setLs } from '../constants/encryptConf';
import { LocalStorageKeys as LSKeys } from '../constants/local-storage-keys';

interface AuthorizationResponse {
  token: string;
  refreshToken: string;
}

const { protocol, hostname, port } = window.location;
const apiURL = process.env.REACT_APP_API_URL;
const currentUrl = `${protocol}//${hostname}:${port}/api/v1`;

axios.defaults.baseURL = apiURL || currentUrl;
axios.defaults.responseType = 'json';

export const unauthorizedInstance = axios.create();
export const authorizedInstance = axios.create();

authorizedInstance.interceptors.request.use(
  (config) => {
    const token = getLs(LSKeys.Token);

    if (token) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

authorizedInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;
      const token = getLs(LSKeys.Token);
      const refreshToken = getLs(LSKeys.RefreshToken);

      return authorizedInstance
        .post<AuthorizationResponse>('/user/refresh', { token, refreshToken })
        .then((response: AxiosResponse<AuthorizationResponse>) => {
          setLs(LSKeys.Token, response.data.token);
          setLs(LSKeys.RefreshToken, response.data.refreshToken);
          localStorage.setItem(LSKeys.TimeStamp, `${new Date()}`);

          return authorizedInstance(originalRequest);
        })
        .catch((refreshErr) => {
          if (refreshErr.response.status === 400) {
            document.location.href = '/login';
            localStorage.setItem(LSKeys.RedirectedLoginPage, `true`);
          }

          return Promise.reject(refreshErr);
        });
    }

    return Promise.reject(error);
  }
);
