import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, TextField, Modal, Box } from '@material-ui/core';
import ConfirmationModal from 'src/components/ConfirmationModal';
import AuthorInfo from 'src/components/AuthorInfo';
import { ErrorMessages } from 'src/utils/formFields/formFields.constants';
import { selectPushNotificationsModal } from 'src/selectors/pushNotificationsModal';
import { EditFieldWrapper } from '../../../../components/Fields';
import { ReactComponent as Cross } from '../../../../assets/images/Cross.svg';
import style from './index.module.scss';
import DateDropdown from '../../../../components/DateDropdown';
import Dropdown from '../../../../components/Dropdown';
import { selectPushNotifications } from '../../../../selectors/pushNotifications';
import { usePushNotification } from '../../hook/usePushNotification';
import { FieldNames, ReminderTypes } from '../../constants';
import { useActions, useFlag } from '../../../../hooks';
import { ActionButtons } from '../../ActionButtons';

interface IScheduledModalProps {
  onCloseModal: () => void;
  defaultValues: any;
  isOpen: boolean;
}

const isReminderTypeScheduled = true;

const ScheduledModal = ({ onCloseModal, defaultValues, isOpen }: IScheduledModalProps) => {
  const { pushNotificationCategories } = useSelector(selectPushNotifications);
  const [isDelete, setIsDelete] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteModalVisible, openDeleteModal, closeDeleteModal] = useFlag();
  const [cancelModalVisible, openCancelModal, closeCancelModal] = useFlag();

  const { createdBy, updatedBy } = useSelector(selectPushNotificationsModal);

  const [error, setError] = useState<boolean>(false);

  const { getCreatedBy, getUpdatedBy } = useActions();
  const {
    handleDataChange,
    handleCategoryChange,
    handleDistributionChange,
    handleSaveModal,
    handleDeletePushNotification,
    handleCancelPushNotification,
    isSaveValid,
    formData,
    hasChanged,
    authorInfo,
  } = usePushNotification({
    defaultValues,
    type: ReminderTypes.Scheduled,
    onCloseModal,
    isEditMode,
    pushNotificationCategories,
    createdBy,
    updatedBy,
  });

  useEffect(() => {
    if (defaultValues) {
      setIsEditMode(true);
      getCreatedBy(defaultValues.createdById);
      getUpdatedBy(defaultValues.lastUpdatedById);
    }
  }, [defaultValues]);

  useEffect(() => {
    if (
      formData.name.length >= 35 ||
      formData.shortDescription.length >= 65 ||
      formData.longDescription.length >= 200
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [formData]);

  const checkIsStateChanged = () => {
    if (!hasChanged) {
      onCloseModal();
    }
  };

  const handleDeleteBtn = () => {
    setIsDelete(true);
    openDeleteModal();
  };

  const handleCloseDeleteModal = () => {
    setIsDelete(false);
    closeDeleteModal();
  };

  const handleCancelBtn = () => {
    setIsCancel(true);
    openCancelModal();
  };

  const handleCloseCancelModal = () => {
    setIsCancel(false);
    closeCancelModal();
  };

  const getHelperText = (text: string, maxLength: number) => {
    if (text.length >= maxLength) {
      return <span style={{ color: 'red' }}>{ErrorMessages.OversizeText + maxLength}</span>;
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '-10px' }}>
        <p>{text.length}</p>
        /
        <p>{maxLength}</p>
      </div>
    );
  };

  return (
    <>
      <Modal open={isOpen} onClose={checkIsStateChanged} className={style.modal}>
        <Box className={style.container}>
          <Grid className={style.modal_body}>
            <Grid className={style.modal_body_content}>
              <Cross className={style.cross} onClick={onCloseModal} />

              <h3 className={style.modal_text}>{formData.id ? 'Редагувати нагадування' : 'Додати нагадування'}</h3>

              <EditFieldWrapper required fieldLabel="Назва">
                <TextField
                  id={FieldNames.Name}
                  fullWidth
                  variant="outlined"
                  value={formData.name}
                  onChange={handleDataChange}
                  required
                  placeholder="Введіть дані..."
                  helperText={getHelperText(formData.name, 35)}
                  inputProps={{ maxLength: 40 }}
                />
              </EditFieldWrapper>

              <EditFieldWrapper required fieldLabel="Категорія">
                <Dropdown
                  data={pushNotificationCategories}
                  value={formData.categoryId}
                  onChange={handleCategoryChange}
                />
              </EditFieldWrapper>

              <EditFieldWrapper required fieldLabel="Короткий опис">
                <TextField
                  id={FieldNames.ShortDescription}
                  variant="outlined"
                  placeholder="Введіть дані"
                  fullWidth
                  multiline
                  value={formData.shortDescription}
                  onChange={handleDataChange}
                  helperText={getHelperText(formData.shortDescription, 65)}
                  inputProps={{ maxLength: 70 }}
                />
              </EditFieldWrapper>

              <EditFieldWrapper required fieldLabel="Довгий опис">
                <TextField
                  id={FieldNames.LongDescription}
                  variant="outlined"
                  placeholder="Введіть дані"
                  fullWidth
                  multiline
                  margin="normal"
                  onChange={handleDataChange}
                  value={formData.longDescription}
                  helperText={getHelperText(formData.longDescription, 200)}
                  inputProps={{ maxLength: 205 }}
                />
              </EditFieldWrapper>

              <EditFieldWrapper fieldLabel="Заплановано на:">
                <DateDropdown
                  startDate={formData.notificationDistribution as Date}
                  endDate={null}
                  onDateChange={handleDistributionChange}
                  isDirty={false}
                  isRange={false}
                />
              </EditFieldWrapper>

              <AuthorInfo isEditMode authorInfo={authorInfo()} />

              <ActionButtons
                onCloseModal={onCloseModal}
                onSaveModal={handleSaveModal}
                handleDeleteBtn={handleDeleteBtn}
                handleCancelBtn={handleCancelBtn}
                isEditMode={isEditMode}
                isReminderTypeScheduled={isReminderTypeScheduled}
                disabled={isSaveValid()}
                error={error}
                formData={formData}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <ConfirmationModal
        title="Видалення"
        isOpen={deleteModalVisible}
        leftButtonLabel="Cкасувати"
        rightButtonLabel="Видалити"
        onClose={handleCloseDeleteModal}
        onCloseAllModals={isDelete ? handleDeletePushNotification : onCloseModal}
      >
        <p>Ви дійсно бажаєте видалити це нагадування?</p>
        <p>Нагадування буде переміщено до минулих.</p>
      </ConfirmationModal>
      <ConfirmationModal
        title="Скасування відправки нагадування"
        isOpen={cancelModalVisible}
        leftButtonLabel="Cкасувати"
        rightButtonLabel="Скасувати відправку"
        onClose={handleCloseCancelModal}
        onCloseAllModals={isCancel ? handleCancelPushNotification : onCloseModal}
      >
        <p>Ви дійсно бажаєте скасувати відправку цього нагадування?</p>
        <p>Нагадування буде переміщено до минулих.</p>
      </ConfirmationModal>
    </>
  );
};

export default ScheduledModal;
