import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 700,
        textTransform: 'none',
        borderRadius: 'none',
      },
    },
  },
  palette: {
    primary: {
      light: '#f4a1a1',
      main: '#ff0000',
      dark: '#f44336',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#ffffff',
      main: '#ffffff',
      dark: '#f8f8f8',
      contrastText: '#ff0000',
    },
  },
  typography: {
    fontFamily: 'Roboto',
  },
});

export default theme;
