import React, { createContext, useContext, ReactElement } from 'react';
import { useFlag } from '../../../../hooks/useFlag';

type ModalContextType = { openedModal: boolean; openModal: () => void; closeModal: () => void };
const abstractMethod = () => {
  throw Error('method must be implemented');
};
const defaultValue: ModalContextType = {
  openedModal: false,
  openModal: abstractMethod,
  closeModal: abstractMethod,
};
const QuestionModalContext = createContext<ModalContextType>(defaultValue);

export const useQuestionModal = () => useContext(QuestionModalContext);

export const QuestionModalContextProvider = ({ children }: { children: ReactElement }) => {
  const [openedModal, openModal, closeModal] = useFlag();
  const modalContext = {
    openedModal,
    openModal,
    closeModal,
  };

  return <QuestionModalContext.Provider value={modalContext}>{children}</QuestionModalContext.Provider>;
};
