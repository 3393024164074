import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { Question } from '../pages/FAQ/Question/Models/Question';

export interface IQuestions {
  questions: Question[];
  isLoading: boolean;
  error: string | null;
  search: string;
}

export const initialState: IQuestions = {
  questions: [],
  isLoading: false,
  error: null,
  search: '',
};

export const questionsReducer = (state = initialState, { type, payload, error }: IAction) => {
  switch (type) {
    case actionTypes.GET_QUESTIONS_LIST:
    case actionTypes.GET_FILTER_QUESTIONS_LIST:
      return { ...state, questions: [], isLoading: true, error: null };
    case actionTypes.ADD_NEW_QUESTION:
    case actionTypes.UPDATE_QUESTION:
    case actionTypes.DELETE_QUESTION:
      return { ...state, isLoading: true };
    case actionTypes.GET_SEARCH_QUESTIONS:
      return { ...state, search: payload, isLoading: true };

    case getSuccessAction(actionTypes.GET_QUESTIONS_LIST):
    case getSuccessAction(actionTypes.GET_FILTER_QUESTIONS_LIST):
      return { ...state, questions: payload, isLoading: false };
    case getSuccessAction(actionTypes.ADD_NEW_QUESTION):
      return { ...state, questions: [...state.questions, payload], isLoading: false };
    case getSuccessAction(actionTypes.UPDATE_QUESTION):
      return {
        ...state,
        questions: [...state.questions].map((item) => (item.id === payload.id ? payload : item)),
        isLoading: false,
      };
    case getSuccessAction(actionTypes.DELETE_QUESTION):
      return {
        ...state,
        questions: [...state.questions].filter((item) => item.id !== payload),
        isLoading: false,
      };

    case getFailAction(actionTypes.GET_QUESTIONS_LIST):
    case getFailAction(actionTypes.GET_FILTER_QUESTIONS_LIST):
    case getFailAction(actionTypes.ADD_NEW_QUESTION):
    case getFailAction(actionTypes.UPDATE_QUESTION):
    case getFailAction(actionTypes.DELETE_QUESTION):
      return { ...state, isLoading: false, error };

    default:
      return state;
  }
};
