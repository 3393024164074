import React from 'react';
import { Grid, InputLabel } from '@material-ui/core';

import styles from './index.module.scss';

interface IProps {
  fieldLabel: string;
  children: JSX.Element;
  required?: boolean;
  error?: string;
}

export const TextEditorWrapper = ({ required, fieldLabel, children, error }: IProps) => (
  <Grid container direction="row" justifyContent="center" alignItems="center">
    <Grid item xs={3} container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
      <InputLabel className={styles.label_text}>
        {fieldLabel}
        {required && <span className={styles.required}>*</span>}
      </InputLabel>
      <Grid style={{ height: '190px' }} item />
    </Grid>

    <Grid item xs={8} style={{ minHeight: '255px' }} container direction="row">
      {children}
    </Grid>

    <Grid item xs={5} container direction="row" className={styles.error_message}>
      {error}
    </Grid>
  </Grid>
);

TextEditorWrapper.defaultProps = {
  required: false,
  error: '',
};
