import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { showNotification } from '../actions/notifications';
import { getSuccessAction, actionTypes, getFailAction, IAction } from '../actions/types';
import { getDonationCenters as getDonationCentersAction } from '../actions/donationCenters';
import {
  getDonationCenters,
  addDonationCenters,
  editDonationCenters,
  clearDonationCenter,
} from '../api/donationCenters';

export function* getDonationCentersSaga() {
  try {
    const { data } = yield call(getDonationCenters);
    yield put({
      type: getSuccessAction(actionTypes.GET_DONATION_CENTERS),
      payload: data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.GET_DONATION_CENTERS),
      error,
    });
  }
}

export function* addDonationCenterSaga(action: IAction) {
  try {
    const result: AxiosResponse = yield call(addDonationCenters, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.ADD_DONATION_CENTER),
      payload: result.data,
    });

    yield put(showNotification('Центр донації успішно додано'));
    yield put(getDonationCentersAction());
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));
    yield put({
      type: getFailAction(actionTypes.ADD_DONATION_CENTER),
      error,
    });
  }
}

export function* editDonationCenterSaga(action: IAction) {
  try {
    const result: AxiosResponse = yield call(editDonationCenters, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.EDIT_DONATION_CENTER),
      payload: result.data,
    });

    yield put(showNotification('Центр донації редаговано успішно'));
    yield put(getDonationCentersAction());
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));
    yield put({
      type: getFailAction(actionTypes.EDIT_DONATION_CENTER),
      error,
    });
  }
}

export function* clearDonationCenterSaga(action: IAction) {
  try {
    const result: AxiosResponse = yield call(clearDonationCenter, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.CLEAR_DONATION_CENTER),
      payload: result.data,
    });

    yield put(showNotification('Центр донації видалено успішно'));
    yield put(getDonationCentersAction());
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));
    yield put({
      type: getFailAction(actionTypes.CLEAR_DONATION_CENTER),
      error,
    });
  }
}

export function* watchGetDonationCenters() {
  yield takeLatest(actionTypes.GET_DONATION_CENTERS, getDonationCentersSaga);
}

export function* watchAddDonationCenters() {
  yield takeLatest(actionTypes.ADD_DONATION_CENTER, addDonationCenterSaga);
}

export function* watchEditDonationCenters() {
  yield takeLatest(actionTypes.EDIT_DONATION_CENTER, editDonationCenterSaga);
}

export function* watchClearDonationCenter() {
  yield takeLatest(actionTypes.CLEAR_DONATION_CENTER, clearDonationCenterSaga);
}
