import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useCategoryModal } from '../useCategoryModal';
import { useActions } from '../../../../../hooks/useActions';
import { editSelectedCategory } from '../../../../../selectors/category';
import { ICategorySaveProps } from './CategotyUpload.constants';
import MainButton from '../../../../../components/MainBtn';

export const CategorySaveBtn = ({ name, picture, isDisabled }: ICategorySaveProps) => {
  const { closeModal } = useCategoryModal();
  const { addCategoryFAQ, editCategoryFAQ, clearCategoryInfo } = useActions();
  const { selectedCategory } = useSelector(editSelectedCategory);

  const handleClickSave = () => {
    if (!selectedCategory) {
      if (name.length && picture.length) {
        addCategoryFAQ({
          name,
          picture,
        });
      }
    } else {
      editCategoryFAQ({
        id: selectedCategory?.id,
        name,
        picture,
      });
      clearCategoryInfo();
    }
    closeModal();
  };

  return (
    <Grid style={{ margin: '0 15px' }} item xs={2}>
      <MainButton disabled={isDisabled} placeholder="Зберегти" onClick={handleClickSave} />
    </Grid>
  );
};
