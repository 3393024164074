import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';

export interface ICategory {
  id: number;
  name: string;
  picture: string;
  faqArticlesCount: number;
  created: string;
  updated: string;
  createdBy: string;
  lastUpdatedBy: string;
}

export interface ICategoryState {
  categories: ICategory[];
  isLoading: boolean;
  error: any;
}

export const initialState: ICategoryState = {
  categories: [],
  isLoading: false,
  error: null,
};

export const categoryReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORIES_FAQ:
    case actionTypes.GET_CATEGORIES_BY_NAME_FAQ:
      return {
        ...state,
        categories: [],
        isLoading: true,
        error: null,
      };
    case getSuccessAction(actionTypes.GET_CATEGORIES_FAQ):
    case getSuccessAction(actionTypes.GET_CATEGORIES_BY_NAME_FAQ):
      return {
        ...state,
        categories: action.payload,
        isLoading: false,
      };
    case getFailAction(actionTypes.GET_CATEGORIES_FAQ):
    case getFailAction(actionTypes.GET_CATEGORIES_BY_NAME_FAQ):
      return {
        ...state,
        categories: [],
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
