import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';

export interface IDonor {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  status: number;
  role: string[];
}

export interface IDonorsState {
  isLoading: boolean;
  donors: IDonor[];
  error: any;
}

const initialState: IDonorsState = {
  isLoading: false,
  donors: [],
  error: null,
};

export const donorsReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case actionTypes.GET_DONORS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case getSuccessAction(actionTypes.GET_DONORS):
      return {
        ...state,
        donors: action.payload,
        isLoading: false,
      };
    case getFailAction(actionTypes.GET_DONORS):
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};
