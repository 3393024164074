import React from 'react';
import Search from '../../../../components/Search';
import { ICategoryFiltersProps } from '../CategoryModal/components/CategotyUpload.constants';
import style from './index.module.scss';

export const CategoryFilters = ({ searchName, onFieldChange }: ICategoryFiltersProps) => (
  <div className={style.container}>
    <Search label="Пошук" value={searchName} onChange={onFieldChange} placeholder="Пошук" />
  </div>
);
