import { call, put, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { addCategoryFAQ, getCategoriesFAQ, getCategoriesByNameFAQ, editCategoryFAQ, removeCategoryFAQ } from '../api/category';
import { actionTypes, getFailAction, getSuccessAction, IAction } from '../actions/types';
import { showNotification } from '../actions/notifications';

export function* addCategoryFAQSaga(action: IAction) {
  try {
    const response: AxiosResponse = yield call(addCategoryFAQ, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.ADD_CATEGORY_FAQ),
      payload: response.data,
    });
    yield put(showNotification(`Категорію успішно додано`));
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.ADD_CATEGORY_FAQ),
      error,
    });
  }
}

export function* getCategoriesFAQSaga() {
  try {
    const response: AxiosResponse = yield call(getCategoriesFAQ);
    yield put({
      type: getSuccessAction(actionTypes.GET_CATEGORIES_FAQ),
      payload: response.data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.GET_CATEGORIES_FAQ),
      error,
    });
  }
}

export function* editCategoryFAQSaga(action: IAction) {
  try {
    const response: AxiosResponse = yield call(editCategoryFAQ, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.EDIT_CATEGORY_FAQ),
      payload: response.data,
    });
    yield put(showNotification(`Категорію успішно редаговано`));
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.EDIT_CATEGORY_FAQ),
      error,
    });
  }
}

export function* removeCategoryFAQSaga(action: IAction) {
  try {
    const response: AxiosResponse = yield call(removeCategoryFAQ, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.REMOVE_CATEGORY_FAQ),
      payload: response.data,
    });
    yield put(showNotification(`Категорію успішно видалено`));
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.REMOVE_CATEGORY_FAQ),
      error,
    });
  }
}

export function* getCategoriesByNameFAQSaga(action: IAction) {
  try {
    const result: AxiosResponse = yield call(getCategoriesByNameFAQ, action.payload);
    yield put({
      type: getSuccessAction(actionTypes.GET_CATEGORIES_BY_NAME_FAQ),
      payload: result.data,
    });
  } catch (error: unknown) {
    yield put(showNotification((error as Error).message));

    yield put({
      type: getFailAction(actionTypes.GET_CATEGORIES_BY_NAME_FAQ),
      error,
    });
  }
}

export function* watchAddCategoryFAQ() {
  yield takeEvery(actionTypes.ADD_CATEGORY_FAQ, addCategoryFAQSaga);
}
export function* watchGetCategoriesFAQ() {
  yield takeEvery(actionTypes.GET_CATEGORIES_FAQ, getCategoriesFAQSaga);
}

export function* watchEditCategoriesFAQ() {
  yield takeEvery(actionTypes.EDIT_CATEGORY_FAQ, editCategoryFAQSaga);
}

export function* watchRemoveCategoriesFAQ() {
  yield takeEvery(actionTypes.REMOVE_CATEGORY_FAQ, removeCategoryFAQSaga);
}

export function* watchGetCategoriesByNameFAQ() {
  yield takeEvery(actionTypes.GET_CATEGORIES_BY_NAME_FAQ, getCategoriesByNameFAQSaga);
}
