import { ThemeProvider } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import theme from '../theme';
import { Routes } from './routes';
import { store } from './store';
import Notifier from './Notifier';
import ErrorBoundary from '../components/ErrorBoundary';
import { configureLS } from '../constants/encryptConf';

function App() {
  useEffect(() => {
    configureLS();
  }, []);

  return (
    <Provider store={store}>
      <SnackbarProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </BrowserRouter>
          <Notifier />
        </ThemeProvider>
      </SnackbarProvider>
    </Provider>
  );
}

export default App;
